#notifications {
    padding-top: 20px;

    .box {
        background: #fff;
        border: 1px solid #efefef;
        min-height: 100px;
        margin-bottom: 20px;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 20px;

        li.ant-list-item {
            flex-wrap: wrap;

            &:before {
                content: '';
                display: inline-block;
                background: $orange;
                border-radius: 100%;
                width: 7px;
                height: 7px;
                margin-left: -5px;
                margin-right: 10px;
            }

            &.read-noti {
                color: #969696;
                &:before {
                    background-color: #dadada;
                }
                img {
                    opacity: 0.7;
                }

                > i {
                    color: #999;
                }
            }

            > span {
                cursor: pointer;
            }

            > span:not(.anticon) {
                flex: 1;
            }

            > i {
                font-style: normal;
                font-size: 12px;
                color: #666;
                margin-right: 10px;
            }
        }

        .notifications-actions {
            padding: 10px;
            border: 1px solid #d8d9dc;
            border-radius: 6px;
            flex: 1;
            background: #f1f1f1;
            button {
                margin: 0 3px;
            }
        }

        .ant-pagination {
            margin-top: 20px;
        }
    }
}

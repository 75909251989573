body.maintenance {
    .site-wrapper {
        background: #fff;
    }
}

#maintenance {
    @include maxWidth;
    min-height: 100vh;
    padding-top: 20px;
    margin: 0 auto !important;

    h1 {
        font-size: 4rem;
        color: rgba(67, 68, 109, 1);
    }

    h3 {
        font-size: 1.5rem;
        color: rgba(67, 68, 109, 1);
    }
}

#bestshopping_list,
#shopping_list {
    width: 100%;

    a:hover {
        text-decoration: none;
    }

    .filter-bar {
        background: transparent;
        border-bottom: 1px solid $violetdark;
        border: none;
        border-radius: 0;
        width: 100%;
        z-index: 2;
        margin-bottom: 5px;
        transition: all 0.3s;

        .ant-input-search .ant-input-affix-wrapper {
            .ant-input:hover,
            .ant-input:focus {
                background-color: #fff;
            }
        }
    }

    .filter-wrapper,
    .cards-wrapper {
        margin: 0 auto;
    }

    .filter-wrapper {
        .ant-col {
            text-align: center;
        }

        .ant-switch-checked {
            background-color: $orange;
        }
    }

    .cards-wrapper {
        float: none;
    }

    .sticky-filter-bar {
        position: fixed;
        top: 0;
        left: 0;
        background: $violetdark;
        color: $white;
        .filter-wrapper {
            max-width: 1400px;
            margin: 0 auto;
        }
        .ant-input-affix-wrapper {
            height: 32px;
        }
        .ant-select {
            span {
                color: $white;
            }
        }
    }

    .grid-wrapper {
        // .ant-list-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        @media #{$mq-sm} {
            grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        }
        @media #{$mq-xs} {
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }
        .grid-element {
            border: 1px solid $gray;
            border-radius: 8px;
            margin: 10px;
            background: $white;
            padding: 1rem;
            display: grid;
            place-items: center;
            position: relative;
            color: $violetdark;
            cursor: pointer;
            overflow: hidden;
            &:hover {
                z-index: 1;
                .shine {
                    opacity: 1;
                    top: -30%;
                    left: -30%;
                    transition-property: left, top, opacity;
                    transition-duration: 1.2s, 1.2s, 0.28s;
                    transition-timing-function: ease;
                }
            }

            .ant-col {
                text-align: center;
            }

            .offer-premium {
                &:before {
                    font-size: 18px;
                    font-family: 'icomoon';
                    display: inline-block;
                    margin-right: 5px;
                    content: '\e92c';
                    color: $orange;
                }
            }

            img {
                margin: 10px 0;
                grid-area: 1 / 1 / 1/ 1;
                width: 100%;
            }

            strong {
                font-weight: bold;
            }

            h4 {
                grid-area: 2 / 1 / 2/ 1;
                font-size: 18px;
                text-align: center;
            }

            .label-info {
                height: 30px;
                overflow: hidden;
                grid-area: 3 / 1 / 3/ 1;
                line-height: 1;
            }
            .icon {
                &:after {
                    font-family: 'icomoon';
                    font-size: 30px;
                    display: inline-block;
                    vertical-align: middle;
                    color: $orangedark;
                    margin-bottom: 5px;
                }
                &.icon-ticket {
                    &:after {
                        content: '\e93b';
                    }
                }
                &.icon-credits {
                    &:after {
                        content: '\e987';
                        color: $orange;
                    }
                }
            }

            .progress-label {
                font-size: 10px;
                text-transform: uppercase;
            }
            .progress-value {
                font-size: 15px;
                margin: 0 10px 0 5px;
            }

            .label-not-orange-premium,
            .label-not-orange {
                display: none;
                color: $violetdark;
            }

            .label-not-orange-2,
            .label-not-orange-premium-2 {
                background-color: $violetdark;
                color: $white;
                font-size: 12px;
                position: absolute;
                top: -1px;
                width: 100%;
                padding: 4px 10px;
                left: 0;
                text-align: right;
            }

            .label-not-orange-2 {
                background-color: $orange;
            }

            &.user-not-orange {
                .label-not-orange {
                    display: block;
                }
            }
            &.user-not-orange-premium {
                img {
                    opacity: 0.5;
                }
                .label-not-orange-premium {
                    display: block;
                }
            }

            .ant-statistic-content-prefix {
                font-size: 16px;
            }
            .ant-statistic-content-value {
                font-size: 12px;
            }
            .label-orange {
                height: 14px;
                margin-bottom: 0;
                font-size: 13px;
                margin-top: 5px;
                overflow: hidden;
                grid-area: 4 / 1 / 4/ 1;
                line-height: 1;
                color: $orange;
            }
        }

        // }
    }

    // ADV

    .grid-element-adv {
        overflow: hidden;
        position: relative;

        & > div {
            float: left;
        }
        & > div:nth-child(2) {
            float: right;
        }
    }

    .ant-btn-primary {
        background-color: $orange;
        border-color: $orange;
        &:hover,
        &:focus {
            background-color: darken($orange, 10%);
            border-color: $orange;
        }
    }

    .ant-btn {
        &:hover,
        &:focus {
            color: $violetdark;
            border-color: $black;
        }
    }

    .ov-ant-inpt {
        &:hover {
            border-color: $orange !important;
            .ant-input:not(.ant-input-disabled) {
                border-color: $orange !important;
            }
        }
        &:focus {
            border-color: $orange;
            box-shadow: 0 0 0 2px rgba($orange, 0.2) !important;
        }
    }

    .ant-select-auto-complete {
        .ant-select-search__field__wrap {
            .ant-input {
                @extend .ov-ant-inpt;
            }
        }
    }

    .ant-list-item.mine {
        justify-content: flex-end;
    }

    .ant-list-item.other {
        justify-content: flex-start;
    }

    .ant-list-item.system {
        justify-content: center;
    }

    .ant-list-item-meta-avatar {
        margin-right: 8px;
    }

    .ant-btn-round.ant-btn-lg {
        .small & {
            height: 22px;
            padding: 0 10px;
            font-size: 15px;
            border-radius: 40px;
            line-height: 5px;
        }
    }

    .ant-btn-background-ghost {
        &.ant-btn-primary {
            color: $gray;
            background-color: transparent;
            border-color: $gray;
            text-shadow: none;
        }
    }

    .ant-comment-inner {
        padding: 0;

        .ant-comment-avatar {
            position: absolute;
            left: -1px;
            top: -21px;

            .single & {
                display: none;
            }
        }

        .ant-comment-actions {
            position: absolute;
            margin: 0;
            bottom: -1px;
            right: -47px;
            width: 43px;

            > li {
                display: block;
                height: 17px;
            }
        }
    }

    .ant-comment-content-author {
        position: absolute;
        top: -23px;
        left: 20px;
        font-weight: 600;
    }

    .ant-dropdown {
        z-index: 1050000000;
    }

    .ant-popover,
    .ant-modal-mask,
    .ant-modal-wrap {
        z-index: 999999;
    }

    .ant-message {
        z-index: 999999;
    }

    .ant-alert-warning {
        .ChatHeader & {
            position: absolute;
            left: 0;
            top: 70px;
            z-index: 9999;
            width: 100%;
            text-align: center;
        }
    }

    .ant-back-top {
        right: 30px;
        bottom: 100px;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: lighten($orange, 20%);
    }
}

#bestshopping_list {
    .bs-cardname {
        grid-area: 1 / 1 / 1 / 1 !important;
    }

    .bs-image {
        grid-area: 2 / 1 / 2 / 1 !important;
        background-size: contain !important;
        height: 7rem;
    }

    .bs-label {
        height: 3rem !important;
        font-size: 15px;
        padding: 0 2rem;

        span {
            float: right;
        }
    }
}

#welcome-credits {
    margin: 10px;

    .total {
        color: $violetdark;
        font-weight: 600;
        font-size: 14px;
    }

    .bar-background {
        box-shadow: inset 0px 3px 8px 0px $orange;
        border-radius: 50px;
        background-color: $lighterorange;
        height: 25px;
        width: 100%;
    }

    .bar-fill {
        border-radius: 50px;
        position: relative;
        background-color: $orange;
        height: 100%;
        box-shadow: inset 0px 3px 8px 0px $lighterorange;

        span {
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            right: 0;
            color: $violetdark;
            white-space: nowrap;
            font-weight: 600;
            font-size: 14px;
        }
    }

    .bar-point {
        box-shadow: inset 0px 5px 10px 0px $lighterorange;
        background-color: $orange;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        right: -17px;
        width: 35px;
        height: 35px;
    }
}

.sort-icon {
    font-size: 16px;
    margin-left: 5px;
}
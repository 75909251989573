#cover {
  @media (max-width: 1200px) {
    .xs-centered {
      justify-content: center;
    }

    .profile-image {
      margin-left: 0px !important;
    }
  }
}
#social-timeline {
    width: 100%;
    margin: 0 auto 50px;
    padding-top: 50px;

    h4 > a {
        margin-left: 5px;
        color: #43446d !important;
        text-decoration: underline;
    }

    .right-col {
        @media (max-width: 768px) {
            display: none;
        }

        .ant-card {
            margin-bottom: 30px;
        }

        .orange-banner {
            background-image: url('/assets/images/icons/orange-banner.png');
            background-repeat: no-repeat;
            background-position: 100% 100%;
            background-size: 138px;

            .ant-typography {
                margin-bottom: 0;
            }

            .color-orange {
                margin-top: 0;
                color: $orange !important;
            }
        }

        .friend-info {
            img {
                margin-top: 10px;
            }

            .title {
                margin-bottom: 5px;
            }
            .value {
                font-size: 20px;
                text-transform: lowercase;
            }
        }
    }

    .write-form {
        .link-preview {
            background: #f5f5f5;
            position: relative;

            .anticon-close-circle {
                position: absolute;
                top: 6px;
                right: 6px;
                cursor: pointer;
                font-size: 20px;
            }
        }
    }

    .post-content,
    .post-edit {
        width: 100%;

        .post-description {
            .ant-tag-blue {
                cursor: pointer;
            }
        }

        .image-preview {
            width: 100%;
            max-height: 500px;
            object-fit: scale-down;
            cursor: pointer;
            margin-bottom: 5px;
            background-color: transparent;
        }
    }

    .post-edit {
        textarea {
            min-height: 200px;
        }
    }

    .post-head {
        .time {
            font-size: 12px;
            color: #666;
        }
    }

    .bg-share {
        background: #f5f5f5;
        padding: 10px !important;

        .image-shared {
            width: 100%;
            max-height: 300px;
            object-fit: cover;
            cursor: pointer;
            margin-bottom: 5px;
        }
    }

    .post-actions {
        .ant-typography {
            cursor: pointer;
        }

        .ant-typography.like.liked {
            color: $orange;
        }
    }

    .comment-list {
        background: #fafafa;
        padding: 6px 10px;
        box-shadow: #dfdfdf 0px 1px 2px inset;
        margin-bottom: 10px;

        .ant-list-item {
            position: relative;
        }

        .ant-list-item-meta-title {
            margin-bottom: 0;
        }
        .ant-list-item-meta-description {
            font-size: 12px;
        }

        .ant-list-item-action {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .friend-friends {
        .ant-avatar {
            cursor: pointer;
        }
    }

    #users-search {
        padding: 0;

        .ant-list {
            position: absolute;
            max-height: 500px;
            width: 120px;
            z-index: 99;
            background: #fff;
            overflow-y: scroll;
            border: 1px solid #eee;

            .ant-list-item {
                &:hover {
                    cursor: pointer;
                    background-color: #eee;

                    .ant-card-body {
                        background-color: #eee;
                    }
                }
            }

            .ant-card-body {
                padding: 10px;
            }
        }

        input[type='text']:hover,
        input[type='text']:active,
        input[type='text']:focus {
            background-color: #fff;
        }
    }
}

.ant-modal  {
    .post-share-img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
        margin-bottom: 5px;
    }
}

.share-post-modal {
    .shared {
        padding: 20px;
        background: #efefef;
    }
}

.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -5px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 5px;
    background-clip: padding-box;
}

.my-masonry-grid_column > img {
    background: gray;
    margin-bottom: 30px;
}

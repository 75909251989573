#usercredits {
    padding-top: 50px;

    .info-block {
        text-align: center;
        font-size: 26px;
        cursor: pointer;
        .ant-card-body {
            padding: 0;
        }
        span {
            font-size: 18px;
            padding: 6px;
            display: block;
            color: $white;
            font-weight: 300;
        }
        b {
            display: block;
            padding: 10px;
        }
    }

    .info-block-wrapper {
        & > .ant-col:nth-child(1) {
            span {
                background: linear-gradient(128deg, #dd5a0b 0%, #f47f00 100%);
            }
        }
    }
    .balance-block {
        display: flex;
        justify-content: center;
        text-align: center;
        .ant-typography {
            margin: 0;
            font-size: 30px;
            font-weight: 300;
        }
    }

    .ant-pagination {
        text-align: center;
        padding: 20px;
    }
}

.wallet-history {
    background: $white;
    h5 {
        font-size: 18px;
        padding: 7px;
        display: block;
        text-align: center;
        font-weight: 300;
    }
}

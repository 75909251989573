$white: #fff;
$gray: #f5f5f5;
$black: #161032;
$violet: #07004D;
$violetdark: #6A706E;
$orange: #25D366;
$orangelight: #137939;
$lighterorange: #E9FBEB;
$orangedark: #6A706E;
$light-background: rgba(37, 211, 54, .1);

.light-background {
  background-color: $light-background;
}
#howitworks {
    padding-top: 20px;
}

#userwallet {
    padding-top: 50px;

    .orange-banner {
        background-image: url('/assets/images/banner-orange-big.png');
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: contain;

        .ant-typography {
            margin-bottom: 0;
        }

        .color-orange {
            margin-top: 0;
            color: $orange !important;
        }
    }

    .info-block {
        text-align: center;
        font-size: 26px;
        h4.ant-typography {
            margin-bottom: 5px;
        }

        b {
            display: block;
            font-size: 34px;
        }

        &.credits b {
            color: $orange;
        }
        &.points b {
            color: $violet;
        }
    }

    #uservouchers {
        max-width: 100%;
        padding: 0;
        margin-top: -20px;
        h5 {
            font-size: 18px;
            padding: 7px;
            margin-top: -20px;
            display: block;
            text-align: center;
            font-weight: 300;
            margin-bottom: 5px;
        }
        .view-all {
            margin: 20px auto 0;
            width: 100px;
            display: block;
        }
    }

    .CircularProgressbar {
        width: 100%;
        vertical-align: middle;
    }

    .CircularProgressbar .CircularProgressbar-path {
        stroke: $orange;
        stroke-linecap: round;
        -webkit-transition: stroke-dashoffset 0.5s ease 0s;
        transition: stroke-dashoffset 0.5s ease 0s;
    }

    .CircularProgressbar .CircularProgressbar-trail {
        stroke: #d6d6d6;
        /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
        stroke-linecap: round;
    }

    .CircularProgressbar .CircularProgressbar-text {
        font-size: 20px;
        dominant-baseline: middle;
        text-anchor: middle;
    }

    .CircularProgressbar .CircularProgressbar-background {
        fill: #d6d6d6;
    }
    .CircularProgressbar.CircularProgressbar-inverted
        .CircularProgressbar-background {
        fill: $orange;
    }

    .CircularProgressbar.CircularProgressbar-inverted
        .CircularProgressbar-text {
        fill: #fff;
    }

    .CircularProgressbar.CircularProgressbar-inverted
        .CircularProgressbar-path {
        stroke: #fff;
    }

    .CircularProgressbar.CircularProgressbar-inverted
        .CircularProgressbar-trail {
        stroke: transparent;
    }
}

.extraprofit,
.chart-earned,
.redeem,
.daily-earnings {
    background: $white;
    h5 {
        font-size: 18px;
        padding: 7px;
        display: block;
        font-weight: 300;
    }
}

.extraprofit {
    h3.ant-typography {
        margin-top: 0 !important;
    }
    h1.ant-typography {
        margin-top: 0 !important;
        font-size: 40px;
        margin-bottom: 5px;
    }
}

.chart-earned {
    .card-wrapper {
        display: flex;
        & .ant-card:last-child {
            border: none;
        }
        @media #{$mq-xs} {
            & .ant-card:nth-child(1),
            & .ant-card:nth-child(2),
            & .ant-card:nth-child(3),
            & .ant-card:nth-child(4) {
                display: none;
            }
            .ant-card {
                width: 25%;
            }
        }
    }
    h5.ant-typography {
        margin-bottom: 0;
        margin-left: 56px;
    }

    .ant-typography + h3.ant-typography {
        margin-top: 0;
        color: $orange !important;
        margin-left: 61px;
    }

    .ant-card {
        width: 12.5%;
        text-align: center;
        box-sizing: border-box;
        border-right: 1px dashed #d8d8d8;
        .ant-card-body {
            padding: 20px 0px;
        }
    }
    // .ant-progress-outer {
    .ant-progress-line {
        transform: rotate(-90deg) translate(-30%, -204%);
        width: 180px;
        z-index: 1;
    }
    .ant-progress-text {
        transform: rotate(90deg) translateX(5px);
    }
    .month-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        text-transform: capitalize;
        flex-direction: column;
        margin-top: 140px;
        & > span:nth-child(2) {
            font-weight: 600;
        }
    }
    .disabled {
        pointer-events: none;
        // opacity: 0.5;
    }
}

.redeem {
    form {
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 0 20px 20px;
    }
}

.level-counter {
    display: inline;
    font-size: 16px;
    position: relative;
    min-width: 80px;
    margin: 0 20px 0 0;
    &:after {
        position: absolute;
        font-family: 'icomoon';
        content: '\e900';
        right: 22px;
        margin-top: 1px;
        font-size: 20px;
    }
}

.points-counter {
    display: inline;
    font-size: 16px;
    position: relative;
    min-width: 80px;
    margin: 0 20px 0 0;
    margin: 0 20px;

    &::after {
        content: '\e900';
        font-family: 'icomoon';
        color: #43446d;
        display: block;
        font-size: 24px;
        right: -22px;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        content: '\e901';
        right: -28px;
        margin-top: -12px;
    }
}

/**
 * COMMON
 */
#userinvoices,
#userpagameno,
#userricaricacell,
#uservouchers,
#userupdate,
#userticket,
#usersxccard,
#refundarea {
    padding-top: 50px;

    .anticon-loading {
        margin: 30px auto;
        font-size: 30px;
        display: block;
    }
}

/**
 * VOUCHER DOWNLOAD
 */
#uservouchers {
    .voucherimg {
        display: flex;
    }

    .ant-table-wrapper img {
        width: 100px;
        @media #{$mq-xs} {
            width: 60px;
        }
    }

    table th.ant-table-cell {
        background-color: #f5f5f5;
    }

    table td a {
        color: $orangedark;
    }

    table td i {
        color: #aaa;
    }

    table td .anticon-loading {
        margin: initial;
        margin-right: 15px;
        font-size: 22px;
        float: right;
    }
}

.download-success .ant-message-notice-content {
    padding: 14px 50px;
    transform: translateX(50%);
}

.ant-notification-topRight {
    width: 500px;

    .popup-download {
        width: 500px;
        border: 1px solid $orange;

        .ant-notification-notice-description {
            font-size: 16px;
            line-height: 30px;
            margin-left: 80px;
        }
    }
}

/**
* INVOICES
*/
#userinvoices {
    .ant-row.ant-form-item {
        flex-direction: column;

        .ant-form-item-label {
            text-align: left;

            label {
                font-weight: 600;
                font-size: 12px;
                font-family: verdana;
                margin-bottom: 0;
            }
        }
    }

    .handle-addresses {
        float: right;
        margin-top: 8px;
        color: $orange;
        cursor: pointer;
    }
}

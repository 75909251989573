/**************
***** BODY
**************/

* {
    font-family: 'Lato', sans-serif;
}

body {
    background: $white;
    color: $violetdark;
}

body a {
    color: $orange;
}

ul {
    list-style: none;
}
a:focus {
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

#boot {
    min-height: 100vh;

    .max-width {
        @include maxWidth();
    }
}

/**
 * GENERIC
 */
.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.align-center {
    text-align: center !important;
}

.color-orange {
    color: $orange !important;
}

.color-orangedark {
    color: $orangedark !important;
}

.violet-column {
    background: rgba(101, 100, 219, 0.1);
}

/**
 * GRID
 */
.flex-break {
    // parent needs to have flex-wrap: wrap;
    flex-basis: 100%;
    height: 0;
}

/**
 * ANT RESET
 */
.ant-row {
    margin-bottom: 20px;
}

.ant-btn-primary {
    background: $orange;
    border-color: $orange;
}

.ant-row-end {
    display: flex;
    justify-content: flex-end;
}

.ant-input-affix-wrapper::before {
    content: '';
    display: none;
}

.ant-input-affix-wrapper .ant-input {
    padding-left: 10px;
}

.ant-dropdown {
    z-index: 999999999;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: $orangelight;
}

.ant-list-split {
    width: 100%;
}

.ant-upload-list-item {
    display: none;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
    color: $violetdark !important;
}

// steps
.ant-steps {
    .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
        color: #fff;
    }
    .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
        background-color: $orange;
        border-color: $orange;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        border-color: $orange;
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: $orange;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: $orange;
    }
    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        background-color: $orange;
    }
}

.ant-descriptions-item.disabled {
    opacity: 0.4;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover {
    border-color: $orange;
    box-shadow: none;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
    color: initial;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $light-background !important;
    color: $orange;
}

.ant-menu-vertical .ant-menu-item:hover {
    color: $orange !important;
}

.ant-btn-primary {
    background: $orange !important;
    border: none !important;
}

/**
 * FORM
 */
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input[type='password']:-webkit-autofill,
input[type='password']:-webkit-autofill:hover,
input[type='password']:-webkit-autofill:focus,
input[type='password']:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::placeholder {
    font-size: 13px;
}

:focus {
    outline-width: 0px !important;
}

button.ant-btn {
    border-radius: 6px;

    &:hover,
    &:focus {
        color: white;
        background-color: $orange;
        border-color: $orange;
    }

    &.ant-btn-sm {
        padding-bottom: 22px;
    }

    &.orange-btn {
        background: $orange;
        color: $white;
        border: 2px solid $orange;
        &:hover {
            background: white;
            border: 2px solid $orange;
            color: $black;
        }
    }

    &.white-btn {
        border: 2px solid $orange;

        &:focus,
        &:hover {
            color: #333;
            background-color: $gray;
        }
    }

    &.dark-btn {
        background: $violetdark;
        color: #fff;
        &:hover,
        &:focus {
            border-color: $violetdark;
            background: $violetdark;
        }
        &:disabled {
            background-color: rgb(196, 196, 196);
        }
    }

    &.ant-btn-dangerous:hover,
    &.ant-btn-dangerous:focus {
        background: #ff7875;
        border-color: #ff7875;
    }

    &.ant-btn-lg {
        width: 100%;
    }

    &.xl {
        font-size: 26px;
        padding: 0 30px;
        height: auto;
    }
}

p.input-note {
    color: #908da0;
    padding-top: 12px;
    font-size: 13px;
    line-height: 14px;
    font-family: sans-serif;
}

textarea {
    outline-width: 0px;
}

/**
 * 3 COLUMNS LAYOUT
 */
.ant-row.col-3-layout {
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .ant-col.col-3-layout-left {
        flex: 0 0 240px;
        max-width: 240px;
        margin-top: 9px;
        @media #{$mq-md} {
            flex: 0 0 17%;
            max-width: 17%;
        }
        @media #{$mq-sm} {
            display: none;
        }
    }

    .ant-col.col-3-layout-center {
        width: initial;
        flex: 0 0 880px;
        padding: 0;
        max-width: 880px;
        @media #{$mq-md} {
            flex: 0 0 67%;
            max-width: 67%;
        }
        @media #{$mq-sm} {
            flex: 0 0 83%;
            max-width: 83%;
        }
        @media #{$mq-xs} {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .ant-col.col-3-layout-right {
        flex: 0 0 220px;
        max-width: 220px;
        text-align: center;
        margin-top: 68px;
        @media #{$mq-md} {
            flex: 0 0 16%;
            max-width: 16%;
        }
        @media #{$mq-sm} {
            display: none;
        }
    }
}

/*
 * OVERRIDE ANT THEME COLORS
 */
.ant-input:focus,
.ant-input-focused {
    border-color: $orange;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba($orange, 0.2);
    box-shadow: 0 0 0 2px rgba($orange, 0.2);
}
.ant-input:hover {
    border-color: $orange;
    border-right-width: 1px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $orange;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: $orange;
    border-color: $orange;
}

.ant-checkbox-checked:after {
    border-color: $orange;
}

.ant-btn-background-ghost.ant-btn-primary {
    color: $orange;
    background-color: transparent;
    border-color: $orange;
    text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
    color: $orange;
    background-color: transparent;
    border-color: $orange;
}

// PAGINATION
.ant-pagination-item-active {
    border-color: $orange;
}
.ant-pagination-item {
    &:hover,
    &:focus {
        border-color: $orange;
    }
}
.ant-pagination-prev,
.ant-pagination-next {
    &:hover {
        color: $orange;
    }
}
.ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
.ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: $orange;
}

.ant-radio-checked {
    &:after {
        border-color: $orange !important;
    }
}

.ant-radio-inner {
    .ant-radio-checked & {
        border-color: $orange !important;
    }
    &:after {
        background-color: $orange !important;
        // width: 12px;
        // height: 12px;
    }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
    border-color: $orange;
    box-shadow: 0 0 0 3px rgba($orange, 0.08);
    border-radius: 50%;
}

.ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
.ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
.ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
.ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: lighten($orange, 50%);
}

.ant-form .ant-form-item-with-help {
    margin-bottom: 20px;
}

.ant-form-item-control-input-content .ant-input-number {
    width: 100%;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input),
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
.ant-picker-focused .ant-select-selector,
.ant-select-open .ant-select-selector,
.ant-picker-focused,
.ant-select-focused,
.ant-picker:hover {
    border-color: $orange !important;
    box-shadow: none !important;
    outline-color: transparent !important;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
    color: $orange;
}

.ant-radio-group
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $orange;
    background: #fff;
    border-color: $orange;
}

.ant-radio-group
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-group
    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-color: $orange;
}

.ant-radio-group
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $orange;
}

.ant-radio-group
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: $orange;
}
.ant-radio-group .ant-radio-button-wrapper:hover {
    color: $orange;
}
.ant-radio-group
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: $orange;
    border-color: $orange;
}
.ant-radio-group
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: $orange;
}

.ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background-color: $orange !important;
}

.ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background-color: $orange !important;
    left: 0;
}

.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: $orange !important;
}

.col-1-layout {
    max-width: 1360px;
    margin: 0 auto;
    &.w1120 {
        max-width: 1120px;
    }
}

.col-2-layout {
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &.w1120 {
        max-width: 1120px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        min-height: 190px;
    }

    .col-2-layout-left {
        flex: 0 1 300px;
        z-index: 9;

        @media (max-width: 1270px) {
            flex: 0 1 26%;
            max-width: 26%;
        }

        @media (max-width: 768px) {
            flex: 0 1 100%;
            max-width: 100%;
        }
    }

    .col-2-layout-right {
        width: initial;
        flex: 0 1 970px;
        padding: 0;
        @media (max-width: 1270px) {
            flex: 0 1 70%;
            max-width: 70%;
        }

        @media (max-width: 768px) {
            flex: 0 1 100%;
            max-width: 100%;
        }

        .card-info {
            margin: 2rem 0;
        }
    }
}

.col-3-layout {
    max-width: 1360px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .col-3-layout-left {
        flex: 0 0 240px;
        max-width: 240px;
        margin-top: 9px;
        @media screen and (max-width: 1360px) {
            flex: 0 0 17%;
            max-width: 17%;
        }
        @media screen and (max-width: 960px) {
            display: none;
        }
    }

    .col-3-layout-center {
        width: initial;
        flex: 0 0 880px;
        padding: 0;
        max-width: 880px;
        @media screen and (max-width: 1360px) {
            flex: 0 0 67%;
            max-width: 67%;
        }
        @media screen and (max-width: 1024px) {
            flex: 0 0 83%;
            max-width: 83%;
        }
        @media screen and (max-width: 960px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .card-info {
            margin: 2rem 0;
        }
    }

    .col-3-layout-right {
        flex: 0 0 220px;
        max-width: 220px;
        text-align: center;
        margin-top: 68px;
        @media screen and (max-width: 1360px) {
            flex: 0 0 16%;
            max-width: 16%;
        }
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}

.navigation .ant-typography {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }

    &.active {
        cursor: default;
        color: $orange;
        &:hover {
            text-decoration: none;
        }
    }
}

.unclickable {
    cursor: default !important;
}

.write-post.bordered {
    .write-post__input {
        border: 1px solid lightgrey;
    }
}

.write-post {
    .write-post__input {
        border: 0;
    }

    .write-post__suggestions {
        border: 1px solid lightgrey;

        .write-post__suggestions__item {
            padding: 0.5em 1em;
        }

        .write-post__suggestions__item:hover {
            background-color: lightgrey;
        }
    }

}

.pointer:hover {
    cursor: pointer;
}
#voucherdetail {
    padding-top: 30px;

    .promosec {
        font-size: 15px;

        .offer-premium {
            &:before {
                font-family: 'icomoon';
                display: inline-block;
                margin-right: 5px;
                content: '\e92c';
                color: $orange;
            }
        }
    }

    .selected-premiums {
        margin-bottom: 10px;
        .ant-card.ant-card-bordered {
            border: 0;
            border-bottom: 1px solid #f0f0f0;
            &:first-child {
                border-top: 1px solid #f0f0f0;
            }
            .ant-card-body {
                padding: 8px;

                .anticon.anticon-delete {
                    position: absolute;
                    left: 5px;
                    top: 12px;
                    font-size: 16px;
                    cursor: pointer;
                }

                .anticon.anticon-caret-down, .anticon.anticon-caret-up {
                    position: absolute;
                    right: 5px;
                    top: 12px;
                    font-size: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    .moreDetails {
        display: block;
        text-align: center;
        cursor: pointer;
    }

    .ant-steps {
        max-width: 300px;
        margin: 0 auto;
        transform: translateX(-12%);
    }

    .voucher-options {
        text-align: center;
        .ant-radio-button-wrapper {
            font-size: 28px;
            height: auto;
            width: 100px;
            text-align: center;
            padding: 10px;
            margin: 0 15px;
            &:first-child {
                margin-left: 0;
            }
        }

        .ant-btn {
            border-radius: 0;
            font-size: 25px;
            line-height: 22px;
        }
    }

    .activation-options {
        .ant-select {
            width: 100%;
        }
    }

    .choose-credit {
        .ant-card {
            cursor: pointer;
            opacity: 0.5;
            &.credit-chosen {
                opacity: 1;
                border: 2px solid $orange;
            }
        }
    }

    .choose-credit-us {
        .ant-card {
            cursor: pointer;
            text-align: center;
            margin-bottom: 20px;
            border-radius: 4px;
            &.credit-chosen {
                border: 2px solid $orange;
            }
        }

        .ant-card-body {
            padding: 10px 10px 6px;
        }
    }

    #voucher-search {
        padding: 0;

        .ant-list {
            position: absolute;
            max-height: 500px;
            width: 340px;
            z-index: 99;
            background: #fff;
            overflow-y: scroll;
            border: 1px solid #eee;

            .ant-list-item {
                &:hover {
                    cursor: pointer;
                    background-color: #eee;

                    .ant-card-body {
                        background-color: #eee;
                    }
                }
            }

            .ant-card-body {
                padding: 10px;
            }
        }

        input[type='text']:hover,
        input[type='text']:active,
        input[type='text']:focus {
            background-color: #fff;
        }
    }

    .voucher-right {
        > .ant-card-body {
            padding-top: 0;
        }

        .ant-alert-warning {
            margin-bottom: 20px;
        }

        .ant-row {
            margin-bottom: 5px;
        }

        input[type='radio'] {
            margin-right: 5px;
        }

        .summary {
            border: 1px solid #f0f0f0;
            padding: 10px 0;
            margin-bottom: 20px;

            .voucher-label {
                font-size: 16px;
                font-weight: 600;
            }

            .voucher-label-summary {
                font-size: 16px;
                font-weight: 400;
            }

            .background-orange {
                background-color: rgba($orange, 0.1);
                padding-top: 15px;
            }

            .ant-divider {
                margin: 0;
            }
        }
    }

    #premiums {
        .ant-card {
            cursor: pointer;
            &.added {
                border: 1px solid $orange;
            }

            .anticon.anticon-info-circle {
                position: absolute;
                left: 10px;
                top: 10px;
                font-size: 20px;
                color: #666;
            }

            .anticon.anticon-plus-circle,
            .anticon.anticon-minus-circle {
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 20px;
                color: $orange;
            }

            .ant-row, h5 {
                margin: 0;
            }

            .voucher-name {
                min-height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .offer-premium {
                &:before {
                    font-family: 'icomoon';
                    display: inline-block;
                    margin-right: 5px;
                    content: '\e92c';
                    color: $orange;
                }
            }

        }

        .ant-card-body {
            padding: 15px;

            .icon {
                &:after {
                    font-family: 'icomoon';
                    font-size: 16px;
                    display: inline-block;
                    vertical-align: middle;
                    color: $orange;
                }
            }

            .icon-credits {
                &:after {
                    content: '\e987';
                }
            }
            .icon-ticket {
                &:after {
                    content: '\e93b';
                }
            }

            .tooltip {
                position: relative;

                .tooltip-text {
                    z-index: 1000;
                    width: max-content;
                    display: none;
                    background-color: #ffffff;
                    border: 1px solid $orange;
                    padding: 5px;
                    font-size: 14px;
                    position: absolute;
                    top: -60px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }

            .tooltip:hover {
                .tooltip-text {
                    display: block;
                }
            }

            .activation-days {
                font-size: 13px;
            }

            .info {
                font-size: 22px;
                color: $violetdark;
            }

            .toggle-voucher-btn {
                width: 100%;
            }

        }
    }

    .socialshare {
        margin-top: 20px;

        .ant-typography {
            display: inline-block;
            margin-bottom: 10px;
        }

        img {
            cursor: pointer;
        }
    }

    .become-orange {
        cursor: pointer;
        background: url('/assets/images/icons/premium.png') 0 2px no-repeat;
        padding-left: 22px;
    }
}

@mixin badge-variant($bg) {
    @include color-yiq($bg);
    background-color: $bg;

    &[href] {
        @include hover-focus {
            @include color-yiq($bg);
            text-decoration: none;
            background-color: darken($bg, 10%);
        }
    }
}

@mixin badge-icons($lineSize, $fontSize) {
    i {
        &:before {
            color: #fff;
            line-height: $lineSize !important;
            font-family: 'icomoon';
            font-size: $fontSize !important;
            content: '\e954';
        }
    }
    &.weekly_login {
        i {
            &:before {
                content: '\e955';
            }
        }
    }
    &.monthly_login {
        i {
            &:before {
                content: '\e956';
            }
        }
    }
    &.yearly_login {
        i {
            &:before {
                content: '\e957';
            }
        }
    }

    &.shopper {
        i {
            &:before {
                content: '\e950';
            }
        }
    }
    &.shopper2 {
        i {
            &:before {
                content: '\e949';
            }
        }
    }
    &.writer {
        i {
            &:before {
                content: '\e948';
            }
        }
    }
    &.writer2 {
        i {
            &:before {
                content: '\e947';
            }
        }
    }
    &.citizen,
    &.profile_name,
    &.profile_date,
    &.profile_country,
    &.profile_currency,
    &.profile_pic {
        i {
            &:before {
                content: '\e951';
            }
        }
    }
    &.extraprofit {
        i {
            &:before {
                content: '\e944';
            }
        }
    }
    &.premium1 {
        i {
            &:before {
                content: '\e940';
            }
        }
    }
    &.extrapremium {
        i {
            &:before {
                content: '\e943';
            }
        }
    }
    &.extrapremium5,
    &.premium5 {
        i {
            &:before {
                content: '\e939';
            }
        }
    }
    &.extrapremium10,
    &.premium10 {
        i {
            &:before {
                content: '\e953';
            }
        }
    }
    &.level {
        i {
            &:before {
                content: '\e952';
            }
        }
    }
    &.ambassador_US {
        i {
            &:before {
                content: '\e926';
            }
        }
    }
    &.ambassador_DE {
        i {
            &:before {
                content: '\e928';
            }
        }
    }
    &.ambassador_UK {
        i {
            &:before {
                content: '\e927';
            }
        }
    }
    &.ambassador_FR {
        i {
            &:before {
                content: '\e929';
            }
        }
    }
    &.ambassador_ES {
        i {
            &:before {
                content: '\e92a';
            }
        }
    }
    &.small {
        width: 46px;
        height: 46px;
        margin: 15px auto;

        i {
            &:before {
                line-height: 46px !important;
                font-size: 40px !important;
            }
        }
    }
}

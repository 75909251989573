#sxccardmodal {
    text-align: center;
    font-size: 1rem;
    h4 {
        color: $orangedark;
    }
    .error-message-ico {
        background: url(/assets/images/icons/attention.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 5rem;
        margin: 0 auto 1rem;
        padding-top: 5rem;
    }
}

#usersxccard {
    padding-top: 50px;

    .anticon-loading {
        margin: 30px auto;
        font-size: 30px;
        display: block;
    }

    .banner {
        margin-top: 20px;
        background: #fff;
        border: 1px solid #efe9e4;
        border-radius: 3px;
        font-size: 1rem;

        h3 {
            font-size: 22px;
            margin-top: 0.5rem;
        }

        &.recharge {
            .method-select {
                display: block;
                height: 4rem;
                font-size: 1rem;

                .description {
                    display: inline-block;
                    vertical-align: top;
                    height: 4rem;
                    padding: 1rem 0 0 0.5rem;
                }

                &:hover {
                    cursor: pointer;
                }

                &.active {
                    border: 2px solid $orangedark;
                    border-radius: 5px;
                    background-color: $orange;
                }
            }

            .method {
                position: relative;
                display: inline-block;
                line-height: 4rem;
                padding-left: 0;

                &:after {
                    font-family: 'icomoon';
                    font-size: 4rem;
                }

                &.sxc-card {
                    &:after {
                        content: '\e934';
                    }
                }

                &.lotto {
                    &:after {
                        content: '\e935';
                    }
                }
            }

            .recharge-form {
                padding: 1rem;

                form {
                    margin-bottom: 0.5rem;
                }

                input {
                    border-radius: 0.25rem;
                    height: 2.25rem;
                }

                button {
                    max-height: 2.25rem;
                }
            }
        }
    }
}

#groups {
    > .ant-row-flex-end {
        justify-content: flex-end;
    }

    .category {
        cursor: pointer;
    }

    .category.active {
        color: $orange;
    }

    #usergroup {
        > .ant-row-flex-end {
            justify-content: flex-end;
        }

        #group-details {
            margin-bottom: 40px;

            .ant-card-body {
                text-align: center;
            }
        }

        .admin-border {
            border-radius: 3px;
            border-color: $orangedark;
            border-width: 1px;
        }

        .post-content {
            width: 100%;

            .post-head {
                .time {
                    font-size: 12px;
                    color: #666;
                }
            }

            .post-description {
                .ant-tag-blue {
                    cursor: pointer;
                }
            }

            .image-preview {
                cursor: pointer;
            }

            .link-preview {
                background: #efefef;
                cursor: pointer;
            }

            .post-actions {
                .ant-typography {
                    cursor: pointer;
                }

                .ant-typography.like.liked {
                    color: #6363e8;
                    font-weight: 600;
                }
            }

            .comment-list {
                .ant-list-item-meta-title {
                    margin-bottom: 0;
                }
                .ant-list-item-meta-description {
                    font-size: 12px;
                }

                .ant-list-item {
                    background: #f7f7f7;
                    border-radius: 6px;
                    padding: 10px;
                    margin-bottom: 10px;
                    position: relative;
                }

                .ant-list-item-action {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        .post-edit {
            width: 100%;
        }
    }
}

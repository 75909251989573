#user-profile {
  .tabs {
    border: 2px solid #F5F5F5;
    border-radius: 10px;

    .tab {
      margin: 0px;
      padding: 10px;
    }

    .tab:not(:last-child) {
      border-bottom: 1px solid #C4C4C4;
    }

    .tab:hover, .tab.selected {
      cursor: pointer;
      background-color: rgba(196, 196, 196, 0.2);
    }
  }

  .tab-content {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 0px 15px;

    .ant-row {
      margin-bottom: 0px;

      .ant-form-item-label {
        width: 200px;
        text-align: start;
        white-space: unset;
      }
    }

    > *:not(:last-child) {
      border-bottom: 1px solid #C4C4C4;
    }

    > * {
      margin: 0px;
      padding: 15px 0px;

      p {
        margin: 0px;
      }
    }

    .public-row {
      position: relative;

      .checkbox {
        position: absolute;
        left: -50px;
        top: 0px;
        bottom: 0px;
        margin: auto 0px;
        height: fit-content;
      }
    }
  }

  .delete-account-btn {
    width: 100%;
  }
}

#profile-diana {
  .diana-bg {
    width: 100%;
    height: 250px;
    background-image: url("/assets/images/diana_bar_en.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .tabs {
    .tab {
      color: $white;
      border-radius: 10px;
      padding: 20px;
      background-color: rgba(73, 90, 191, 0.5);
    }

    .tab:hover:not(.disabled), .tab.selected:not(.disabled) {
      cursor: pointer;
      background-color: #495ABF;
    }
  }

  .tab-content {
    border: 2px solid #F5F5F5;
    border-radius: 10px;
    padding: 15px;

    > .ant-row {
      margin-bottom: 0px;
    }

    .device:not(:last-child) {
      margin: 0px;
      padding: 10px 0px;
      border-bottom: 1px solid #F5F5F5;
    }
  }

  .privacy {
    h4 {margin-bottom: 5px}
    p {margin-bottom: 0px}
  }
}

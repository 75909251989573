#userfriends {
    > .ant-row {
        justify-content: flex-end;
    }

    .anticon-loading {
        margin: 30px auto;
        font-size: 30px;
        display: block;
    }

    table th.ant-table-cell {
        background-color: #f5f5f5;
    }

    .friends-title {
        display: flex;
        align-items: 'center';
        button {
            margin: 0 5px;
        }
    }
}

#usercard {
    @media #{$mq-sm} {
        display: flex;
        display: flex;
        align-items: center;
    }

    .ant-row {
        margin-bottom: 0;
    }

    .ant-typography:first-child {
        margin-bottom: 0;
        margin-top: 10px;
    }

    .ant-typography + h4.ant-typography {
        margin-top: 0;
    }

    &.invited-friends {
        .level-counter,
        .points {
            display: none;
        }
    }
    .level-counter {
        position: relative;
        &:after {
            content: '\e900';
            font-family: 'icomoon';
            color: $violet;
            display: block;
            font-size: 24px;
            left: -64px;
            position: absolute;
            top: 50%;
            margin-top: -19px;
        }
    }
    .points-counter {
        @extend .level-counter;
        &:after {
            content: '\e901';
        }
    }
}

#pagameno {
    @include maxWidth;
    min-height: 40vh;
    padding-top: 20px;

    .anticon-loading {
        margin: 30px auto;
        font-size: 40px;
        display: block;
    }

    .ant-result-icon {
        display: none;
    }

    .ant-result-title {
        color: $orange;
    }

    .ant-steps {
        margin-bottom: 20px;
        background: #fff;
        padding: 12px 30px 15px;

        .ant-steps-item-icon {
            cursor: pointer;
        }
    }
}

.pagameno-list {
    .ant-row {
        margin-bottom: 20px;
    }

    .ant-card {
        text-align: center;
    }
}

.pagameno-premium,
.pagameno-creditusage {
    .ant-descriptions-item-container {
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 10px;
        align-items: center;

        .ant-descriptions-item-label {
            span.anticon {
                font-size: 19px;
                cursor: pointer;
                color: $violetdark;
            }

            img {
                width: 80px;
            }
        }
        .ant-descriptions-item-content {
            text-align: right;
            font-weight: 600;
        }
    }

    .ant-descriptions-item.total {
        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
            font-size: 24px;
            color: $orange;
        }
    }

    .proceed-note {
        font-size: 13px;
        text-align: center;
        color: #999;
        font-style: italic;
        padding-top: 5px;
    }

    .choose-credit {
        .ant-col {
            cursor: pointer;
            text-align: center;
        }

        .ant-col:not(.active-choice) {
            opacity: 0.6;
        }

        .ant-col.active-choice {
            opacity: 1;

            .ant-card {
                border: 2px solid $orange;
            }
        }
    }
}

.bulletin-form {
    .ant-row.ant-form-item {
        flex-direction: column;

        .ant-form-item-label {
            text-align: left;

            label {
                font-weight: 600;
                font-size: 12px;
                font-family: verdana;
                margin-bottom: 0;
            }
        }
    }

    .ant-row.info-support {
        margin: 20px 0;
    }

    .ant-row.accept {
        .ant-form-item.ant-form-item-has-error .ant-form-item-control {
            flex-direction: row;

            .ant-form-item-explain {
                margin-top: 6px;
            }
        }
    }
}

.mav-form,
.rav-form {
    .ant-row.info-support {
        margin: 20px 0;
    }

    .ant-row.accept {
        .ant-form-item.ant-form-item-has-error .ant-form-item-control {
            flex-direction: row;

            .ant-form-item-explain {
                margin-top: 6px;
            }
        }
    }
}

.pagameno-review {
    .ant-col:not(.payment) {
        .ant-card {
            min-height: 600px;
        }
    }

    .ant-descriptions-item-label {
        flex: 0.7;
        font-weight: 600;
    }

    .ant-descriptions-item-content {
        text-align: right;
    }

    .ant-col.payment .ant-card {
        min-height: 350px;
    }

    .ant-col.payment .ant-descriptions-item-label {
        flex: 1;
        &:after {
            display: none;
        }
    }

    .ant-col.payment .ant-descriptions-item-content {
        flex: 0.2;
    }

    .ant-descriptions-item.premium-row {
        .ant-descriptions-item-label:after {
            display: none;
        }

        img {
            width: 80px;
        }
    }

    .ant-descriptions-item-label .ant-checkbox-wrapper {
        margin-right: 8px;
    }

    &.processing {
        .ant-card-body {
            min-height: 250px;
        }

        h4.ant-typography {
            text-align: center;
        }

        img.bgcard {
            position: absolute;
            margin: 0 auto;
            display: block;
            width: 100px;
            top: 150px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

#userpagameno {
    padding-top: 50px;

    .anticon-loading {
        margin: 30px auto;
        font-size: 30px;
        display: block;
    }

    table th.ant-table-cell {
        background-color: #f5f5f5;
    }
}

/**
* NEW - REACT
*/
@import 'react/variables.scss';
/**
* NEW BRAND - START
 */
@import 'react/new.brand.scss';
/**
* NEW BRAND - END
 */
@import 'react/common.scss';
@import 'react/mixin/_badges.scss';
@import 'react/cart.scss';
@import 'react/footer.scss';
@import 'react/header.scss';
@import 'react/home.scss';
@import 'react/signin.scss';
@import 'react/maintenance.scss';
@import 'react/messenger.scss';
@import 'react/menuleft.scss';
@import 'react/new.privacy.scss';
@import 'react/notifications.scss';
@import 'react/pagameno.and.ricaricacell.scss';
@import 'react/pagameno.scss';
@import 'react/shopping.scss';
@import 'react/timeline.scss';
@import 'react/user.pagameno.scss';
@import 'react/user.ranking.scss';
@import 'react/user.friends.scss';
@import 'react/user.groups.scss';
@import 'react/user.badges.scss';
@import 'react/user.ticket.scss';
@import 'react/user.verify.scss';
@import 'react/user.sxccard.scss';
@import 'react/user.familybox.scss';
@import 'react/user.orange.scss';
@import 'react/user.momosy.scss';
@import 'react/user.wallet.scss';
@import 'react/user.credits.scss';
@import 'react/user.guadagnalink.scss';
@import 'react/voucher.scss';
@import 'react/voucher.error.scss';
@import 'react/wallet.scss';
@import 'react/profile.scss';
@import 'react/profile.navigation.scss';
@import 'react/cover.scss';

@import 'autoinclude/_quantcast.scss';
@import 'autoinclude/_asdlots';

#header {
  .logo-elem {
    div {
      height: 80px;
    }
  }
  .logo {
    height: 55px;
  }
}

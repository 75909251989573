#new-privacy {
    .scrollframe {
        margin: 0 auto 30px;
        border: 1px solid #ccc;
        background: #f1f1f1;
        padding: 25px;
        height: 50vh;
        overflow: scroll;
    }
}

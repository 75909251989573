.pending-ci-alert {
    text-align: center;

    .is-chat-route & {
        display: none;
    }
}

#header {
    min-height: 70px;
    margin-top: 10px;
    .logo-elem {
        @media #{$mq-sm} {
            flex: 100%;
            max-width: 100%;
            text-align: center;
            margin-bottom: 14px;
        }

        div {
            width: 176px;
            margin-top: 1px;
            cursor: pointer;
            background-image: url("/assets/images/logo/logo.svg");
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            @media #{$mq-sm} {
                width: 120px;
                height: 32px;
                margin: auto;
            }
            @media #{$mq-xs} {
                margin: 0 auto;
            }
        }
    }

    .logo {
        height: 40px;
        background-image: url("/assets/images/logo/logo.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .lang-elem {
        padding-left: 10px;
        padding-top: 25px;

        @media #{$mq-xs} {
            position: absolute;
            top: 0;
            left: 10px;
            padding: 0;
        }

        span {
            font-size: 12px;
            position: absolute;
            padding: 2px 7px;
            border-radius: 0 0 4px 4px;
            top: -8px;
            background: #4e4e8c;
            @media #{$mq-xs} {
                width: 100px;
                top: 0;
            }
        }
    }

    .ant-row {
        margin-top: 11px;
        margin-bottom: 0;

        // not the dropdown bade
        .ant-badge {
            .ant-scroll-number.ant-badge-dot {
                left: 5px;
                top: 6px;
            }
        }
    }

    .ant-typography {
        cursor: pointer;
        font-size: 16px;
        &.active {
            color: $orange;
        }
    }

    button.account-btn {
        margin-top: -3px;
    }

    .logout {
        cursor: pointer;
    }

    .header-icon {
        color: $orange;
        width: 22px;
        height: 22px;
        font-size: 22px;
    }
}

/**
* languages
*/
.ant-dropdown .choose-language {
    .ant-row {
        min-width: 50px;
    }

    li {
        &:hover {
            background: $gray;
        }
    }
}

/**
* Community
*/
.ant-dropdown .community-drop {
    .ant-row {
        min-width: 150px;

        .ant-scroll-number {
            top: 12px;
        }
    }
}

//**********************
// MY MESSAGES
//**********************

#sxc-messenger-small {
    position: fixed;
    z-index: 999999;
    left: 10px;
    bottom: 0;

    //style for scrollbar
    & * {
        // style for firefox
        scrollbar-width: thin;
        scrollbar-color: $violetdark white;
        &::-webkit-scrollbar {
            width: 11px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $violetdark;
            border-radius: 6px;
        }
    }

    .is-chat-route & {
        display: flex;
        left: 50%;
        transform: translateX(-50%);
        top: 73px;
        bottom: initial;
        position: absolute;
        z-index: 1;
        width: 100%;
        max-width: 1280px;

        .ant-col.full {
            padding: 0;
            height: 85.7vh;

            @media (max-width: 1280px) {
                width: 72%;
                max-width: 72%;

                &.col-sx {
                    overflow: hidden;
                }
                @media (max-width: 1280px) {
                    width: 28%;
                    max-width: 28%;
                    flex: 0 0 28%;
                }
            }
        }
    }

    .small {
        position: relative;
        width: 320px;
        bottom: 0;
        height: 0;
        background: #fff;
        border: 1px solid #ccc;
        .is-chat-route & {
            width: 100%;
            border: 0;
        }

        &.opened {
            height: 400px;
        }
    }
}

.sxc-messenger-header {
    background: $white;
    height: 68px;
    border-bottom: 1px solid #e8e8e8;
    padding: 9px 20px;

    @media #{$mq-xs} {
        height: 120px;
        display: flex;
        align-items: center;

        .is-chat-route & {
            height: 68px;
        }

        svg {
            font-size: 20px;
            margin-right: 20px;
        }
        .ant-list {
            width: 100%;
        }
    }
    .small {
        height: auto;
        padding: 3px;
    }

    li {
        padding: 0;
    }
}

.is-chat-route iframe {
    display: none;
}

.is-chat-route #sxc-messenger-small {
    min-height: 50vh;
    @media #{$mq-xs} {
        top: 133px;
    }
}

.is-chat-route .sxc-messenger-header {
    > .ant-list {
        @media #{$mq-xs} {
            flex: 1;
        }
    }
}

#messenger-height {
    min-height: 80vh;
}

#messenger-courtesy {
    @include maxWidth;
    height: calc(100vh - 50px);
    text-align: center;

    .chat-logo {
        width: 100%;
        height: 200px;
        background: url('/assets/images/sxc-messenger.svg') 50% 50% no-repeat;
        background-size: contain;
        margin-top: 50px;
    }
}

/** **/
// Remove privacy policy button
.qc-cmp-persistent-link {
    display: none !important;
}

.full {
    // height: 101vh; inda future
    height: 590px;
    overflow: hidden;
}

.col-sx {
    height: calc(100vh - 172px);
    overflow-y: scroll;
    background: #fff;
    .header-fixed {
        position: sticky;
        top: 0px;
        left: 0;
        z-index: 11;
        background: white;
        border-bottom: 1px solid #dedede;
    }
}

.group-name-input {
    .ant-form-item-control {
        &.has-error {
            border: none !important;
            .ant-form-explain {
                padding: 5px;
            }
        }
    }
}

h1.title {
    .small & {
        font-size: 15px;
    }
}

// SEARCH

.search-friend {
    width: 100%;
}

.search-dropdown {
    .ant-select-dropdown-menu-item-group {
        border-bottom: 1px solid #f6f6f6;
    }
}

.ant-form.create-group-form {
    width: 90%;
    margin: 0 auto;
    input {
        padding: 6px 11px !important;
        height: 40px !important;
    }
    input,
    .ant-select-selection__placeholder {
        font-size: 14px !important;
    }
}

.row-single-friend {
    padding: 10px;
    align-items: center;
}

.user-added-box-wrapper {
    .user-added-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 10px;
        button {
            top: 0;
            right: 50%;
            position: absolute;
            z-index: 1;
            transform: translateX(100%);
        }
        .name-label {
            font-size: 12px;
        }
    }
}

// MESSAGES

.ant-comment-content-author-name {
    white-space: nowrap;
    .sender-name {
        color: #fff;
        margin-left: 20px;
    }
}

.message-list.ant-list-split {
    &.blocked {
        opacity: 0.5;
    }

    .ant-list-item {
        border-bottom: 0;

        .ant-comment-content-detail {
            color: #fff;
        }
    }

    .input-edit {
        border-radius: 30px;
        height: 40px;
        border: 1px solid #ccc;
    }
    .edit-cancel {
        color: #ccc;
    }
    .edit-submit {
        color: $orange;
    }
}

.share-voucher {
    padding: 30px;
    text-align: center;
    & + div {
        display: none;
    }
    .chat-mobile & {
        padding: 10px;
        &.channel-active {
            display: none;
        }
        img {
            max-width: 100px;
        }
        button {
            max-width: 200px;
            width: 100%;
            display: block;
            margin: 0 auto;
        }
        & + div {
            display: inherit;
        }
    }
}

.voucher-message {
    background: white;
    margin: 10px;
    padding: 30px;
    border-radius: 20px;
    max-width: 400px;
    img {
        max-width: 340px;
        width: 100%;
        height: 220px;
        background-size: 100% auto;
        background-position: center center;
        background-repeat: no-repeat;
        @media (max-width: 768px) {
            height: 170px;
        }
    }
    .bttn-4 {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        text-transform: none;
    }
}

// MESSAGES BUTTONS

.chat-zip-btn {
    width: 100%;
    min-height: 30px;
    margin: 5px;
}

.submit-multimedia {
    right: 20px;
    .is-chat-route & {
        right: 0;
    }
}

.msg-focused:not(.system) {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        right: -100%;
        bottom: 0;
        animation: fade 2s forwards;
        background-color: lighten($orange, 10%);
    }
}

@keyframes fade {
    from {
        background-color: lighten($orange, 10%);
    }
    to {
        background-color: transparent;
    }
}

.messages {
    @media (max-width: 980px) {
        overflow: hidden;
        position: relative;
    }

    .no-messages {
        height: calc(100vh - 73px);
        background: white;
    }
}

.unread-messages-cnt {
    background-color: #6562d8;
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    border-radius: 100rem;
    right: 0.5rem;
    bottom: -0.15rem;
    color: #fff;
    font-size: smaller;
    text-align: center;
    top: 26px;
}

.btn-load-more {
    margin: 10px auto;
    display: block !important;
    max-width: fit-content;
    max-width: -moz-fit-content;
}

.write {
    input[type='text'] {
        padding-left: 50px !important;
        border-radius: 30px;
        color: #161032;
        height: 30px;
    }

    i {
        font-size: 16px;
        margin-right: 5px;
        &.anticon-message {
            margin-right: 0 !important;
        }
    }

    &.full {
        height: 40px;

        input[type='text'] {
            padding-left: 70px !important;
        }

        i {
            font-size: 20px;
            margin-right: 5px;
        }
    }

    .small & {
        height: auto;
        padding: 3px;
    }

    form {
        width: 100%;
        & > .ant-form-item {
            margin-bottom: 0;
        }
    }

    .ant-input-suffix {
        right: 2px;
    }

    .ant-tag-volcano {
        background: #f8faff;
        opacity: 1;
        border-radius: 0;
        border: none;
        position: absolute;
        top: -100px;
        left: 0;
        height: 170px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 30;
        .anticon-close {
            position: absolute;
            z-index: 3;
            top: 4px;
            right: 0px;
        }
        &.open-graph-msg {
            top: -140px;
            height: 150px;
        }
        &.reply-to-msg {
            top: -170px;
        }
    }
    .file-name {
        display: inline-block;
        margin-left: 10px;
    }
    .anticon-message {
        font-size: 20px;
    }
    .upload-row {
        .ant-btn-primary {
            width: 38px;
            height: 38px;
        }
        width: 100%;
    }
    .open-graph-row {
        width: 100%;
        .opg-text {
            vertical-align: middle;
            display: inline-block;
            color: $violet;
            width: 80%;
            margin-left: 10px;
            p:first-child {
                font-weight: bold;
            }
            p:last-child {
                white-space: normal;
                overflow: hidden;
                height: 32px;
                text-overflow: ellipsis;
            }
        }
    }
    .opg-image {
        max-width: 180px;
        max-height: 120px;
    }

    button.ant-btn-primary {
        position: absolute;
        width: 30px;
    }
}

.send-loading {
    text-align: center;
    margin-top: 20px;
}

.message-container {
    @media (max-width: 980px) {
        .device-android & {
            // Android devices have a bottom status bar that cover our write form so fix this with a smaller main container (-40px)
            // height: calc(100vh - 206px);
        }
    }
}

.message-list {
    .ant-list-item {
        padding: 12px 0 24px;
        border-bottom: none;

        &.system {
            padding: 12px 0 12px;
        }

        &.first-separator {
            padding-bottom: 5px;
        }
    }
}

.mine,
.other {
    & > div {
        min-width: 120px;
        max-width: 96.4%;

        .small & {
            max-width: 93%;
        }
    }
}

.reply-to-msg {
    position: absolute;
    top: -172px;
    left: 0;
    height: 170px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3 !important;
    font-size: 15px;
    p {
        color: $violet;
    }
    img {
        max-width: 200px !important;
        max-height: 120px;
    }
}

.no-messages {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
        text-align: center;
        color: $orange;
    }
    @media (max-width: 768px) {
        display: none;
    }
}

// EMOJY

.emoji-mart-title-label {
    font-size: 18px;
}

// Thumbs Size

$thumbs-size: 300px !important;

.thumb-modal-wrapper {
    .ant-modal-body {
        img {
            width: 100%;
            height: auto;
        }
    }
}

// LAZY LOADER

.thumb {
    &.lazy-loading {
        background: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(25%, #f2f2f2),
            color-stop(37%, #e6e6e6),
            color-stop(63%, #f2f2f2)
        );
        background: linear-gradient(
            90deg,
            #f2f2f2 25%,
            #e6e6e6 37%,
            #f2f2f2 63%
        );
        background-size: 400% 100%;
        -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
        animation: ant-skeleton-loading 1.4s ease infinite;
    }
}

// VIDEO PLAYER

.video-wrapper {
    width: $thumbs-size;
    height: $thumbs-size;
    .mine & {
        margin-right: 20px;
    }
}

// PARTECIPANTS LIST

.partecipants-list {
    .ant-list-item-meta {
        align-items: center;
        margin-bottom: 0;
    }
}

// ANT FIX Z-INDEXES
.ant-drawer,
.ant-popover-placement-top {
    z-index: 999999;
}

.avatar-overlay {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.linkwrapper {
    color: $violet;
    &:hover {
        color: $violet;
        text-decoration: underline;
    }
}

a.textLink {
    color: #575a77;
    text-decoration: underline;
}

// BADGE BACKDOWN

.badge-go-down {
    right: 60px;
    z-index: 10;
    position: absolute;
    bottom: 80px;
    .is-chat-route & {
        position: fixed;
        bottom: 120px;
    }
    sup {
        background-color: orange;
    }
}

// IDLE BOX

.idleBox {
    background: #fff;
    width: 100%;
    margin: 30px auto;
    text-align: center;
    display: none;
    .is-chat-route & {
        display: block;
    }
    h3 {
        color: $orange;
    }
    .imgWrapper {
        box-shadow: 1px 1px 5px #00000021;
        overflow: hidden;
        border-radius: 20px;
        width: 60%;
        margin: 0 auto;
        border: 10px solid white;
        img {
            max-width: 300px;
            height: auto;
        }
    }
    button {
        margin-top: 20px;
    }
}

.channel-list {
    border-top: 1px solid #e8e8e8;
    height: calc(100vh - 78px);

    @media screen and(max-width:768px) {
        height: calc(100vh - 274px);
        overflow-y: scroll;
    }
    li {
        padding-right: 10px;
        padding-left: 0;
        border-left: 10px solid transparent;
        position: relative;
        &.full {
            padding-right: 20px;
            padding-left: 10;
            padding-top: 8px;
            padding-bottom: 8px;
        }
        &:hover {
            background: #e8e8e8;
            h4 {
                div {
                    color: #dd5a0b;
                }
            }
        }
        .ant-badge {
            position: absolute;
            right: 23px;
            top: 33px;
            sup {
                background-color: #dd5a0b;
                p {
                    color: white;
                    line-height: inherit;
                }
            }
        }
        .ant-list-item-meta-description {
            color: #686868;
        }
    }
}

.ant-drawer-wrapper-body {
    button.add-member {
        position: absolute;
        top: 60px;
        right: 20px;
        width: 50px;
        height: 50px;
        z-index: 2;
        margin: 10px 0;
    }
}

.messages .ant-row.write {
    position: relative;
    transition: height 0.2s;
    background: #f8faff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #e8e8e8;
}

.chat-mobile {
    .channel-list {
        height: calc(100vh - 158px);
    }
}

.chat-loader {
    display: none;
}
.is-chat-route {
    .chat-loader {
        display: block;
    }
    .inner-container {
        height: 590px;
        width: 100%;
    }
}

// MINI CHAT HEADER STYLE

.little-header-wrapper {
    background: $violet;
    height: 50px;
    position: absolute;
    top: -50px;
    right: -1px;
    left: -1px;
    border: 0px solid $orange;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3px;
    .chat-opener,
    .chat-fullsize {
        color: #ffffff;
        padding: 8px;
        cursor: pointer;
        width: 40px;
        &:hover {
            color: $orange;
        }
    }
}

.little-right-actions {
    span {
        position: absolute;
        width: 40px;
        height: 40px;
        right: -50px;
        top: 10px;
        padding: 5px;
        background: white;
        border-radius: 10px;
        border: 2px solid $violet;
        color: $violet;
        border-radius: 100%;
        font-size: 24px;
        line-height: 32px;
        &:hover {
            color: $orange;
            border-color: $violet;
        }
        & + span {
            top: 60px;
            & + span {
                top: 110px;
            }
        }
    }
}

/** CHANNEL CREATION */
#sxc-messenger-small {
    .form-wrapper {
        position: absolute;
        width: 100%;
        background: #fff;
        top: 0;
        height: 100%;
        z-index: 99;
        padding: 10px;
        @media (max-width: 980px) {
            height: calc(100vh - 60px);
        }
    }

    .absolute-close {
        position: absolute;
        right: 0;
        font-size: 28px;
        z-index: 1;
    }
}

/** CHANNEL LIST */
#sxc-messenger-small {
    .title-wrapper {
        &:hover {
            color: purple;
        }
        @media screen and (min-width: 980px) {
            max-width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .sender {
        font-size: 13px;
        &.unread {
            font-weight: 600;
            color: #000;
        }
    }

    .badge-custom {
        margin-left: 10px;
        sup {
            background-color: orange;
        }
    }

    .channel-last-msg-time {
        color: gray;
        position: absolute;
        font-size: 11px;
        top: -60px;
        right: 11px;
        &.full {
            top: 9px;
            right: 20px;
        }
    }

    .channel-item-comp {
        cursor: pointer;
        background: #fff;

        &.isactive {
            background: #f9f9f9;
        }
        &.isblockedbyme {
            opacity: 0.5;
        }
    }
}

/** CHAT HEADER **/
#sxc-messenger-small {
    .button-clear {
        margin: 20px 0 10px;
    }

    .admin-label {
        background: rgb(232, 128, 16);
        color: #fff;
        padding: 0 4px;
        border-radius: 3px;
        &.super {
            background: #6562d8;
        }
    }

    .member-name {
        margin: 0 0 0 -10px;
        position: relative;
        & a {
            color: rgba(0, 0, 0, 0.45);
            &:hover {
                color: #dd5a0b;
                text-decoration: none;
            }
        }
    }

    .online-label {
        color: #9fba50;
        font-size: 11px;
        margin-left: -10px;
        margin-bottom: 0;
        font-weight: 600;
    }

    .last-seen-label {
        font-size: 11px;
        margin-left: -10px;
    }

    .small-settings-wrapper {
        position: absolute;
        z-index: 999;
        top: 0;
        background: #fff;
        padding: 10px;
        width: 100%;
        height: 400px;
        overflow-y: scroll;
    }

    .other-member {
        &:hover {
            color: #dd5a0b !important;
            text-decoration: none;
        }
    }
}
.admin-custom-dropdown {
    cursor: pointer;
    position: absolute;
    right: -7px;
    top: -1px;
    padding: 5px;
    border-radius: 100%;
}

/** MESSAGES */
#sxc-messenger-small {
    .badge-icon {
        position: absolute;
        font-size: 24px;
    }
    .edit-wrapper {
        left: -19px;
        bottom: -1px;
        > span {
            color: #666;
            font-size: 17px;
        }
    }
    .day-separator-container {
        width: 100%;
        position: relative;
        flex-direction: column;
        &:after {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            bottom: 12px;
            background: #e6e6e6;
            position: relative;
            z-index: 1;
        }
    }
    .day-separator-label {
        width: 110px;
        height: 28px;
        min-width: 100px;
        background: #eee;
        border-radius: 3px;
        padding: 2px 5px;
        text-align: center;
        border-radius: 30px;
        margin: 0 auto;
        z-index: 2;
        &.full {
            width: 200px;
        }
        :not(.full) {
            font-size: 12px;
        }
    }
    .message-is-writing {
        bottom: 80px;
        min-width: 180px;
        z-index: 99;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #fff;
        padding: 5px 0;
        background: #9fb3d0;
        border-radius: 26px;
        font-size: 13px;
        &.full {
            min-width: 200px;
        }
        &.focus {
            bottom: 180px;
        }
    }

    .edit-label {
        font-size: 10px;
        color: #bb7232;
        font-style: italic;
        margin: 0 0 0 5px;
        display: inline-block;
        width: 48px;
        text-align: right;
        position: absolute;
        bottom: 1px;
        &.isByMe {
            left: 10px;
        }
        :not(.isByMe) {
            right: 10px;
        }
    }

    .message-wrapper {
        margin-bottom: 10px;
        display: block;
        width: 100%;
        padding: 5px 15px;
    }

    .message-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .sender-name {
        &:hover {
            color: #dd5a0b;
            text-decoration: none;
        }
    }

    .chat-message-wrapper {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        white-space: pre-wrap;
        :not(.full) {
            line-height: 22px;
            p {
                font-size: 12.4px;
            }
        }

        p {
            font-size: 12.2px;
            margin-bottom: 6px;
        }
    }

    .message-edit-label {
        font-size: 10px;
        color: #847e78;
        font-style: italic;
        margin: 0 0 0 5px;
        display: inline-block;
        width: 48px;
        text-align: right;
        position: absolute;
        bottom: 3px;
        left: 10px;
        @media (max-width: 640px) {
            font-size: 11px !important;
            width: 70px !important;
            left: 0 !important;
            bottom: -14px !important;
        }
        &.isByMe {
            bottom: 1px;
            color: #bb7232;
        }
    }

    .msg-wrapper {
        margin-bottom: 0;
        display: block;
        padding: 8px 19px;
        min-height: 52px;
    }

    .quote-padder {
        padding: 5px 8px 0;
    }

    .quote {
        cursor: pointer;
        background: #434278;
        border-radius: 2px;
        display: inline-block;
        padding: 8px 10px;
        color: #fff;
        font-size: 12px;
        width: 100%;
    }

    .ant-list-item.mine {
        .quote {
            background: #000;
            margin-bottom: 10px;
        }
        .msg-wrapper {
            padding-top: 22px;
        }
    }

    .quote-sender {
        font-size: 13px;
        text-align: left;
        font-weight: 600;
        display: block;
        width: 100%;
    }

    .message-text {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .audio-padding {
        width: 200px;
        padding: 7px 5px 5px;
    }

    .file-padder {
        width: 100%;
        padding: 5px;
    }

    .image-preview-wrapper {
        width: 170px !important;
        height: 100px !important;
        border: 1px solid #a79f90;
        border-radius: 6px;
        text-align: center;
        overflow: hidden;
        position: relative;
        background: #ffffff;
        &.full {
            width: 220px !important;
            height: 140px I !important;
        }
    }

    .zoom {
        position: absolute;
        width: 20px;
        top: 10px;
        right: 10px;
        color: #ccc;
    }

    .image-preview {
        position: relative;
        max-width: 200% !important;
    }

    .linkwrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .link-title {
        font-size: 14px;
        font-weight: bold;
        background: #160f32;
        padding: 4px 15px;
        color: #fff;
        width: 100%;
    }

    .img-container {
        width: 100%;
        height: 170px;
        position: relative;
        background: #160f32;
        border-radius: 3px 3px 0 0;
        &.full {
            height: 220px;
        }
    }

    .link-image {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .player-preview {
        width: 100% !important;
        min-width: 250px;
        height: 160px !important;
        background: #000;
        position: relative;
        padding-top: 56.25%;
        &.full {
            min-width: 300px;
            height: 240px I !important;
        }
    }

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }

    .message-container {
        background: #fff;
        background-attachment: fixed;
        height: 333px;
        padding: 0 14px;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: height 0.2s;
        &.focus {
            height: calc(333px - 70px);
        }
        &.full {
            padding: 0 20px;
            &.chatMobile {
                height: calc(100vh - 276px);
                &.focus {
                    height: calc(100vh - 340px);
                }
            }
            &:not(.chatMobile) {
                height: calc(100vh - 98px - 50px - 200px);
            }
            &:after {
                top: 68px;
                height: 100%;
            }
        }
        &:not(.isblockedbyme),
        &:not(.isblockingme) {
            &:after {
                display: none;
            }
        }
        &:after {
            content: '';
            width: 100%;
            top: 0;
            height: 354px;
            position: absolute;
            background: #eee;
            opacity: 0.6;
            left: 0;
            z-index: 999;
        }
    }

    .ant-list-item.mine {
        justify-content: flex-end;
    }

    .ant-comment.message-received {
        clear: both;
        line-height: 18px;
        padding: 0;
        position: relative;
        margin: 0;
        min-width: 36%;
        max-width: 50%;
        width: auto;
        height: auto;
        word-wrap: break-word;
        background: rgba(101, 100, 219, 1);
        border-radius: 16px 16px 16px 0;
        float: left;
        .metadata {
            display: inline-block;
            float: right;
            padding: 0 0 0 7px;
            position: relative;
            bottom: -4px;
        }
        &:after {
            border-style: solid;
            border-width: 0px 10px 10px 0;
            border-color: transparent rgba(101, 100, 219, 1) transparent
                transparent;
            top: 0;
            left: -10px;
            position: absolute;
            // content: '';
            width: 0;
            height: 0;
        }
        &.other-removed {
            border-radius: 0px 5px 5px 5px;
            float: left;
            &:after {
                border-width: 0px 10px 10px 0;
                border-color: transparent #e2e2e2 transparent transparent;
                top: 0;
                left: -10px;
            }
        }
    }

    .message-sent {
        color: #fff;
        clear: both;
        line-height: 18px;
        font-size: 14px;
        padding: 0;
        position: relative;
        margin: 0;
        max-width: 50%;
        width: auto;
        height: auto;
        word-wrap: break-word;
        z-index: 3;
        background: rgba(67, 68, 109, 1);
        border-radius: 3px;
        float: right;
        display: flex;
        justify-content: flex-end;
        &.deleted {
            color: #868686;
            font-size: 11px;
            background: #e2e2e2;
        }
        &.full {
            border-radius: 6px 6px 0 6px;
        }
        &.upoverlay {
            z-index: 12;
        }
        .metadata {
            display: inline-block;
            float: right;
            padding: 0 0 0 7px;
            position: relative;
            bottom: -4px;
        }
        &.other-removed {
            border-radius: 0px 5px 5px 5px;
            float: left;
            &:after {
                border-width: 0px 10px 10px 0;
                border-color: transparent #e2e2e2 transparent transparent;
                top: 0;
                left: -10px;
            }
        }
    }

    .message-time {
        font-size: 11px;
        color: #fff;
        position: absolute;
        top: -2px;
        right: 9px;
        &.isByMe {
            top: 5px;
        }
    }

    .message-sent-hearted {
        position: absolute;
        top: 17px;
        left: -45px;
        text-align: right;
        width: 40px;
        height: auto;
        > span {
            color: #999;
        }
    }
}
.message-action {
    color: #d48430;
    position: absolute;
}

/** WRITE */
#sxc-messenger-small {
    .write-image {
        max-width: 150px !important;
        max-height: 100px;
        height: auto;
        text-align: center;
    }
    .prefix-actions {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
    }
    .submit-button {
        width: 38px;
        height: 38px;
        border-color: transparent;
        position: absolute;
        top: 3px;
        right: 10px;
        i {
            margin: 0;
        }
    }
    .open-graph-msg {
        position: absolute;
        top: -192px;
        left: 0;
        height: 190px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 3;
    }
    .open-graph-img {
        max-width: 150px !important;
        max-height: 100px;
        height: auto;
        text-align: center;
    }
    .quote-msg {
        overflow: hidden;
        white-space: normal;
        max-width: 90%;
    }
    .quote-msg-sender {
        margin: 10px 0;
    }
    .share-voucher-wrapper {
        position: absolute;
        top: -192px;
        left: 0;
        height: 190px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 3;
    }
    .share-voucher-img {
        max-width: 150px !important;
        max-height: 100px;
        height: auto;
        text-align: center;
    }
    .write {
        height: 70px;
    }
    .error-attach {
        width: 100%;
        padding: 15px;
        position: absolute;
        top: -82px;
        z-index: 10;
    }
}

/** SEARCH FRIEND */
#sxc-messenger-small {
    .search-title {
        margin-top: 10px;
        color: #161032;
        font-weight: bold;
    }

    .search-wrap {
        display: inline-block;
        width: 100%;
    }
}

/* USER */
#sxc-messenger-small {
    .user {
        padding: 15px;
        width: 100%;
        text-align: center;

        div,
        h4 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }
        h4 {
            @media screen and (min-width: 980px) {
                max-width: 170px;
                overflow: hidden;
            }
        }

        .avatar-wrapper {
            margin: 0 auto;
        }
    }
}

/* COMMOM  */
#sxc-messenger-small {
    .loader-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .little-header-actions {
        display: flex;
        justify-content: space-between;
        margin-right: 8px;
    }
    .little-header-title {
        width: 240px;
        color: #fff;
        font-size: 15px;
        margin: 0 0 0 10px;
    }
    .avatar-wrapper {
        background-size: cover;
        background-position: 50%;
        border-radius: 100%;
        &.left {
            float: left;
            margin-right: 10px;
        }
        &.inline {
            display: inline-block;
        }
        &.absolute {
            position: absolute;
            z-index: 99;
            left: -17px;
            top: -15px;
            border: 2px solid #d0d4dc;
            &.full {
                left: -20px;
            }
        }
        &.disabled {
            opacity: 0.5;
        }
        img {
            width: 100%;
            height: auto;
            opacity: 0;
        }
    }
}

#userbadges {
    > .ant-row {
        justify-content: flex-end;
    }

    .ant-card-body {
        text-align: center;
    }

    .badge-count {
        min-height: 1.5rem;
    }
    .badge-rules-link {
        color: $orange;
        &:hover {
            text-decoration: underline;
        }
    }
}

.badge-listing-icon {
    $imageSize: 90px;
    $fontSize: 80px;
    overflow: hidden;
    border-radius: 100%;
    width: $imageSize;
    height: $imageSize;
    display: block;
    margin: 30px auto;
    background: $violet;
    box-shadow: 2px 2px 5px rgba($violet, 0.5);

    @include badge-icons($imageSize, $fontSize);

    a {
        width: $imageSize;
        height: $imageSize;
    }
}

#profile-navigation {
  > * {
    p {
      color: $black;
    }

    p.selected {
      border-bottom: 1px solid $black;
    }
  }

  > *:hover {
    cursor: pointer;
  }

  .profile-icon {
    font-size: 20px;
    color: $orange;
  }
}
#leftmenu {
    .ant-menu {
        border-radius: 5px;
        overflow: hidden;
        padding: 0;
        .ant-menu-item {
            margin: 0;
            &.active,
            &:hover {
                background: $orangelight;
                color: $white;
            }
            &.active {
                pointer-events: none;
            }
        }
    }

    .orange-badge {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('/assets/images/badges/Orange-premium-icon.svg')
            no-repeat center center;
        background-size: 100%;
        margin-top: -2px;
        vertical-align: middle;
    }
}
.ant-row.col-3-layout {
    .ant-col.col-3-layout-left {
        .ant-radio-group.ant-radio-group-outline {
            width: 100%;
            margin-bottom: 10px;

            .ant-radio-button-wrapper {
                width: 50%;
                text-align: center;
            }

            .ant-radio-button-wrapper:hover,
            .ant-radio-button-wrapper-checked {
                border-color: $orangedark;
                color: $orangedark;
                &:before,
                &:after {
                    background-color: $orangedark;
                }
            }
        }

        .switch-menu {
            &:after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 0.255em;
                vertical-align: 0.255em;
                content: '';
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-left: 0.3em solid transparent;
            }
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            padding: 18px 10px;
            border: none;
            background: #ececec !important;
            color: inherit;
        }

        .ant-list-split .ant-list-item:not(.item-profile) {
            font-size: 12px;
            padding: 10px 0 10px 20px;
            border: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            color: $violetdark;
            margin-bottom: 0;
            background: #f5f5f5;
            transition: all 8ms;
            border-radius: 5px;
            justify-content: flex-start;

            &:before {
                font-size: 18px;
                font-family: 'icomoon';
                display: inline-block;
                margin-right: 5px;
            }

            &:hover {
                background: #ececec;
            }

            &.active {
                background: #ffe6da59;
                font-weight: 600;
                border: 2px solid $orange;
                padding-left: 15px;
            }

            &.profile {
                &:before {
                    content: '\e910';
                }
            }
            &.timeline {
                &:before {
                    content: '\e903';
                }
            }
            &.messages {
                &:before {
                    content: '\e905';
                }
            }
            &.notifications {
                &:before {
                    content: '\e914';
                }
            }
            &.friends {
                &:before {
                    content: '\e904';
                }
            }
            &.mybadges {
                &:before {
                    content: '\e900';
                }
            }
            &.ranking {
                &:before {
                    content: '\e90c';
                }
            }
            &.groups {
                &:before {
                    content: '\e91f';
                }
            }
            &.myaccount {
                &:before {
                    content: '\e906';
                }
            }
            &.mywallet {
                &:before {
                    content: '\e907';
                }
            }
            &.mycredits {
                &:before {
                    content: '\e908';
                }
            }
            &.mypoints {
                &:before {
                    content: '\e901';
                }
            }
            &.creditcoming {
                &:before {
                    content: '\e909';
                }
            }
            &.mycards {
                &:before {
                    content: '\e90a';
                }
            }
            &.myinvoices {
                &:before {
                    content: '\e93e';
                }
            }
            &.logout {
                &:before {
                    content: '\e915';
                }
            }
            &.familybox {
                &:before {
                    content: '\e932';
                }
            }
            &.pagameno {
                &:before {
                    content: '\e91e';
                }
            }
            &.ricaricacell {
                &:before {
                    content: '\e925';
                }
            }
            &.utilities {
                &:before {
                    content: '\e92f';
                }
            }
            &.sostariffe {
                &:before {
                    content: '\e93a';
                }
            }
            &.soisy {
                &:before {
                    content: '\e92d';
                }
            }
            &.orange {
                &:before {
                    content: '\e92c';
                }
            }
            &.refundarea {
                &:before {
                    content: '\e93d';
                }
            }
            &.momosy_rules,
            &.howitworks {
                &:before {
                    content: '\e989';
                }
            }
            &.guadagnalink {
                &::before {
                    content: '\e924';
                }
            }
            &.sxccard,
            &.diana {
                &:before {
                    content: '\e98b';
                }
            }

            &.sxcticket {
                &:before {
                    content: '\e93b';
                }
            }
        }

        .ant-list-split .ant-list-item.item-profile {
            padding-bottom: 8px;

            .ant-avatar {
                margin-right: 5px;
            }
        }
    }
}

#menuleft-mobile {
    @include maxWidth;
    margin-top: 20px;

    .ant-select {
        width: 100%;
    }
}

.adSlots {
    clear: both;
    margin: 0 auto;
    background: transparent;
    display: block;
    position: relative;

    @media (max-width: 970px) {
        display: none;
    }

    &.bottom20 {
        margin-bottom: 20px;
    }
    &.bottom30 {
        margin-bottom: 30px;
    }
    &.bottom40 {
        margin-bottom: 40px;
    }

    &.w970x250 {
        width: 970px;
        max-height: 250px;
        text-align: center;

        .profile-container &,
        #usr_cnt_tim &,
        #usr_cnt_wlt &,
        .clubs-container & {
            margin-bottom: 30px;
        }

        @media (max-width: 1280px) {
            .col-2-layout & {
                width: 100%;
            }
        }

        @media (max-width: 970px) {
            .col-1-layout {
                width: 100%;
            }
        }
    }

    &.w300x250 {
        width: 300px;
        height: 250px;

        @media (max-width: 1280px) {
            // exclude voicher list
            .col-2-layout > div:not(.shopping-main-content) & {
                width: 100%;
            }

            .col-2-layout .shoppingcard_in_all {
                display: none;
            }
        }

        @media (max-width: 970px) {
            .col-1-layout {
                width: 100%;
            }

            .flex-for-adv &,
            .offer-details-left &,
            .col-2-layout > .shopping-main-content &,
            .bulletin-container & {
                display: none;
            }
        }
    }

    &.w300x600 {
        width: 300px;
        height: 600px;
        margin-bottom: 30px;

        @media (max-width: 1280px) {
            // exclude voicher list
            .col-2-layout > div:not(.shopping-main-content) & {
                width: 100%;
            }
        }

        @media (max-width: 970px) {
            .col-1-layout {
                width: 100%;
            }
            .col-2-layout > .shopping-main-content &,
            .bulletin-container &,
            .user-area & {
                display: none;
            }
        }
    }

    &.w120x600 {
        width: 120px;
        height: 600px;
        &:after {
            writing-mode: vertical-rl;
        }

        @media (max-width: 1280px) {
            .col-2-layout & {
                width: 100%;
            }
        }

        @media (max-width: 970px) {
            .col-1-layout {
                width: 100%;
            }

            .flex-for-adv & {
                display: none;
            }
        }
    }

    &.w160x600 {
        width: 160px;
        height: 600px;
        &:after {
            writing-mode: vertical-rl;
        }

        @media (max-width: 1280px) {
            .col-2-layout & {
                width: 100%;
            }
        }

        @media (max-width: 970px) {
            .col-1-layout {
                width: 100%;
            }

            .flex-for-adv & {
                display: none;
            }
        }
    }

    .aside-menu & {
        @media (max-width: 970px) {
            display: none;
        }
    }
}

#home {
    .adSlots.w970x250 {
        margin-bottom: 30px;
    }

    .hghlt.flex-for-adv {
        display: flex;
    }
}

.layout {
    .container.flex-for-adv {
        display: flex;

        > .pt-4 {
            flex: 1;
        }

        .adSlots.w300x250 {
            margin-top: 60px;
        }
    }
}

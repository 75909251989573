#signin,
#register,
#forgotpassword,
#resetpassword {
    h3 {
        margin-bottom: 20px;
    }
    h5 {
        margin-bottom: 40px;
    }

    .ant-form-item-has-error {
        .ant-checkbox-inner {
            border-color: #ee4834;
        }
    }
    .link {
        font-size: 12px;
        color: $orange;
        display: block;
        width: 100%;
        &:hover {
            color: $orange;
            text-decoration: underline;
        }
    }
    .btn-submit {
        width: 100%;
        height: 40px;
        display: block;
        margin: 0 auto;
    }
    .social-btn-wrapper {
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;

        .social-btn {
            width: 100%;
            min-height: 44px;
            border: 1px solid rgba(22, 24, 35, 0.12);
            box-sizing: border-box;
            border-radius: 2px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 12px;
            margin: 5px;
            &#LoginWithAmazon {
                background: #ffd86c;
            }
            &#LoginWithFacebook {
                background: #3570dc;
                color: #fff;
            }

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
            & > img {
                display: none;
            }
            cursor: pointer;
            .social-icon {
                width: 34px;
                margin-right: 10px;
                min-height: 34px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
        .apple-sign-in {
            appearance: none;
            -webkit-appearance: none;
            padding: 7px 40px;
            border: none;
            color: #fff;
            background-color: black;
            font-size: 16px;
            cursor: pointer;
            border-radius: 4px;
        }
    }
}

#register {
    @media #{$mq-sm} {
        .registration-stepper {
            margin-left: -22px;
        }
    }
    @media #{$mq-sm} {
        .ant-steps-item-container {
            height: 20px;
        }
    }
    .skip {
        display: block;
        margin: 20px auto 0;
        text-align: center;
        color: #b3b3b3;
        font-size: 13px;
    }
    .custom-step {
        font-size: 14px;
        width: 20px;
        height: 20px;
        display: block;
        background: $orange;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
    }
    .ant-form-item {
        margin-bottom: 10px;
    }
    .ant-card-bordered {
        max-width: 700px;
        margin: 0 auto;
    }
    .ant-checkbox-wrapper {
        line-height: 1em;
    }

    .ant-steps-item-description {
        font-size: 12px;
    }

    .shop-card-img {
        @media #{$mq-xs} {
            text-align: center;
        }
    }

    .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: $orangelight;
    }

    .ant-upload {
        margin: 0;
    }

    .price-example {
        .ant-typography {
            display: flex;
            justify-content: space-between;
        }
        strong {
            color: $orange;
            position: relative;
            span:first-child {
                color: $black;
                font-weight: 100;
                display: inline-block;
                text-decoration: line-through;
            }
            span:nth-child(2) {
                position: absolute;
                left: 0;
                bottom: -14px;
            }
        }
    }

    .checkbox-wrapper {
        padding: 20px !important;
        border: 1px solid #e7e7e7;
        border-radius: 20px;
        .ant-alert {
            margin-bottom: 20px;
        }
    }

    .social-btn-wrapper {
        .social-btn {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .ant-upload.ant-upload-select-picture-card:hover {
        border-color: $orange;
    }
}

// PASSWORD SECURITY METER

$strength-colors: (darkred, orangered, orange, yellowgreen, green);
$strength-gap: 6px;
.main-container {
    position: relative;
    width: 400px;
    top: 100px;
    // bottom: 0;
    // left: 0;
    // right: 0;
}

.strength-meter {
    position: relative;
    height: 2px;
    background: #ddd;
    margin: 7px 0;
    border-radius: 2px;
    overflow: hidden;
    &:before,
    &:after {
        content: '';
        height: inherit;
        background: transparent;
        display: block;
        border-color: #fff;
        border-style: solid;
        border-width: 0 $strength-gap 0;
        position: absolute;
        width: calc(20% + #{$strength-gap});
        z-index: 2;
    }
    &:before {
        left: calc(20% - #{($strength-gap / 2)});
    }
    &:after {
        right: calc(20% - #{($strength-gap / 2)});
    }
}

.strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    transition: width 0.5s ease-in-out, background 0.25s;
    @for $i from 1 through 5 {
        &[data-strength='#{$i - 1}'] {
            width: (20% * $i);
            background: nth($strength-colors, $i);
        }
    }
}

.ant-modal.auth-modal {
    border-radius: 6px;
    width: 900px !important;
    @media #{$mq-xs} {
        width: 100% !important;
    }

    .ant-modal-body {
        padding: 0;

        .ant-card-bordered {
            padding: 30px 40px;
            border: 0;
        }

        .ant-input {
            border: 0;
            border-bottom: 2px solid $violetdark !important;
            padding-left: 30px;
        }

        #reg_firstName,
        #reg_lastName {
            padding-left: 5px;
        }

        .ant-input-password,
        .ant-input-affix-wrapper {
            border: 0;
            padding: 0;
        }

        .ant-form-item-has-error
            :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
            border-color: #ff4d4f !important;
        }

        .ant-input-prefix {
            font-size: 20px;
            position: absolute;
            z-index: 999;
        }
    }
}

#userorange {
    padding-top: 50px;

    .info-box {
        margin-bottom: 10px;
    }

    .expiry-date {
        span {
            color: $orangelight;
            display: inline-block;
            margin-right: 10px;
        }
    }

    .orange-subscription {
        text-align: center;
        border: 1px solid transparent;
        opacity: 0.8;
        cursor: pointer;
        position: relative;
        transition: transform 0.3s;
        &.selected {
            border-color: $violet;
            opacity: 1;
            transform: scale(1.03);
        }
        .ant-card-body {
            padding: 0;
        }
        h5 {
            background-image: url(/assets/images/orange/orange-premium-bg-tiny.png);
            background-size: 100% auto;
            background-repeat: no-repeat;
            padding: 20px;
            font-size: 20px;
            color: $white !important;
        }
        .month-price {
            text-transform: lowercase;
            font-size: 16px;
            color: $orange;
            margin-bottom: 10px;
        }
        .total {
            font-size: 18px;
            margin-bottom: 40px;
            strong {
                line-height: 1em;
                display: block;
                font-size: 25px;
                color: $orange;
            }
        }

        .orange-check {
            position: absolute;
            bottom: -8px;
            left: 50%;
            margin-left: -20px;
            width: 40px;
            height: 40px;
            background-image: linear-gradient($orangelight, $orange);
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: #fff;
                font-size: 20px;
            }
        }
    }
    .bttn-2 {
        background-image: linear-gradient(to right, #161032 0%, #6562d8 100%);
        margin: 0px auto;
        display: block;
        width: 150px;
        font-size: 18px;
        transition: none;
        &:hover {
            border-color: $violetdark;
        }
    }

    .redeem-form-wrapper {
        opacity: 0.5;
        pointer-events: none;
        .ant-alert {
            display: none;
        }
        .extra-note {
            font-size: 12px;
            color: grey;
            margin-top: -18px;
        }
        .bttn-redeem {
            display: none;
        }
        &.redeem-visibile {
            opacity: 1;
            pointer-events: initial;
            .bttn-redeem {
                display: block;
            }
            .ant-alert {
                display: inherit;
            }
        }
    }

    .bttn-selectplan {
        display: none;
        &.btn-visibile {
            display: block;
        }
    }

    .redeem-code {
        input {
            border: 1px solid $orangelight;
            max-width: 300px;
            padding: 10px;
        }
    }
    .input-share-wrapper {
        position: relative;
        .success {
            position: absolute;
            bottom: -20px;
            right: 0;
        }
    }
    .input-share {
        padding: 0 0 0 20px;
        button {
            border: none;
        }
        &:hover {
            border-color: initial;
        }
    }

    .checkout-grid {
        .item {
            display: flex;
            align-items: center;
            & > :first-child {
                flex-grow: 1;
            }
        }
    }

    .ant-radio-group {
        display: flex;
        flex-direction: column;
        .ant-radio-wrapper {
            display: flex;
            align-items: center;
            margin-right: 0;
        }
        .ant-radio + span {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
            padding-right: 0;
            & > div {
                flex-grow: 1;
                text-transform: capitalize;
            }
            img {
                padding: 5px;
                width: 70px;
                max-height: 41px;
                object-fit: contain;
                border-radius: 5px;
                box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .orange-checkout {
        .bttn-checkout {
            color: $white;
            margin: 20px auto;
            background: linear-gradient(to right, $violetdark 0%, $violet 100%);
            display: block;
            &:hover {
                border-top-color: $violetdark;
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }
}

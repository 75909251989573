#userranking {
    > .ant-row {
        justify-content: flex-end;
    }

    .anticon-loading {
        margin: 30px auto;
        font-size: 30px;
        display: block;
    }
}

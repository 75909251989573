#ticketmodal {
    text-align: center;
    font-size: 1rem;
    h4 {
        color: $orangedark;
    }
    .error-message-ico {
        background: url(/assets/images/icons/attention.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 5rem;
        margin: 0 auto 1rem;
        padding-top: 5rem;
    }
}

#userticket {
    padding-top: 50px;

    .anticon-loading {
        margin: 30px auto;
        font-size: 30px;
        display: block;
    }

    .banner {
        margin-top: 20px;
        background: #fff;
        border: 1px solid #efe9e4;
        font-size: 1rem;

        &.sxc-banner {
            background: #43446d;
            height: 15rem;
            position: relative;
            padding-top: 2rem;

            .header h3 {
                color: #fff;
                font-size: 32px;
                text-align: center;
                margin-bottom: 0;
            }

            .header h4 {
                font-size: 60px;
                color: #fff;
                text-align: center;
                margin-top: 0;
            }
        }

        h3.header {
            font-size: 22px;
            margin-top: 0.5rem;
            span {
                color: $orangedark;
            }
        }

        .method {
            position: relative;
            display: inline-block;
            line-height: 60px;

            &:before {
                font-family: 'icomoon';
                display: block;
                font-size: 56px;
            }

            &.ticket {
                padding-left: 60px;
                &:before {
                    content: '\e93b';
                    position: absolute;
                    left: 0;
                }
            }
        }

        input[type='text'] {
            height: 44px;
            border: 2px solid #ffc386;
            border-radius: 6px;
            font-size: 17px;
            padding: 0 20px;
            margin-bottom: 20px;
            margin-right: 30px;
            width: 400px;

            &.error {
                border-color: #ff4949;
            }
        }
    }
}

#home {
    position: relative;
    .welcome-credits-banner {
        position: absolute;
        background-color: $lighterorange;
        border: 1px solid $orange;
        color: $violetdark;
        padding: 6px;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        transition: .5s;
        font-weight: 600;
        -webkit-box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.25);
        box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.25);
        z-index: 1;
    }

    .welcome-credits-banner:hover {
        background-color: #ffffff;
        transition: .5s;
        cursor: pointer;
    }

    padding-top: 50px;
    @media screen and (max-width: 640px) {
        padding-top: 10px;

        .welcome-credits-banner {
            top: -20px;
        }
    }

    .ant-card-bordered.screen-2,
    .ant-card-bordered.screen-4 {
        background: #f5f5f5;
        padding: 20px;
        border: 0;
    }

    .screen-1 {
        h1.ant-typography {
            font-size: 5.2em;
            line-height: 5.7rem;
            margin-top: 30px;
            @media screen and (max-width: 640px) {
                font-size: 3rem;
                line-height: 3rem;
            }
        }
        h3.ant-typography {
            font-size: 2.3em;
            margin-top: 22px;
            margin-bottom: 20px;
            @media screen and (max-width: 640px) {
                font-size: 2rem;
                line-height: 3rem;
                margin-top: 5px;
            }
        }
        h4.ant-typography {
            margin-bottom: 40px;
        }

        button.ant-btn {
            min-width: 100px;
            height: 40px;
            font-size: 20px;
        }
    }

    .screen-1,
    .screen-3 {
        margin-bottom: 80px;
    }

    .screen-2,
    .screen-4 {
        > .ant-col {
            @include maxWidth();
        }
    }

    .orange-banner {
        padding: 30px 0;
        background-image: url('/assets/images/banner-orange-big.png');
        background-repeat: no-repeat;
        background-position: 70% 100%;
        background-size: 36%;
        @media screen and (max-width: 640px) {
            background-size: 60%;
            background-position: 100% 100%;
        }
        .ant-typography {
            margin-bottom: 0;
        }

        .ant-col {
            @media screen and (max-width: 640px) {
                margin-bottom: 10px;
            }
        }

        h1 {
            font-size: 3rem;
            @media screen and (max-width: 640px) {
                font-size: 1.5rem;
            }
        }

        .color-orange {
            margin-top: 0;
            color: $orange !important;
        }
    }
}

#usermomosy {
    > .ant-row {
        justify-content: flex-end;
    }
    h5 {
        color: $orange;
    }
    ul {
        list-style: circle;
    }
}

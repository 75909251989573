/**
* COLORS
*/

$white: #fff;
$gray: #f5f5f5;
$black: #161032;
$violet: #6564db;
$violetdark: #43446d;
$orange: #f47f00;
$orangelight: #ffa630;
$lighterorange: #FFE2AE;
$orangedark: #dd5a0b;
$light-background: #fff0e6;

/**
* MEDIA QUERIES
*/

$mq-lg: 'only screen and (min-width: 992px)';
$mq-md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$mq-sm: 'only screen and (min-width: 576px) and (max-width: 767px)';
$mq-xs: 'only screen and (max-width: 575px)';

@mixin maxWidth() {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 50px;
    @media screen and (max-width: 1240px) {
        max-width: 94%;
    }
}

/**
* CARDS
*/
$box-shadow: 2px 2px 2px 0px #efefef;

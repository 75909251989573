@charset "UTF-8";
/**
* NEW - REACT
*/
/**
* COLORS
*/
/**
* MEDIA QUERIES
*/
/**
* CARDS
*/
/**
* NEW BRAND - START
 */
.light-background {
  background-color: rgba(37, 211, 54, 0.1); }

/**
* NEW BRAND - END
 */
/**************
***** BODY
**************/
* {
  font-family: 'Lato', sans-serif; }

body {
  background: #fff;
  color: #6A706E; }

body a {
  color: #25D366; }

ul {
  list-style: none; }

a:focus {
  color: inherit;
  text-decoration: none; }

img {
  max-width: 100%; }

#boot {
  min-height: 100vh; }
  #boot .max-width {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 50px; }
    @media screen and (max-width: 1240px) {
      #boot .max-width {
        max-width: 94%; } }

/**
 * GENERIC
 */
.align-left {
  text-align: left !important; }

.align-right {
  text-align: right !important; }

.align-center {
  text-align: center !important; }

.color-orange {
  color: #25D366 !important; }

.color-orangedark {
  color: #6A706E !important; }

.violet-column {
  background: rgba(101, 100, 219, 0.1); }

/**
 * GRID
 */
.flex-break {
  flex-basis: 100%;
  height: 0; }

/**
 * ANT RESET
 */
.ant-row {
  margin-bottom: 20px; }

.ant-btn-primary {
  background: #25D366;
  border-color: #25D366; }

.ant-row-end {
  display: flex;
  justify-content: flex-end; }

.ant-input-affix-wrapper::before {
  content: '';
  display: none; }

.ant-input-affix-wrapper .ant-input {
  padding-left: 10px; }

.ant-dropdown {
  z-index: 999999999; }

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #137939; }

.ant-list-split {
  width: 100%; }

.ant-upload-list-item {
  display: none; }

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  color: #6A706E !important; }

.ant-steps .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
  color: #fff; }

.ant-steps .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
  background-color: #25D366;
  border-color: #25D366; }

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #25D366; }

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #25D366; }

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #25D366; }

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #25D366; }

.ant-descriptions-item.disabled {
  opacity: 0.4; }

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover {
  border-color: #25D366;
  box-shadow: none; }

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: initial; }

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(37, 211, 54, 0.1) !important;
  color: #25D366; }

.ant-menu-vertical .ant-menu-item:hover {
  color: #25D366 !important; }

.ant-btn-primary {
  background: #25D366 !important;
  border: none !important; }

/**
 * FORM
 */
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input[type='password']:-webkit-autofill,
input[type='password']:-webkit-autofill:hover,
input[type='password']:-webkit-autofill:focus,
input[type='password']:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

::placeholder {
  font-size: 13px; }

:focus {
  outline-width: 0px !important; }

button.ant-btn {
  border-radius: 6px; }
  button.ant-btn:hover, button.ant-btn:focus {
    color: white;
    background-color: #25D366;
    border-color: #25D366; }
  button.ant-btn.ant-btn-sm {
    padding-bottom: 22px; }
  button.ant-btn.orange-btn {
    background: #25D366;
    color: #fff;
    border: 2px solid #25D366; }
    button.ant-btn.orange-btn:hover {
      background: white;
      border: 2px solid #25D366;
      color: #161032; }
  button.ant-btn.white-btn {
    border: 2px solid #25D366; }
    button.ant-btn.white-btn:focus, button.ant-btn.white-btn:hover {
      color: #333;
      background-color: #f5f5f5; }
  button.ant-btn.dark-btn {
    background: #6A706E;
    color: #fff; }
    button.ant-btn.dark-btn:hover, button.ant-btn.dark-btn:focus {
      border-color: #6A706E;
      background: #6A706E; }
    button.ant-btn.dark-btn:disabled {
      background-color: #c4c4c4; }
  button.ant-btn.ant-btn-dangerous:hover, button.ant-btn.ant-btn-dangerous:focus {
    background: #ff7875;
    border-color: #ff7875; }
  button.ant-btn.ant-btn-lg {
    width: 100%; }
  button.ant-btn.xl {
    font-size: 26px;
    padding: 0 30px;
    height: auto; }

p.input-note {
  color: #908da0;
  padding-top: 12px;
  font-size: 13px;
  line-height: 14px;
  font-family: sans-serif; }

textarea {
  outline-width: 0px; }

/**
 * 3 COLUMNS LAYOUT
 */
.ant-row.col-3-layout {
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left {
    flex: 0 0 240px;
    max-width: 240px;
    margin-top: 9px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .ant-row.col-3-layout .ant-col.col-3-layout-left {
        flex: 0 0 17%;
        max-width: 17%; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .ant-row.col-3-layout .ant-col.col-3-layout-left {
        display: none; } }
  .ant-row.col-3-layout .ant-col.col-3-layout-center {
    width: initial;
    flex: 0 0 880px;
    padding: 0;
    max-width: 880px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .ant-row.col-3-layout .ant-col.col-3-layout-center {
        flex: 0 0 67%;
        max-width: 67%; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .ant-row.col-3-layout .ant-col.col-3-layout-center {
        flex: 0 0 83%;
        max-width: 83%; } }
    @media only screen and (max-width: 575px) {
      .ant-row.col-3-layout .ant-col.col-3-layout-center {
        flex: 0 0 100%;
        max-width: 100%; } }
  .ant-row.col-3-layout .ant-col.col-3-layout-right {
    flex: 0 0 220px;
    max-width: 220px;
    text-align: center;
    margin-top: 68px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .ant-row.col-3-layout .ant-col.col-3-layout-right {
        flex: 0 0 16%;
        max-width: 16%; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .ant-row.col-3-layout .ant-col.col-3-layout-right {
        display: none; } }

/*
 * OVERRIDE ANT THEME COLORS
 */
.ant-input:focus,
.ant-input-focused {
  border-color: #25D366;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(37, 211, 102, 0.2);
  box-shadow: 0 0 0 2px rgba(37, 211, 102, 0.2); }

.ant-input:hover {
  border-color: #25D366;
  border-right-width: 1px !important; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #25D366; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #25D366;
  border-color: #25D366; }

.ant-checkbox-checked:after {
  border-color: #25D366; }

.ant-btn-background-ghost.ant-btn-primary {
  color: #25D366;
  background-color: transparent;
  border-color: #25D366;
  text-shadow: none; }

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #25D366;
  background-color: transparent;
  border-color: #25D366; }

.ant-pagination-item-active {
  border-color: #25D366; }

.ant-pagination-item:hover, .ant-pagination-item:focus {
  border-color: #25D366; }

.ant-pagination-prev:hover,
.ant-pagination-next:hover {
  color: #25D366; }

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #25D366; }

.ant-radio-checked:after {
  border-color: #25D366 !important; }

.ant-radio-checked .ant-radio-inner {
  border-color: #25D366 !important; }

.ant-radio-inner:after {
  background-color: #25D366 !important; }

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #25D366;
  box-shadow: 0 0 0 3px rgba(37, 211, 102, 0.08);
  border-radius: 50%; }

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #f9fefb; }

.ant-form .ant-form-item-with-help {
  margin-bottom: 20px; }

.ant-form-item-control-input-content .ant-input-number {
  width: 100%; }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input),
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-focused .ant-select-selector,
.ant-select-open .ant-select-selector,
.ant-picker-focused,
.ant-select-focused,
.ant-picker:hover {
  border-color: #25D366 !important;
  box-shadow: none !important;
  outline-color: transparent !important; }

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #25D366; }

.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #25D366;
  background: #fff;
  border-color: #25D366; }

.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-group .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-color: #25D366; }

.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #25D366; }

.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #25D366; }

.ant-radio-group .ant-radio-button-wrapper:hover {
  color: #25D366; }

.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #25D366;
  border-color: #25D366; }

.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #25D366; }

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: #25D366 !important; }

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: #25D366 !important;
  left: 0; }

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #25D366 !important; }

.col-1-layout {
  max-width: 1360px;
  margin: 0 auto; }
  .col-1-layout.w1120 {
    max-width: 1120px; }

.col-2-layout {
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  .col-2-layout.w1120 {
    max-width: 1120px; }
  @media (max-width: 768px) {
    .col-2-layout {
      flex-direction: column;
      min-height: 190px; } }
  .col-2-layout .col-2-layout-left {
    flex: 0 1 300px;
    z-index: 9; }
    @media (max-width: 1270px) {
      .col-2-layout .col-2-layout-left {
        flex: 0 1 26%;
        max-width: 26%; } }
    @media (max-width: 768px) {
      .col-2-layout .col-2-layout-left {
        flex: 0 1 100%;
        max-width: 100%; } }
  .col-2-layout .col-2-layout-right {
    width: initial;
    flex: 0 1 970px;
    padding: 0; }
    @media (max-width: 1270px) {
      .col-2-layout .col-2-layout-right {
        flex: 0 1 70%;
        max-width: 70%; } }
    @media (max-width: 768px) {
      .col-2-layout .col-2-layout-right {
        flex: 0 1 100%;
        max-width: 100%; } }
    .col-2-layout .col-2-layout-right .card-info {
      margin: 2rem 0; }

.col-3-layout {
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  .col-3-layout .col-3-layout-left {
    flex: 0 0 240px;
    max-width: 240px;
    margin-top: 9px; }
    @media screen and (max-width: 1360px) {
      .col-3-layout .col-3-layout-left {
        flex: 0 0 17%;
        max-width: 17%; } }
    @media screen and (max-width: 960px) {
      .col-3-layout .col-3-layout-left {
        display: none; } }
  .col-3-layout .col-3-layout-center {
    width: initial;
    flex: 0 0 880px;
    padding: 0;
    max-width: 880px; }
    @media screen and (max-width: 1360px) {
      .col-3-layout .col-3-layout-center {
        flex: 0 0 67%;
        max-width: 67%; } }
    @media screen and (max-width: 1024px) {
      .col-3-layout .col-3-layout-center {
        flex: 0 0 83%;
        max-width: 83%; } }
    @media screen and (max-width: 960px) {
      .col-3-layout .col-3-layout-center {
        flex: 0 0 100%;
        max-width: 100%; } }
    .col-3-layout .col-3-layout-center .card-info {
      margin: 2rem 0; }
  .col-3-layout .col-3-layout-right {
    flex: 0 0 220px;
    max-width: 220px;
    text-align: center;
    margin-top: 68px; }
    @media screen and (max-width: 1360px) {
      .col-3-layout .col-3-layout-right {
        flex: 0 0 16%;
        max-width: 16%; } }
    @media screen and (max-width: 1024px) {
      .col-3-layout .col-3-layout-right {
        display: none; } }

.navigation .ant-typography {
  cursor: pointer; }
  .navigation .ant-typography:hover {
    text-decoration: underline; }
  .navigation .ant-typography.active {
    cursor: default;
    color: #25D366; }
    .navigation .ant-typography.active:hover {
      text-decoration: none; }

.unclickable {
  cursor: default !important; }

.write-post.bordered .write-post__input {
  border: 1px solid lightgrey; }

.write-post .write-post__input {
  border: 0; }

.write-post .write-post__suggestions {
  border: 1px solid lightgrey; }
  .write-post .write-post__suggestions .write-post__suggestions__item {
    padding: 0.5em 1em; }
  .write-post .write-post__suggestions .write-post__suggestions__item:hover {
    background-color: lightgrey; }

.pointer:hover {
  cursor: pointer; }

#cart {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto 50px;
  min-height: 40vh;
  padding-top: 20px; }
  @media screen and (max-width: 1240px) {
    #cart {
      max-width: 94%; } }
  #cart.revolut {
    margin-top: 20px; }
    #cart.revolut iframe {
      height: 50px !important;
      margin-bottom: 20px !important; }
  #cart .ant-steps {
    margin-bottom: 20px;
    background: #fff;
    padding: 12px 30px 15px; }
    #cart .ant-steps .ant-steps-item-icon {
      cursor: pointer; }
  #cart .vouchers-wrapper {
    min-height: 413px; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      #cart .vouchers-wrapper {
        min-height: 0; } }
  #cart .voucher-list-cart .voucher-single-cart {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px; }
  #cart .voucher-list-cart .voucher-premium-cart {
    border-top: 1px dashed #137939;
    padding-top: 10px;
    margin-top: 10px; }
    #cart .voucher-list-cart .voucher-premium-cart h5 {
      color: #25D366; }
  #cart .ant-descriptions-item-container {
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 10px;
    align-items: center; }
    #cart .ant-descriptions-item-container .ant-descriptions-item-label span.anticon {
      font-size: 19px;
      cursor: pointer;
      color: #6A706E; }
    #cart .ant-descriptions-item-container .ant-descriptions-item-label img {
      width: 80px; }
    #cart .ant-descriptions-item-container .ant-descriptions-item-content {
      text-align: right;
      font-weight: 600;
      display: table-cell; }
  #cart .ant-descriptions-item.total .ant-descriptions-item-label,
  #cart .ant-descriptions-item.total .ant-descriptions-item-content {
    font-size: 24px;
    color: #25D366; }
  #cart .ant-row.ant-form-item {
    flex-direction: column; }
    #cart .ant-row.ant-form-item .ant-form-item-label {
      text-align: left; }
      #cart .ant-row.ant-form-item .ant-form-item-label label {
        font-weight: 600;
        font-size: 12px;
        font-family: verdana;
        margin-bottom: 0; }
  #cart .cart-billing .ant-checkbox-wrapper {
    margin-right: 10px; }
  #cart .cart-billing .ant-menu-item {
    border: 1px solid #ededed; }
  #cart .cart-billing .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #137939;
    color: #25D366; }
  #cart .cart-shipping .ant-menu-item {
    border: 1px solid #ededed; }
  #cart .cart-shipping .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #137939;
    color: #25D366; }
  #cart .cart-payment .ant-list-items .ant-row {
    margin: 10px 0 0 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ededed;
    cursor: pointer; }
    #cart .cart-payment .ant-list-items .ant-row:last-child {
      border: 0; }
    #cart .cart-payment .ant-list-items .ant-row div {
      vertical-align: middle; }
  #cart .cart-payment img {
    height: 100%; }
  #cart .cart-payment button {
    margin: 0 0 10px 0; }
  #cart .cart-payment button[disabled] {
    background-color: #fff;
    color: #ccc;
    border-color: #ccc; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #cart .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      display: none; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #cart .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
      padding-left: 0; } }
  #cart .ant-menu-item:active,
  #cart .ant-menu-submenu-title:active {
    color: initial;
    background: #fff; }
  #cart .header-icon {
    color: #25D366;
    width: 22px;
    height: 22px;
    font-size: 22px; }
  #cart .min-height {
    min-height: 44px; }
  #cart .link-orange a {
    color: #25D366; }
  #cart .credit-card input {
    border-color: #d9d9d9; }

#walletWidgetDiv,
#addressBookWidgetDiv {
  margin-top: 20px !important; }

#OffAmazonPaymentsWidgets2IFrame {
  border: 0 !important; }

.bank-box {
  border: 1px solid orange;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: -1px 5px 10px 0px lightgrey;
  padding: 10px; }
  .bank-box p.bank-logo {
    text-transform: capitalize;
    font-size: large;
    vertical-align: middle; }
  .bank-box img {
    height: 100px;
    max-width: 100px;
    object-fit: contain;
    margin-right: 2em; }
  .bank-box .has-padding > div {
    padding: 0 5px; }
  .bank-box .has-padding > div:first-child {
    padding-left: 0; }
  .bank-box .has-padding > div:last-child {
    padding-right: 0; }

.yapily-box1 {
  background-color: skyblue;
  padding: 30px 60px;
  border: 1px solid transparent;
  border-radius: 10px; }
  .yapily-box1 h1 {
    color: orange;
    font-weight: bold; }
  .yapily-box1 h2 {
    font-weight: bold;
    margin: 0; }

.yapily-box2 {
  border: 1px solid lightgrey;
  padding: 30px 60px;
  border-radius: 10px; }
  .yapily-box2 h1 {
    font-weight: bold;
    border-bottom: 1px solid lightgrey; }
  .yapily-box2 span:first-child {
    color: orange; }
  .yapily-box2 span {
    font-size: large; }
  .yapily-box2 p {
    font-size: small; }

#footer {
  background-color: #6A706E; }
  #footer .wrapper {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 50px;
    margin: 0 auto !important;
    padding-top: 30px; }
    @media screen and (max-width: 1240px) {
      #footer .wrapper {
        max-width: 94%; } }
    #footer .wrapper.last {
      border-top: 1px solid #cecece;
      padding: 20px 0 100px;
      margin-top: 10px !important; }
    #footer .wrapper .the4 span {
      cursor: pointer; }
      #footer .wrapper .the4 span strong {
        cursor: default; }
    #footer .wrapper .ant-typography,
    #footer .wrapper a {
      color: #fff;
      display: block; }
    #footer .wrapper strong {
      display: block;
      margin-bottom: 20px; }
    #footer .wrapper .payments img {
      margin: 0 7px; }

.pending-ci-alert {
  text-align: center; }
  .is-chat-route .pending-ci-alert {
    display: none; }

#header {
  min-height: 70px;
  margin-top: 10px; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #header .logo-elem {
      flex: 100%;
      max-width: 100%;
      text-align: center;
      margin-bottom: 14px; } }
  #header .logo-elem div {
    width: 176px;
    margin-top: 1px;
    cursor: pointer;
    background-image: url("/assets/images/logo/logo.svg");
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      #header .logo-elem div {
        width: 120px;
        height: 32px;
        margin: auto; } }
    @media only screen and (max-width: 575px) {
      #header .logo-elem div {
        margin: 0 auto; } }
  #header .logo {
    height: 40px;
    background-image: url("/assets/images/logo/logo.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  #header .lang-elem {
    padding-left: 10px;
    padding-top: 25px; }
    @media only screen and (max-width: 575px) {
      #header .lang-elem {
        position: absolute;
        top: 0;
        left: 10px;
        padding: 0; } }
    #header .lang-elem span {
      font-size: 12px;
      position: absolute;
      padding: 2px 7px;
      border-radius: 0 0 4px 4px;
      top: -8px;
      background: #4e4e8c; }
      @media only screen and (max-width: 575px) {
        #header .lang-elem span {
          width: 100px;
          top: 0; } }
  #header .ant-row {
    margin-top: 11px;
    margin-bottom: 0; }
    #header .ant-row .ant-badge .ant-scroll-number.ant-badge-dot {
      left: 5px;
      top: 6px; }
  #header .ant-typography {
    cursor: pointer;
    font-size: 16px; }
    #header .ant-typography.active {
      color: #25D366; }
  #header button.account-btn {
    margin-top: -3px; }
  #header .logout {
    cursor: pointer; }
  #header .header-icon {
    color: #25D366;
    width: 22px;
    height: 22px;
    font-size: 22px; }

/**
* languages
*/
.ant-dropdown .choose-language .ant-row {
  min-width: 50px; }

.ant-dropdown .choose-language li:hover {
  background: #f5f5f5; }

/**
* Community
*/
.ant-dropdown .community-drop .ant-row {
  min-width: 150px; }
  .ant-dropdown .community-drop .ant-row .ant-scroll-number {
    top: 12px; }

#home {
  position: relative;
  padding-top: 50px; }
  #home .welcome-credits-banner {
    position: absolute;
    background-color: #E9FBEB;
    border: 1px solid #25D366;
    color: #6A706E;
    padding: 6px;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    transition: .5s;
    font-weight: 600;
    -webkit-box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.25);
    z-index: 1; }
  #home .welcome-credits-banner:hover {
    background-color: #ffffff;
    transition: .5s;
    cursor: pointer; }
  @media screen and (max-width: 640px) {
    #home {
      padding-top: 10px; }
      #home .welcome-credits-banner {
        top: -20px; } }
  #home .ant-card-bordered.screen-2,
  #home .ant-card-bordered.screen-4 {
    background: #f5f5f5;
    padding: 20px;
    border: 0; }
  #home .screen-1 h1.ant-typography {
    font-size: 5.2em;
    line-height: 5.7rem;
    margin-top: 30px; }
    @media screen and (max-width: 640px) {
      #home .screen-1 h1.ant-typography {
        font-size: 3rem;
        line-height: 3rem; } }
  #home .screen-1 h3.ant-typography {
    font-size: 2.3em;
    margin-top: 22px;
    margin-bottom: 20px; }
    @media screen and (max-width: 640px) {
      #home .screen-1 h3.ant-typography {
        font-size: 2rem;
        line-height: 3rem;
        margin-top: 5px; } }
  #home .screen-1 h4.ant-typography {
    margin-bottom: 40px; }
  #home .screen-1 button.ant-btn {
    min-width: 100px;
    height: 40px;
    font-size: 20px; }
  #home .screen-1,
  #home .screen-3 {
    margin-bottom: 80px; }
  #home .screen-2 > .ant-col,
  #home .screen-4 > .ant-col {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto 50px; }
    @media screen and (max-width: 1240px) {
      #home .screen-2 > .ant-col,
      #home .screen-4 > .ant-col {
        max-width: 94%; } }
  #home .orange-banner {
    padding: 30px 0;
    background-image: url("/assets/images/banner-orange-big.png");
    background-repeat: no-repeat;
    background-position: 70% 100%;
    background-size: 36%; }
    @media screen and (max-width: 640px) {
      #home .orange-banner {
        background-size: 60%;
        background-position: 100% 100%; } }
    #home .orange-banner .ant-typography {
      margin-bottom: 0; }
    @media screen and (max-width: 640px) {
      #home .orange-banner .ant-col {
        margin-bottom: 10px; } }
    #home .orange-banner h1 {
      font-size: 3rem; }
      @media screen and (max-width: 640px) {
        #home .orange-banner h1 {
          font-size: 1.5rem; } }
    #home .orange-banner .color-orange {
      margin-top: 0;
      color: #25D366 !important; }

#signin h3,
#register h3,
#forgotpassword h3,
#resetpassword h3 {
  margin-bottom: 20px; }

#signin h5,
#register h5,
#forgotpassword h5,
#resetpassword h5 {
  margin-bottom: 40px; }

#signin .ant-form-item-has-error .ant-checkbox-inner,
#register .ant-form-item-has-error .ant-checkbox-inner,
#forgotpassword .ant-form-item-has-error .ant-checkbox-inner,
#resetpassword .ant-form-item-has-error .ant-checkbox-inner {
  border-color: #ee4834; }

#signin .link,
#register .link,
#forgotpassword .link,
#resetpassword .link {
  font-size: 12px;
  color: #25D366;
  display: block;
  width: 100%; }
  #signin .link:hover,
  #register .link:hover,
  #forgotpassword .link:hover,
  #resetpassword .link:hover {
    color: #25D366;
    text-decoration: underline; }

#signin .btn-submit,
#register .btn-submit,
#forgotpassword .btn-submit,
#resetpassword .btn-submit {
  width: 100%;
  height: 40px;
  display: block;
  margin: 0 auto; }

#signin .social-btn-wrapper,
#register .social-btn-wrapper,
#forgotpassword .social-btn-wrapper,
#resetpassword .social-btn-wrapper {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  margin: 0 auto; }
  #signin .social-btn-wrapper .social-btn,
  #register .social-btn-wrapper .social-btn,
  #forgotpassword .social-btn-wrapper .social-btn,
  #resetpassword .social-btn-wrapper .social-btn {
    width: 100%;
    min-height: 44px;
    border: 1px solid rgba(22, 24, 35, 0.12);
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 12px;
    margin: 5px;
    cursor: pointer; }
    #signin .social-btn-wrapper .social-btn#LoginWithAmazon,
    #register .social-btn-wrapper .social-btn#LoginWithAmazon,
    #forgotpassword .social-btn-wrapper .social-btn#LoginWithAmazon,
    #resetpassword .social-btn-wrapper .social-btn#LoginWithAmazon {
      background: #ffd86c; }
    #signin .social-btn-wrapper .social-btn#LoginWithFacebook,
    #register .social-btn-wrapper .social-btn#LoginWithFacebook,
    #forgotpassword .social-btn-wrapper .social-btn#LoginWithFacebook,
    #resetpassword .social-btn-wrapper .social-btn#LoginWithFacebook {
      background: #3570dc;
      color: #fff; }
    #signin .social-btn-wrapper .social-btn.disabled,
    #register .social-btn-wrapper .social-btn.disabled,
    #forgotpassword .social-btn-wrapper .social-btn.disabled,
    #resetpassword .social-btn-wrapper .social-btn.disabled {
      opacity: 0.5;
      pointer-events: none; }
    #signin .social-btn-wrapper .social-btn > img,
    #register .social-btn-wrapper .social-btn > img,
    #forgotpassword .social-btn-wrapper .social-btn > img,
    #resetpassword .social-btn-wrapper .social-btn > img {
      display: none; }
    #signin .social-btn-wrapper .social-btn .social-icon,
    #register .social-btn-wrapper .social-btn .social-icon,
    #forgotpassword .social-btn-wrapper .social-btn .social-icon,
    #resetpassword .social-btn-wrapper .social-btn .social-icon {
      width: 34px;
      margin-right: 10px;
      min-height: 34px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
  #signin .social-btn-wrapper .apple-sign-in,
  #register .social-btn-wrapper .apple-sign-in,
  #forgotpassword .social-btn-wrapper .apple-sign-in,
  #resetpassword .social-btn-wrapper .apple-sign-in {
    appearance: none;
    -webkit-appearance: none;
    padding: 7px 40px;
    border: none;
    color: #fff;
    background-color: black;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px; }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  #register .registration-stepper {
    margin-left: -22px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  #register .ant-steps-item-container {
    height: 20px; } }

#register .skip {
  display: block;
  margin: 20px auto 0;
  text-align: center;
  color: #b3b3b3;
  font-size: 13px; }

#register .custom-step {
  font-size: 14px;
  width: 20px;
  height: 20px;
  display: block;
  background: #25D366;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%; }

#register .ant-form-item {
  margin-bottom: 10px; }

#register .ant-card-bordered {
  max-width: 700px;
  margin: 0 auto; }

#register .ant-checkbox-wrapper {
  line-height: 1em; }

#register .ant-steps-item-description {
  font-size: 12px; }

@media only screen and (max-width: 575px) {
  #register .shop-card-img {
    text-align: center; } }

#register .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #137939; }

#register .ant-upload {
  margin: 0; }

#register .price-example .ant-typography {
  display: flex;
  justify-content: space-between; }

#register .price-example strong {
  color: #25D366;
  position: relative; }
  #register .price-example strong span:first-child {
    color: #161032;
    font-weight: 100;
    display: inline-block;
    text-decoration: line-through; }
  #register .price-example strong span:nth-child(2) {
    position: absolute;
    left: 0;
    bottom: -14px; }

#register .checkbox-wrapper {
  padding: 20px !important;
  border: 1px solid #e7e7e7;
  border-radius: 20px; }
  #register .checkbox-wrapper .ant-alert {
    margin-bottom: 20px; }

#register .social-btn-wrapper .social-btn {
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0; }

#register .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #25D366; }

.main-container {
  position: relative;
  width: 400px;
  top: 100px; }

.strength-meter {
  position: relative;
  height: 2px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
  overflow: hidden; }
  .strength-meter:before, .strength-meter:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 6px 0;
    position: absolute;
    width: calc(20% + 6px);
    z-index: 2; }
  .strength-meter:before {
    left: calc(20% - 3px); }
  .strength-meter:after {
    right: calc(20% - 3px); }

.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  transition: width 0.5s ease-in-out, background 0.25s; }
  .strength-meter-fill[data-strength='0'] {
    width: 20%;
    background: darkred; }
  .strength-meter-fill[data-strength='1'] {
    width: 40%;
    background: orangered; }
  .strength-meter-fill[data-strength='2'] {
    width: 60%;
    background: orange; }
  .strength-meter-fill[data-strength='3'] {
    width: 80%;
    background: yellowgreen; }
  .strength-meter-fill[data-strength='4'] {
    width: 100%;
    background: green; }

.ant-modal.auth-modal {
  border-radius: 6px;
  width: 900px !important; }
  @media only screen and (max-width: 575px) {
    .ant-modal.auth-modal {
      width: 100% !important; } }
  .ant-modal.auth-modal .ant-modal-body {
    padding: 0; }
    .ant-modal.auth-modal .ant-modal-body .ant-card-bordered {
      padding: 30px 40px;
      border: 0; }
    .ant-modal.auth-modal .ant-modal-body .ant-input {
      border: 0;
      border-bottom: 2px solid #6A706E !important;
      padding-left: 30px; }
    .ant-modal.auth-modal .ant-modal-body #reg_firstName,
    .ant-modal.auth-modal .ant-modal-body #reg_lastName {
      padding-left: 5px; }
    .ant-modal.auth-modal .ant-modal-body .ant-input-password,
    .ant-modal.auth-modal .ant-modal-body .ant-input-affix-wrapper {
      border: 0;
      padding: 0; }
    .ant-modal.auth-modal .ant-modal-body .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      border-color: #ff4d4f !important; }
    .ant-modal.auth-modal .ant-modal-body .ant-input-prefix {
      font-size: 20px;
      position: absolute;
      z-index: 999; }

body.maintenance .site-wrapper {
  background: #fff; }

#maintenance {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto 50px;
  min-height: 100vh;
  padding-top: 20px;
  margin: 0 auto !important; }
  @media screen and (max-width: 1240px) {
    #maintenance {
      max-width: 94%; } }
  #maintenance h1 {
    font-size: 4rem;
    color: #43446d; }
  #maintenance h3 {
    font-size: 1.5rem;
    color: #43446d; }

#sxc-messenger-small {
  position: fixed;
  z-index: 999999;
  left: 10px;
  bottom: 0; }
  #sxc-messenger-small * {
    scrollbar-width: thin;
    scrollbar-color: #6A706E white; }
    #sxc-messenger-small *::-webkit-scrollbar {
      width: 11px; }
    #sxc-messenger-small *::-webkit-scrollbar-track {
      background: transparent; }
    #sxc-messenger-small *::-webkit-scrollbar-thumb {
      background-color: #6A706E;
      border-radius: 6px; }
  .is-chat-route #sxc-messenger-small {
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    top: 73px;
    bottom: initial;
    position: absolute;
    z-index: 1;
    width: 100%;
    max-width: 1280px; }
    .is-chat-route #sxc-messenger-small .ant-col.full {
      padding: 0;
      height: 85.7vh; }
      @media (max-width: 1280px) {
        .is-chat-route #sxc-messenger-small .ant-col.full {
          width: 72%;
          max-width: 72%; }
          .is-chat-route #sxc-messenger-small .ant-col.full.col-sx {
            overflow: hidden; } }
  @media (max-width: 1280px) and (max-width: 1280px) {
    .is-chat-route #sxc-messenger-small .ant-col.full {
      width: 28%;
      max-width: 28%;
      flex: 0 0 28%; } }
  #sxc-messenger-small .small {
    position: relative;
    width: 320px;
    bottom: 0;
    height: 0;
    background: #fff;
    border: 1px solid #ccc; }
    .is-chat-route #sxc-messenger-small .small {
      width: 100%;
      border: 0; }
    #sxc-messenger-small .small.opened {
      height: 400px; }

.sxc-messenger-header {
  background: #fff;
  height: 68px;
  border-bottom: 1px solid #e8e8e8;
  padding: 9px 20px; }
  @media only screen and (max-width: 575px) {
    .sxc-messenger-header {
      height: 120px;
      display: flex;
      align-items: center; }
      .is-chat-route .sxc-messenger-header {
        height: 68px; }
      .sxc-messenger-header svg {
        font-size: 20px;
        margin-right: 20px; }
      .sxc-messenger-header .ant-list {
        width: 100%; } }
  .sxc-messenger-header .small {
    height: auto;
    padding: 3px; }
  .sxc-messenger-header li {
    padding: 0; }

.is-chat-route iframe {
  display: none; }

.is-chat-route #sxc-messenger-small {
  min-height: 50vh; }
  @media only screen and (max-width: 575px) {
    .is-chat-route #sxc-messenger-small {
      top: 133px; } }

@media only screen and (max-width: 575px) {
  .is-chat-route .sxc-messenger-header > .ant-list {
    flex: 1; } }

#messenger-height {
  min-height: 80vh; }

#messenger-courtesy {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto 50px;
  height: calc(100vh - 50px);
  text-align: center; }
  @media screen and (max-width: 1240px) {
    #messenger-courtesy {
      max-width: 94%; } }
  #messenger-courtesy .chat-logo {
    width: 100%;
    height: 200px;
    background: url("/assets/images/sxc-messenger.svg") 50% 50% no-repeat;
    background-size: contain;
    margin-top: 50px; }

/** **/
.qc-cmp-persistent-link {
  display: none !important; }

.full {
  height: 590px;
  overflow: hidden; }

.col-sx {
  height: calc(100vh - 172px);
  overflow-y: scroll;
  background: #fff; }
  .col-sx .header-fixed {
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 11;
    background: white;
    border-bottom: 1px solid #dedede; }

.group-name-input .ant-form-item-control.has-error {
  border: none !important; }
  .group-name-input .ant-form-item-control.has-error .ant-form-explain {
    padding: 5px; }

.small h1.title {
  font-size: 15px; }

.search-friend {
  width: 100%; }

.search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6; }

.ant-form.create-group-form {
  width: 90%;
  margin: 0 auto; }
  .ant-form.create-group-form input {
    padding: 6px 11px !important;
    height: 40px !important; }
  .ant-form.create-group-form input,
  .ant-form.create-group-form .ant-select-selection__placeholder {
    font-size: 14px !important; }

.row-single-friend {
  padding: 10px;
  align-items: center; }

.user-added-box-wrapper .user-added-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 10px; }
  .user-added-box-wrapper .user-added-box button {
    top: 0;
    right: 50%;
    position: absolute;
    z-index: 1;
    transform: translateX(100%); }
  .user-added-box-wrapper .user-added-box .name-label {
    font-size: 12px; }

.ant-comment-content-author-name {
  white-space: nowrap; }
  .ant-comment-content-author-name .sender-name {
    color: #fff;
    margin-left: 20px; }

.message-list.ant-list-split.blocked {
  opacity: 0.5; }

.message-list.ant-list-split .ant-list-item {
  border-bottom: 0; }
  .message-list.ant-list-split .ant-list-item .ant-comment-content-detail {
    color: #fff; }

.message-list.ant-list-split .input-edit {
  border-radius: 30px;
  height: 40px;
  border: 1px solid #ccc; }

.message-list.ant-list-split .edit-cancel {
  color: #ccc; }

.message-list.ant-list-split .edit-submit {
  color: #25D366; }

.share-voucher {
  padding: 30px;
  text-align: center; }
  .share-voucher + div {
    display: none; }
  .chat-mobile .share-voucher {
    padding: 10px; }
    .chat-mobile .share-voucher.channel-active {
      display: none; }
    .chat-mobile .share-voucher img {
      max-width: 100px; }
    .chat-mobile .share-voucher button {
      max-width: 200px;
      width: 100%;
      display: block;
      margin: 0 auto; }
    .chat-mobile .share-voucher + div {
      display: inherit; }

.voucher-message {
  background: white;
  margin: 10px;
  padding: 30px;
  border-radius: 20px;
  max-width: 400px; }
  .voucher-message img {
    max-width: 340px;
    width: 100%;
    height: 220px;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat; }
    @media (max-width: 768px) {
      .voucher-message img {
        height: 170px; } }
  .voucher-message .bttn-4 {
    display: block;
    margin: 0 auto;
    max-width: 200px;
    text-transform: none; }

.chat-zip-btn {
  width: 100%;
  min-height: 30px;
  margin: 5px; }

.submit-multimedia {
  right: 20px; }
  .is-chat-route .submit-multimedia {
    right: 0; }

.msg-focused:not(.system) {
  position: relative; }
  .msg-focused:not(.system):before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    right: -100%;
    bottom: 0;
    animation: fade 2s forwards;
    background-color: #4be083; }

@keyframes fade {
  from {
    background-color: #4be083; }
  to {
    background-color: transparent; } }

@media (max-width: 980px) {
  .messages {
    overflow: hidden;
    position: relative; } }

.messages .no-messages {
  height: calc(100vh - 73px);
  background: white; }

.unread-messages-cnt {
  background-color: #6562d8;
  width: 1.3rem;
  height: 1.3rem;
  position: absolute;
  border-radius: 100rem;
  right: 0.5rem;
  bottom: -0.15rem;
  color: #fff;
  font-size: smaller;
  text-align: center;
  top: 26px; }

.btn-load-more {
  margin: 10px auto;
  display: block !important;
  max-width: fit-content;
  max-width: -moz-fit-content; }

.write input[type='text'] {
  padding-left: 50px !important;
  border-radius: 30px;
  color: #161032;
  height: 30px; }

.write i {
  font-size: 16px;
  margin-right: 5px; }
  .write i.anticon-message {
    margin-right: 0 !important; }

.write.full {
  height: 40px; }
  .write.full input[type='text'] {
    padding-left: 70px !important; }
  .write.full i {
    font-size: 20px;
    margin-right: 5px; }

.small .write {
  height: auto;
  padding: 3px; }

.write form {
  width: 100%; }
  .write form > .ant-form-item {
    margin-bottom: 0; }

.write .ant-input-suffix {
  right: 2px; }

.write .ant-tag-volcano {
  background: #f8faff;
  opacity: 1;
  border-radius: 0;
  border: none;
  position: absolute;
  top: -100px;
  left: 0;
  height: 170px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 30; }
  .write .ant-tag-volcano .anticon-close {
    position: absolute;
    z-index: 3;
    top: 4px;
    right: 0px; }
  .write .ant-tag-volcano.open-graph-msg {
    top: -140px;
    height: 150px; }
  .write .ant-tag-volcano.reply-to-msg {
    top: -170px; }

.write .file-name {
  display: inline-block;
  margin-left: 10px; }

.write .anticon-message {
  font-size: 20px; }

.write .upload-row {
  width: 100%; }
  .write .upload-row .ant-btn-primary {
    width: 38px;
    height: 38px; }

.write .open-graph-row {
  width: 100%; }
  .write .open-graph-row .opg-text {
    vertical-align: middle;
    display: inline-block;
    color: #07004D;
    width: 80%;
    margin-left: 10px; }
    .write .open-graph-row .opg-text p:first-child {
      font-weight: bold; }
    .write .open-graph-row .opg-text p:last-child {
      white-space: normal;
      overflow: hidden;
      height: 32px;
      text-overflow: ellipsis; }

.write .opg-image {
  max-width: 180px;
  max-height: 120px; }

.write button.ant-btn-primary {
  position: absolute;
  width: 30px; }

.send-loading {
  text-align: center;
  margin-top: 20px; }

.message-list .ant-list-item {
  padding: 12px 0 24px;
  border-bottom: none; }
  .message-list .ant-list-item.system {
    padding: 12px 0 12px; }
  .message-list .ant-list-item.first-separator {
    padding-bottom: 5px; }

.mine > div,
.other > div {
  min-width: 120px;
  max-width: 96.4%; }
  .small .mine > div, .small
  .other > div {
    max-width: 93%; }

.reply-to-msg {
  position: absolute;
  top: -172px;
  left: 0;
  height: 170px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3 !important;
  font-size: 15px; }
  .reply-to-msg p {
    color: #07004D; }
  .reply-to-msg img {
    max-width: 200px !important;
    max-height: 120px; }

.no-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .no-messages h4 {
    text-align: center;
    color: #25D366; }
  @media (max-width: 768px) {
    .no-messages {
      display: none; } }

.emoji-mart-title-label {
  font-size: 18px; }

.thumb-modal-wrapper .ant-modal-body img {
  width: 100%;
  height: auto; }

.thumb.lazy-loading {
  background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite; }

.video-wrapper {
  width: 300px !important;
  height: 300px !important; }
  .mine .video-wrapper {
    margin-right: 20px; }

.partecipants-list .ant-list-item-meta {
  align-items: center;
  margin-bottom: 0; }

.ant-drawer,
.ant-popover-placement-top {
  z-index: 999999; }

.avatar-overlay {
  display: flex;
  justify-content: center;
  margin-bottom: 10px; }

.linkwrapper {
  color: #07004D; }
  .linkwrapper:hover {
    color: #07004D;
    text-decoration: underline; }

a.textLink {
  color: #575a77;
  text-decoration: underline; }

.badge-go-down {
  right: 60px;
  z-index: 10;
  position: absolute;
  bottom: 80px; }
  .is-chat-route .badge-go-down {
    position: fixed;
    bottom: 120px; }
  .badge-go-down sup {
    background-color: orange; }

.idleBox {
  background: #fff;
  width: 100%;
  margin: 30px auto;
  text-align: center;
  display: none; }
  .is-chat-route .idleBox {
    display: block; }
  .idleBox h3 {
    color: #25D366; }
  .idleBox .imgWrapper {
    box-shadow: 1px 1px 5px #00000021;
    overflow: hidden;
    border-radius: 20px;
    width: 60%;
    margin: 0 auto;
    border: 10px solid white; }
    .idleBox .imgWrapper img {
      max-width: 300px;
      height: auto; }
  .idleBox button {
    margin-top: 20px; }

.channel-list {
  border-top: 1px solid #e8e8e8;
  height: calc(100vh - 78px); }
  @media screen and (max-width: 768px) {
    .channel-list {
      height: calc(100vh - 274px);
      overflow-y: scroll; } }
  .channel-list li {
    padding-right: 10px;
    padding-left: 0;
    border-left: 10px solid transparent;
    position: relative; }
    .channel-list li.full {
      padding-right: 20px;
      padding-left: 10;
      padding-top: 8px;
      padding-bottom: 8px; }
    .channel-list li:hover {
      background: #e8e8e8; }
      .channel-list li:hover h4 div {
        color: #dd5a0b; }
    .channel-list li .ant-badge {
      position: absolute;
      right: 23px;
      top: 33px; }
      .channel-list li .ant-badge sup {
        background-color: #dd5a0b; }
        .channel-list li .ant-badge sup p {
          color: white;
          line-height: inherit; }
    .channel-list li .ant-list-item-meta-description {
      color: #686868; }

.ant-drawer-wrapper-body button.add-member {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 50px;
  height: 50px;
  z-index: 2;
  margin: 10px 0; }

.messages .ant-row.write {
  position: relative;
  transition: height 0.2s;
  background: #f8faff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e8e8e8; }

.chat-mobile .channel-list {
  height: calc(100vh - 158px); }

.chat-loader {
  display: none; }

.is-chat-route .chat-loader {
  display: block; }

.is-chat-route .inner-container {
  height: 590px;
  width: 100%; }

.little-header-wrapper {
  background: #07004D;
  height: 50px;
  position: absolute;
  top: -50px;
  right: -1px;
  left: -1px;
  border: 0px solid #25D366;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px; }
  .little-header-wrapper .chat-opener,
  .little-header-wrapper .chat-fullsize {
    color: #ffffff;
    padding: 8px;
    cursor: pointer;
    width: 40px; }
    .little-header-wrapper .chat-opener:hover,
    .little-header-wrapper .chat-fullsize:hover {
      color: #25D366; }

.little-right-actions span {
  position: absolute;
  width: 40px;
  height: 40px;
  right: -50px;
  top: 10px;
  padding: 5px;
  background: white;
  border-radius: 10px;
  border: 2px solid #07004D;
  color: #07004D;
  border-radius: 100%;
  font-size: 24px;
  line-height: 32px; }
  .little-right-actions span:hover {
    color: #25D366;
    border-color: #07004D; }
  .little-right-actions span + span {
    top: 60px; }
    .little-right-actions span + span + span {
      top: 110px; }

/** CHANNEL CREATION */
#sxc-messenger-small .form-wrapper {
  position: absolute;
  width: 100%;
  background: #fff;
  top: 0;
  height: 100%;
  z-index: 99;
  padding: 10px; }
  @media (max-width: 980px) {
    #sxc-messenger-small .form-wrapper {
      height: calc(100vh - 60px); } }

#sxc-messenger-small .absolute-close {
  position: absolute;
  right: 0;
  font-size: 28px;
  z-index: 1; }

/** CHANNEL LIST */
#sxc-messenger-small .title-wrapper:hover {
  color: purple; }

@media screen and (min-width: 980px) {
  #sxc-messenger-small .title-wrapper {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis; } }

#sxc-messenger-small .sender {
  font-size: 13px; }
  #sxc-messenger-small .sender.unread {
    font-weight: 600;
    color: #000; }

#sxc-messenger-small .badge-custom {
  margin-left: 10px; }
  #sxc-messenger-small .badge-custom sup {
    background-color: orange; }

#sxc-messenger-small .channel-last-msg-time {
  color: gray;
  position: absolute;
  font-size: 11px;
  top: -60px;
  right: 11px; }
  #sxc-messenger-small .channel-last-msg-time.full {
    top: 9px;
    right: 20px; }

#sxc-messenger-small .channel-item-comp {
  cursor: pointer;
  background: #fff; }
  #sxc-messenger-small .channel-item-comp.isactive {
    background: #f9f9f9; }
  #sxc-messenger-small .channel-item-comp.isblockedbyme {
    opacity: 0.5; }

/** CHAT HEADER **/
#sxc-messenger-small .button-clear {
  margin: 20px 0 10px; }

#sxc-messenger-small .admin-label {
  background: #e88010;
  color: #fff;
  padding: 0 4px;
  border-radius: 3px; }
  #sxc-messenger-small .admin-label.super {
    background: #6562d8; }

#sxc-messenger-small .member-name {
  margin: 0 0 0 -10px;
  position: relative; }
  #sxc-messenger-small .member-name a {
    color: rgba(0, 0, 0, 0.45); }
    #sxc-messenger-small .member-name a:hover {
      color: #dd5a0b;
      text-decoration: none; }

#sxc-messenger-small .online-label {
  color: #9fba50;
  font-size: 11px;
  margin-left: -10px;
  margin-bottom: 0;
  font-weight: 600; }

#sxc-messenger-small .last-seen-label {
  font-size: 11px;
  margin-left: -10px; }

#sxc-messenger-small .small-settings-wrapper {
  position: absolute;
  z-index: 999;
  top: 0;
  background: #fff;
  padding: 10px;
  width: 100%;
  height: 400px;
  overflow-y: scroll; }

#sxc-messenger-small .other-member:hover {
  color: #dd5a0b !important;
  text-decoration: none; }

.admin-custom-dropdown {
  cursor: pointer;
  position: absolute;
  right: -7px;
  top: -1px;
  padding: 5px;
  border-radius: 100%; }

/** MESSAGES */
#sxc-messenger-small .badge-icon {
  position: absolute;
  font-size: 24px; }

#sxc-messenger-small .edit-wrapper {
  left: -19px;
  bottom: -1px; }
  #sxc-messenger-small .edit-wrapper > span {
    color: #666;
    font-size: 17px; }

#sxc-messenger-small .day-separator-container {
  width: 100%;
  position: relative;
  flex-direction: column; }
  #sxc-messenger-small .day-separator-container:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    bottom: 12px;
    background: #e6e6e6;
    position: relative;
    z-index: 1; }

#sxc-messenger-small .day-separator-label {
  width: 110px;
  height: 28px;
  min-width: 100px;
  background: #eee;
  border-radius: 3px;
  padding: 2px 5px;
  text-align: center;
  border-radius: 30px;
  margin: 0 auto;
  z-index: 2; }
  #sxc-messenger-small .day-separator-label.full {
    width: 200px; }
  #sxc-messenger-small .day-separator-label :not(.full) {
    font-size: 12px; }

#sxc-messenger-small .message-is-writing {
  bottom: 80px;
  min-width: 180px;
  z-index: 99;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  padding: 5px 0;
  background: #9fb3d0;
  border-radius: 26px;
  font-size: 13px; }
  #sxc-messenger-small .message-is-writing.full {
    min-width: 200px; }
  #sxc-messenger-small .message-is-writing.focus {
    bottom: 180px; }

#sxc-messenger-small .edit-label {
  font-size: 10px;
  color: #bb7232;
  font-style: italic;
  margin: 0 0 0 5px;
  display: inline-block;
  width: 48px;
  text-align: right;
  position: absolute;
  bottom: 1px; }
  #sxc-messenger-small .edit-label.isByMe {
    left: 10px; }
  #sxc-messenger-small .edit-label :not(.isByMe) {
    right: 10px; }

#sxc-messenger-small .message-wrapper {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  padding: 5px 15px; }

#sxc-messenger-small .message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }

#sxc-messenger-small .sender-name:hover {
  color: #dd5a0b;
  text-decoration: none; }

#sxc-messenger-small .chat-message-wrapper {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  white-space: pre-wrap; }
  #sxc-messenger-small .chat-message-wrapper :not(.full) {
    line-height: 22px; }
    #sxc-messenger-small .chat-message-wrapper :not(.full) p {
      font-size: 12.4px; }
  #sxc-messenger-small .chat-message-wrapper p {
    font-size: 12.2px;
    margin-bottom: 6px; }

#sxc-messenger-small .message-edit-label {
  font-size: 10px;
  color: #847e78;
  font-style: italic;
  margin: 0 0 0 5px;
  display: inline-block;
  width: 48px;
  text-align: right;
  position: absolute;
  bottom: 3px;
  left: 10px; }
  @media (max-width: 640px) {
    #sxc-messenger-small .message-edit-label {
      font-size: 11px !important;
      width: 70px !important;
      left: 0 !important;
      bottom: -14px !important; } }
  #sxc-messenger-small .message-edit-label.isByMe {
    bottom: 1px;
    color: #bb7232; }

#sxc-messenger-small .msg-wrapper {
  margin-bottom: 0;
  display: block;
  padding: 8px 19px;
  min-height: 52px; }

#sxc-messenger-small .quote-padder {
  padding: 5px 8px 0; }

#sxc-messenger-small .quote {
  cursor: pointer;
  background: #434278;
  border-radius: 2px;
  display: inline-block;
  padding: 8px 10px;
  color: #fff;
  font-size: 12px;
  width: 100%; }

#sxc-messenger-small .ant-list-item.mine .quote {
  background: #000;
  margin-bottom: 10px; }

#sxc-messenger-small .ant-list-item.mine .msg-wrapper {
  padding-top: 22px; }

#sxc-messenger-small .quote-sender {
  font-size: 13px;
  text-align: left;
  font-weight: 600;
  display: block;
  width: 100%; }

#sxc-messenger-small .message-text {
  display: flex;
  flex-direction: column;
  width: 100%; }

#sxc-messenger-small .audio-padding {
  width: 200px;
  padding: 7px 5px 5px; }

#sxc-messenger-small .file-padder {
  width: 100%;
  padding: 5px; }

#sxc-messenger-small .image-preview-wrapper {
  width: 170px !important;
  height: 100px !important;
  border: 1px solid #a79f90;
  border-radius: 6px;
  text-align: center;
  overflow: hidden;
  position: relative;
  background: #ffffff; }
  #sxc-messenger-small .image-preview-wrapper.full {
    width: 220px !important;
    height: 140px I !important; }

#sxc-messenger-small .zoom {
  position: absolute;
  width: 20px;
  top: 10px;
  right: 10px;
  color: #ccc; }

#sxc-messenger-small .image-preview {
  position: relative;
  max-width: 200% !important; }

#sxc-messenger-small .linkwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }

#sxc-messenger-small .link-title {
  font-size: 14px;
  font-weight: bold;
  background: #160f32;
  padding: 4px 15px;
  color: #fff;
  width: 100%; }

#sxc-messenger-small .img-container {
  width: 100%;
  height: 170px;
  position: relative;
  background: #160f32;
  border-radius: 3px 3px 0 0; }
  #sxc-messenger-small .img-container.full {
    height: 220px; }

#sxc-messenger-small .link-image {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

#sxc-messenger-small .player-preview {
  width: 100% !important;
  min-width: 250px;
  height: 160px !important;
  background: #000;
  position: relative;
  padding-top: 56.25%; }
  #sxc-messenger-small .player-preview.full {
    min-width: 300px;
    height: 240px I !important; }

#sxc-messenger-small .react-player {
  position: absolute;
  top: 0;
  left: 0; }

#sxc-messenger-small .message-container {
  background: #fff;
  background-attachment: fixed;
  height: 333px;
  padding: 0 14px;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: height 0.2s; }
  #sxc-messenger-small .message-container.focus {
    height: calc(333px - 70px); }
  #sxc-messenger-small .message-container.full {
    padding: 0 20px; }
    #sxc-messenger-small .message-container.full.chatMobile {
      height: calc(100vh - 276px); }
      #sxc-messenger-small .message-container.full.chatMobile.focus {
        height: calc(100vh - 340px); }
    #sxc-messenger-small .message-container.full:not(.chatMobile) {
      height: calc(100vh - 98px - 50px - 200px); }
    #sxc-messenger-small .message-container.full:after {
      top: 68px;
      height: 100%; }
  #sxc-messenger-small .message-container:not(.isblockedbyme):after, #sxc-messenger-small .message-container:not(.isblockingme):after {
    display: none; }
  #sxc-messenger-small .message-container:after {
    content: '';
    width: 100%;
    top: 0;
    height: 354px;
    position: absolute;
    background: #eee;
    opacity: 0.6;
    left: 0;
    z-index: 999; }

#sxc-messenger-small .ant-list-item.mine {
  justify-content: flex-end; }

#sxc-messenger-small .ant-comment.message-received {
  clear: both;
  line-height: 18px;
  padding: 0;
  position: relative;
  margin: 0;
  min-width: 36%;
  max-width: 50%;
  width: auto;
  height: auto;
  word-wrap: break-word;
  background: #6564db;
  border-radius: 16px 16px 16px 0;
  float: left; }
  #sxc-messenger-small .ant-comment.message-received .metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px; }
  #sxc-messenger-small .ant-comment.message-received:after {
    border-style: solid;
    border-width: 0px 10px 10px 0;
    border-color: transparent #6564db transparent transparent;
    top: 0;
    left: -10px;
    position: absolute;
    width: 0;
    height: 0; }
  #sxc-messenger-small .ant-comment.message-received.other-removed {
    border-radius: 0px 5px 5px 5px;
    float: left; }
    #sxc-messenger-small .ant-comment.message-received.other-removed:after {
      border-width: 0px 10px 10px 0;
      border-color: transparent #e2e2e2 transparent transparent;
      top: 0;
      left: -10px; }

#sxc-messenger-small .message-sent {
  color: #fff;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 0;
  position: relative;
  margin: 0;
  max-width: 50%;
  width: auto;
  height: auto;
  word-wrap: break-word;
  z-index: 3;
  background: #43446d;
  border-radius: 3px;
  float: right;
  display: flex;
  justify-content: flex-end; }
  #sxc-messenger-small .message-sent.deleted {
    color: #868686;
    font-size: 11px;
    background: #e2e2e2; }
  #sxc-messenger-small .message-sent.full {
    border-radius: 6px 6px 0 6px; }
  #sxc-messenger-small .message-sent.upoverlay {
    z-index: 12; }
  #sxc-messenger-small .message-sent .metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px; }
  #sxc-messenger-small .message-sent.other-removed {
    border-radius: 0px 5px 5px 5px;
    float: left; }
    #sxc-messenger-small .message-sent.other-removed:after {
      border-width: 0px 10px 10px 0;
      border-color: transparent #e2e2e2 transparent transparent;
      top: 0;
      left: -10px; }

#sxc-messenger-small .message-time {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: -2px;
  right: 9px; }
  #sxc-messenger-small .message-time.isByMe {
    top: 5px; }

#sxc-messenger-small .message-sent-hearted {
  position: absolute;
  top: 17px;
  left: -45px;
  text-align: right;
  width: 40px;
  height: auto; }
  #sxc-messenger-small .message-sent-hearted > span {
    color: #999; }

.message-action {
  color: #d48430;
  position: absolute; }

/** WRITE */
#sxc-messenger-small .write-image {
  max-width: 150px !important;
  max-height: 100px;
  height: auto;
  text-align: center; }

#sxc-messenger-small .prefix-actions {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10; }

#sxc-messenger-small .submit-button {
  width: 38px;
  height: 38px;
  border-color: transparent;
  position: absolute;
  top: 3px;
  right: 10px; }
  #sxc-messenger-small .submit-button i {
    margin: 0; }

#sxc-messenger-small .open-graph-msg {
  position: absolute;
  top: -192px;
  left: 0;
  height: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3; }

#sxc-messenger-small .open-graph-img {
  max-width: 150px !important;
  max-height: 100px;
  height: auto;
  text-align: center; }

#sxc-messenger-small .quote-msg {
  overflow: hidden;
  white-space: normal;
  max-width: 90%; }

#sxc-messenger-small .quote-msg-sender {
  margin: 10px 0; }

#sxc-messenger-small .share-voucher-wrapper {
  position: absolute;
  top: -192px;
  left: 0;
  height: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 3; }

#sxc-messenger-small .share-voucher-img {
  max-width: 150px !important;
  max-height: 100px;
  height: auto;
  text-align: center; }

#sxc-messenger-small .write {
  height: 70px; }

#sxc-messenger-small .error-attach {
  width: 100%;
  padding: 15px;
  position: absolute;
  top: -82px;
  z-index: 10; }

/** SEARCH FRIEND */
#sxc-messenger-small .search-title {
  margin-top: 10px;
  color: #161032;
  font-weight: bold; }

#sxc-messenger-small .search-wrap {
  display: inline-block;
  width: 100%; }

/* USER */
#sxc-messenger-small .user {
  padding: 15px;
  width: 100%;
  text-align: center; }
  #sxc-messenger-small .user div,
  #sxc-messenger-small .user h4 {
    display: inline-block;
    vertical-align: middle;
    margin: 0; }
  @media screen and (min-width: 980px) {
    #sxc-messenger-small .user h4 {
      max-width: 170px;
      overflow: hidden; } }
  #sxc-messenger-small .user .avatar-wrapper {
    margin: 0 auto; }

/* COMMOM  */
#sxc-messenger-small .loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

#sxc-messenger-small .little-header-actions {
  display: flex;
  justify-content: space-between;
  margin-right: 8px; }

#sxc-messenger-small .little-header-title {
  width: 240px;
  color: #fff;
  font-size: 15px;
  margin: 0 0 0 10px; }

#sxc-messenger-small .avatar-wrapper {
  background-size: cover;
  background-position: 50%;
  border-radius: 100%; }
  #sxc-messenger-small .avatar-wrapper.left {
    float: left;
    margin-right: 10px; }
  #sxc-messenger-small .avatar-wrapper.inline {
    display: inline-block; }
  #sxc-messenger-small .avatar-wrapper.absolute {
    position: absolute;
    z-index: 99;
    left: -17px;
    top: -15px;
    border: 2px solid #d0d4dc; }
    #sxc-messenger-small .avatar-wrapper.absolute.full {
      left: -20px; }
  #sxc-messenger-small .avatar-wrapper.disabled {
    opacity: 0.5; }
  #sxc-messenger-small .avatar-wrapper img {
    width: 100%;
    height: auto;
    opacity: 0; }

#leftmenu .ant-menu {
  border-radius: 5px;
  overflow: hidden;
  padding: 0; }
  #leftmenu .ant-menu .ant-menu-item {
    margin: 0; }
    #leftmenu .ant-menu .ant-menu-item.active, #leftmenu .ant-menu .ant-menu-item:hover {
      background: #137939;
      color: #fff; }
    #leftmenu .ant-menu .ant-menu-item.active {
      pointer-events: none; }

#leftmenu .orange-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("/assets/images/badges/Orange-premium-icon.svg") no-repeat center center;
  background-size: 100%;
  margin-top: -2px;
  vertical-align: middle; }

.ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline {
  width: 100%;
  margin-bottom: 10px; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper {
    width: 50%;
    text-align: center; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper:hover,
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper-checked {
    border-color: #6A706E;
    color: #6A706E; }
    .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper:hover:before, .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper:hover:after,
    .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper-checked:before,
    .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper-checked:after {
      background-color: #6A706E; }

.ant-row.col-3-layout .ant-col.col-3-layout-left .switch-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 18px 10px;
  border: none;
  background: #ececec !important;
  color: inherit; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .switch-menu:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent; }

.ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile) {
  font-size: 12px;
  padding: 10px 0 10px 20px;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6A706E;
  margin-bottom: 0;
  background: #f5f5f5;
  transition: all 8ms;
  border-radius: 5px;
  justify-content: flex-start; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile):before {
    font-size: 18px;
    font-family: 'icomoon';
    display: inline-block;
    margin-right: 5px; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile):hover {
    background: #ececec; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).active {
    background: #ffe6da59;
    font-weight: 600;
    border: 2px solid #25D366;
    padding-left: 15px; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).profile:before {
    content: '\e910'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).timeline:before {
    content: '\e903'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).messages:before {
    content: '\e905'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).notifications:before {
    content: '\e914'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).friends:before {
    content: '\e904'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).mybadges:before {
    content: '\e900'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).ranking:before {
    content: '\e90c'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).groups:before {
    content: '\e91f'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).myaccount:before {
    content: '\e906'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).mywallet:before {
    content: '\e907'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).mycredits:before {
    content: '\e908'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).mypoints:before {
    content: '\e901'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).creditcoming:before {
    content: '\e909'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).mycards:before {
    content: '\e90a'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).myinvoices:before {
    content: '\e93e'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).logout:before {
    content: '\e915'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).familybox:before {
    content: '\e932'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).pagameno:before {
    content: '\e91e'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).ricaricacell:before {
    content: '\e925'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).utilities:before {
    content: '\e92f'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).sostariffe:before {
    content: '\e93a'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).soisy:before {
    content: '\e92d'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).orange:before {
    content: '\e92c'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).refundarea:before {
    content: '\e93d'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).momosy_rules:before, .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).howitworks:before {
    content: '\e989'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).guadagnalink::before {
    content: '\e924'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).sxccard:before, .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).diana:before {
    content: '\e98b'; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item:not(.item-profile).sxcticket:before {
    content: '\e93b'; }

.ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item.item-profile {
  padding-bottom: 8px; }
  .ant-row.col-3-layout .ant-col.col-3-layout-left .ant-list-split .ant-list-item.item-profile .ant-avatar {
    margin-right: 5px; }

#menuleft-mobile {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto 50px;
  margin-top: 20px; }
  @media screen and (max-width: 1240px) {
    #menuleft-mobile {
      max-width: 94%; } }
  #menuleft-mobile .ant-select {
    width: 100%; }

#new-privacy .scrollframe {
  margin: 0 auto 30px;
  border: 1px solid #ccc;
  background: #f1f1f1;
  padding: 25px;
  height: 50vh;
  overflow: scroll; }

#notifications {
  padding-top: 20px; }
  #notifications .box {
    background: #fff;
    border: 1px solid #efefef;
    min-height: 100px;
    margin-bottom: 20px;
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 20px; }
    #notifications .box li.ant-list-item {
      flex-wrap: wrap; }
      #notifications .box li.ant-list-item:before {
        content: '';
        display: inline-block;
        background: #25D366;
        border-radius: 100%;
        width: 7px;
        height: 7px;
        margin-left: -5px;
        margin-right: 10px; }
      #notifications .box li.ant-list-item.read-noti {
        color: #969696; }
        #notifications .box li.ant-list-item.read-noti:before {
          background-color: #dadada; }
        #notifications .box li.ant-list-item.read-noti img {
          opacity: 0.7; }
        #notifications .box li.ant-list-item.read-noti > i {
          color: #999; }
      #notifications .box li.ant-list-item > span {
        cursor: pointer; }
      #notifications .box li.ant-list-item > span:not(.anticon) {
        flex: 1; }
      #notifications .box li.ant-list-item > i {
        font-style: normal;
        font-size: 12px;
        color: #666;
        margin-right: 10px; }
    #notifications .box .notifications-actions {
      padding: 10px;
      border: 1px solid #d8d9dc;
      border-radius: 6px;
      flex: 1;
      background: #f1f1f1; }
      #notifications .box .notifications-actions button {
        margin: 0 3px; }
    #notifications .box .ant-pagination {
      margin-top: 20px; }

#pagameno,
#ricaricacell {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto 50px;
  min-height: 40vh;
  padding-top: 20px; }
  @media screen and (max-width: 1240px) {
    #pagameno,
    #ricaricacell {
      max-width: 94%; } }
  #pagameno .anticon-loading,
  #ricaricacell .anticon-loading {
    margin: 30px auto;
    font-size: 40px;
    display: block; }
  #pagameno .ant-result-icon,
  #ricaricacell .ant-result-icon {
    display: none; }
  #pagameno .ant-result-title,
  #ricaricacell .ant-result-title {
    color: #25D366; }
  #pagameno .ant-steps,
  #ricaricacell .ant-steps {
    margin-bottom: 20px;
    background: #fff;
    padding: 12px 30px 15px; }
    #pagameno .ant-steps .ant-steps-item-icon,
    #ricaricacell .ant-steps .ant-steps-item-icon {
      cursor: pointer; }

.pagameno-list .ant-row {
  margin-bottom: 20px; }

.pagameno-list .ant-card {
  text-align: center; }

.pagameno-premium .ant-descriptions-item-container,
.pagameno-creditusage .ant-descriptions-item-container,
.ricaricacell-premium .ant-descriptions-item-container,
.ricaricacell-creditusage .ant-descriptions-item-container {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
  align-items: center; }
  .pagameno-premium .ant-descriptions-item-container .ant-descriptions-item-label span.anticon,
  .pagameno-creditusage .ant-descriptions-item-container .ant-descriptions-item-label span.anticon,
  .ricaricacell-premium .ant-descriptions-item-container .ant-descriptions-item-label span.anticon,
  .ricaricacell-creditusage .ant-descriptions-item-container .ant-descriptions-item-label span.anticon {
    font-size: 19px;
    cursor: pointer;
    color: #6A706E; }
  .pagameno-premium .ant-descriptions-item-container .ant-descriptions-item-label img,
  .pagameno-creditusage .ant-descriptions-item-container .ant-descriptions-item-label img,
  .ricaricacell-premium .ant-descriptions-item-container .ant-descriptions-item-label img,
  .ricaricacell-creditusage .ant-descriptions-item-container .ant-descriptions-item-label img {
    width: 80px; }
  .pagameno-premium .ant-descriptions-item-container .ant-descriptions-item-content,
  .pagameno-creditusage .ant-descriptions-item-container .ant-descriptions-item-content,
  .ricaricacell-premium .ant-descriptions-item-container .ant-descriptions-item-content,
  .ricaricacell-creditusage .ant-descriptions-item-container .ant-descriptions-item-content {
    text-align: right;
    font-weight: 600; }

.pagameno-premium .ant-descriptions-item.total .ant-descriptions-item-label,
.pagameno-premium .ant-descriptions-item.total .ant-descriptions-item-content,
.pagameno-creditusage .ant-descriptions-item.total .ant-descriptions-item-label,
.pagameno-creditusage .ant-descriptions-item.total .ant-descriptions-item-content,
.ricaricacell-premium .ant-descriptions-item.total .ant-descriptions-item-label,
.ricaricacell-premium .ant-descriptions-item.total .ant-descriptions-item-content,
.ricaricacell-creditusage .ant-descriptions-item.total .ant-descriptions-item-label,
.ricaricacell-creditusage .ant-descriptions-item.total .ant-descriptions-item-content {
  font-size: 24px;
  color: #25D366; }

.pagameno-premium .proceed-note,
.pagameno-creditusage .proceed-note,
.ricaricacell-premium .proceed-note,
.ricaricacell-creditusage .proceed-note {
  font-size: 13px;
  text-align: center;
  color: #999;
  font-style: italic;
  padding-top: 5px; }

.pagameno-premium .choose-credit .ant-col,
.pagameno-creditusage .choose-credit .ant-col,
.ricaricacell-premium .choose-credit .ant-col,
.ricaricacell-creditusage .choose-credit .ant-col {
  cursor: pointer;
  text-align: center; }

.pagameno-premium .choose-credit .ant-col:not(.active-choice),
.pagameno-creditusage .choose-credit .ant-col:not(.active-choice),
.ricaricacell-premium .choose-credit .ant-col:not(.active-choice),
.ricaricacell-creditusage .choose-credit .ant-col:not(.active-choice) {
  opacity: 0.6; }

.pagameno-premium .choose-credit .ant-col.active-choice,
.pagameno-creditusage .choose-credit .ant-col.active-choice,
.ricaricacell-premium .choose-credit .ant-col.active-choice,
.ricaricacell-creditusage .choose-credit .ant-col.active-choice {
  opacity: 1; }
  .pagameno-premium .choose-credit .ant-col.active-choice .ant-card,
  .pagameno-creditusage .choose-credit .ant-col.active-choice .ant-card,
  .ricaricacell-premium .choose-credit .ant-col.active-choice .ant-card,
  .ricaricacell-creditusage .choose-credit .ant-col.active-choice .ant-card {
    border: 2px solid #25D366; }

.bulletin-form .ant-row.ant-form-item {
  flex-direction: column; }
  .bulletin-form .ant-row.ant-form-item .ant-form-item-label {
    text-align: left; }
    .bulletin-form .ant-row.ant-form-item .ant-form-item-label label {
      font-weight: 600;
      font-size: 12px;
      font-family: verdana;
      margin-bottom: 0; }

.bulletin-form .ant-row.info-support {
  margin: 20px 0; }

.bulletin-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control {
  flex-direction: row; }
  .bulletin-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain {
    margin-top: 6px; }

.mav-form .ant-row.info-support,
.rav-form .ant-row.info-support {
  margin: 20px 0; }

.mav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control,
.rav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control {
  flex-direction: row; }
  .mav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain,
  .rav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain {
    margin-top: 6px; }

.ricaricacell-form .ant-row.ant-form-item {
  flex-direction: column; }
  .ricaricacell-form .ant-row.ant-form-item .ant-form-item-label {
    text-align: left; }
    .ricaricacell-form .ant-row.ant-form-item .ant-form-item-label label {
      font-weight: 600;
      font-size: 12px;
      font-family: verdana;
      margin-bottom: 0; }

.ricaricacell-form .ant-radio-group.operators-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .ricaricacell-form .ant-radio-group.operators-list {
      flex-wrap: wrap;
      margin-top: 56px; } }
  @media only screen and (max-width: 575px) {
    .ricaricacell-form .ant-radio-group.operators-list {
      margin-top: 0; } }
  .ricaricacell-form .ant-radio-group.operators-list img {
    width: 80px; }

.ricaricacell-form .ant-form-item-control .ant-input-number input {
  padding-left: 50px; }

.ricaricacell-form .ant-tag {
  position: absolute;
  top: 62px;
  left: 8px;
  height: 32px;
  line-height: 31px;
  width: 42px;
  font-size: 14px; }
  @media only screen and (max-width: 575px) {
    .ricaricacell-form .ant-tag {
      top: 70px; } }

.pagameno-review .ant-col:not(.payment) .ant-card,
.ricaricacell-review .ant-col:not(.payment) .ant-card {
  min-height: 600px; }
  @media only screen and (max-width: 575px) {
    .pagameno-review .ant-col:not(.payment) .ant-card,
    .ricaricacell-review .ant-col:not(.payment) .ant-card {
      min-height: initial;
      margin-bottom: 20px; } }

.pagameno-review .ant-descriptions-item-label,
.ricaricacell-review .ant-descriptions-item-label {
  flex: 0.7;
  font-weight: 600; }

.pagameno-review .ant-descriptions-item-content,
.ricaricacell-review .ant-descriptions-item-content {
  text-align: right; }

.pagameno-review .ant-col.payment .ant-card,
.ricaricacell-review .ant-col.payment .ant-card {
  min-height: 350px; }

.pagameno-review .ant-col.payment .ant-descriptions-item-label,
.ricaricacell-review .ant-col.payment .ant-descriptions-item-label {
  flex: 1; }
  .pagameno-review .ant-col.payment .ant-descriptions-item-label:after,
  .ricaricacell-review .ant-col.payment .ant-descriptions-item-label:after {
    display: none; }

.pagameno-review .ant-col.payment .ant-descriptions-item-content,
.ricaricacell-review .ant-col.payment .ant-descriptions-item-content {
  flex: 0.2; }

.pagameno-review .ant-descriptions-item.premium-row .ant-descriptions-item-label:after,
.ricaricacell-review .ant-descriptions-item.premium-row .ant-descriptions-item-label:after {
  display: none; }

.pagameno-review .ant-descriptions-item.premium-row img,
.ricaricacell-review .ant-descriptions-item.premium-row img {
  width: 80px; }

.pagameno-review .ant-descriptions-item-label .ant-checkbox-wrapper,
.ricaricacell-review .ant-descriptions-item-label .ant-checkbox-wrapper {
  margin-right: 8px; }

.pagameno-review.processing .ant-card-body,
.ricaricacell-review.processing .ant-card-body {
  min-height: 250px; }

.pagameno-review.processing h4.ant-typography,
.ricaricacell-review.processing h4.ant-typography {
  text-align: center; }

.pagameno-review.processing img.bgcard,
.ricaricacell-review.processing img.bgcard {
  position: absolute;
  margin: 0 auto;
  display: block;
  width: 100px;
  top: 150px;
  left: 50%;
  transform: translateX(-50%); }

#pagameno {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto 50px;
  min-height: 40vh;
  padding-top: 20px; }
  @media screen and (max-width: 1240px) {
    #pagameno {
      max-width: 94%; } }
  #pagameno .anticon-loading {
    margin: 30px auto;
    font-size: 40px;
    display: block; }
  #pagameno .ant-result-icon {
    display: none; }
  #pagameno .ant-result-title {
    color: #25D366; }
  #pagameno .ant-steps {
    margin-bottom: 20px;
    background: #fff;
    padding: 12px 30px 15px; }
    #pagameno .ant-steps .ant-steps-item-icon {
      cursor: pointer; }

.pagameno-list .ant-row {
  margin-bottom: 20px; }

.pagameno-list .ant-card {
  text-align: center; }

.pagameno-premium .ant-descriptions-item-container,
.pagameno-creditusage .ant-descriptions-item-container {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
  align-items: center; }
  .pagameno-premium .ant-descriptions-item-container .ant-descriptions-item-label span.anticon,
  .pagameno-creditusage .ant-descriptions-item-container .ant-descriptions-item-label span.anticon {
    font-size: 19px;
    cursor: pointer;
    color: #6A706E; }
  .pagameno-premium .ant-descriptions-item-container .ant-descriptions-item-label img,
  .pagameno-creditusage .ant-descriptions-item-container .ant-descriptions-item-label img {
    width: 80px; }
  .pagameno-premium .ant-descriptions-item-container .ant-descriptions-item-content,
  .pagameno-creditusage .ant-descriptions-item-container .ant-descriptions-item-content {
    text-align: right;
    font-weight: 600; }

.pagameno-premium .ant-descriptions-item.total .ant-descriptions-item-label,
.pagameno-premium .ant-descriptions-item.total .ant-descriptions-item-content,
.pagameno-creditusage .ant-descriptions-item.total .ant-descriptions-item-label,
.pagameno-creditusage .ant-descriptions-item.total .ant-descriptions-item-content {
  font-size: 24px;
  color: #25D366; }

.pagameno-premium .proceed-note,
.pagameno-creditusage .proceed-note {
  font-size: 13px;
  text-align: center;
  color: #999;
  font-style: italic;
  padding-top: 5px; }

.pagameno-premium .choose-credit .ant-col,
.pagameno-creditusage .choose-credit .ant-col {
  cursor: pointer;
  text-align: center; }

.pagameno-premium .choose-credit .ant-col:not(.active-choice),
.pagameno-creditusage .choose-credit .ant-col:not(.active-choice) {
  opacity: 0.6; }

.pagameno-premium .choose-credit .ant-col.active-choice,
.pagameno-creditusage .choose-credit .ant-col.active-choice {
  opacity: 1; }
  .pagameno-premium .choose-credit .ant-col.active-choice .ant-card,
  .pagameno-creditusage .choose-credit .ant-col.active-choice .ant-card {
    border: 2px solid #25D366; }

.bulletin-form .ant-row.ant-form-item {
  flex-direction: column; }
  .bulletin-form .ant-row.ant-form-item .ant-form-item-label {
    text-align: left; }
    .bulletin-form .ant-row.ant-form-item .ant-form-item-label label {
      font-weight: 600;
      font-size: 12px;
      font-family: verdana;
      margin-bottom: 0; }

.bulletin-form .ant-row.info-support {
  margin: 20px 0; }

.bulletin-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control {
  flex-direction: row; }
  .bulletin-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain {
    margin-top: 6px; }

.mav-form .ant-row.info-support,
.rav-form .ant-row.info-support {
  margin: 20px 0; }

.mav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control,
.rav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control {
  flex-direction: row; }
  .mav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain,
  .rav-form .ant-row.accept .ant-form-item.ant-form-item-has-error .ant-form-item-control .ant-form-item-explain {
    margin-top: 6px; }

.pagameno-review .ant-col:not(.payment) .ant-card {
  min-height: 600px; }

.pagameno-review .ant-descriptions-item-label {
  flex: 0.7;
  font-weight: 600; }

.pagameno-review .ant-descriptions-item-content {
  text-align: right; }

.pagameno-review .ant-col.payment .ant-card {
  min-height: 350px; }

.pagameno-review .ant-col.payment .ant-descriptions-item-label {
  flex: 1; }
  .pagameno-review .ant-col.payment .ant-descriptions-item-label:after {
    display: none; }

.pagameno-review .ant-col.payment .ant-descriptions-item-content {
  flex: 0.2; }

.pagameno-review .ant-descriptions-item.premium-row .ant-descriptions-item-label:after {
  display: none; }

.pagameno-review .ant-descriptions-item.premium-row img {
  width: 80px; }

.pagameno-review .ant-descriptions-item-label .ant-checkbox-wrapper {
  margin-right: 8px; }

.pagameno-review.processing .ant-card-body {
  min-height: 250px; }

.pagameno-review.processing h4.ant-typography {
  text-align: center; }

.pagameno-review.processing img.bgcard {
  position: absolute;
  margin: 0 auto;
  display: block;
  width: 100px;
  top: 150px;
  left: 50%;
  transform: translateX(-50%); }

#bestshopping_list,
#shopping_list {
  width: 100%; }
  #bestshopping_list a:hover,
  #shopping_list a:hover {
    text-decoration: none; }
  #bestshopping_list .filter-bar,
  #shopping_list .filter-bar {
    background: transparent;
    border-bottom: 1px solid #6A706E;
    border: none;
    border-radius: 0;
    width: 100%;
    z-index: 2;
    margin-bottom: 5px;
    transition: all 0.3s; }
    #bestshopping_list .filter-bar .ant-input-search .ant-input-affix-wrapper .ant-input:hover,
    #bestshopping_list .filter-bar .ant-input-search .ant-input-affix-wrapper .ant-input:focus,
    #shopping_list .filter-bar .ant-input-search .ant-input-affix-wrapper .ant-input:hover,
    #shopping_list .filter-bar .ant-input-search .ant-input-affix-wrapper .ant-input:focus {
      background-color: #fff; }
  #bestshopping_list .filter-wrapper,
  #bestshopping_list .cards-wrapper,
  #shopping_list .filter-wrapper,
  #shopping_list .cards-wrapper {
    margin: 0 auto; }
  #bestshopping_list .filter-wrapper .ant-col,
  #shopping_list .filter-wrapper .ant-col {
    text-align: center; }
  #bestshopping_list .filter-wrapper .ant-switch-checked,
  #shopping_list .filter-wrapper .ant-switch-checked {
    background-color: #25D366; }
  #bestshopping_list .cards-wrapper,
  #shopping_list .cards-wrapper {
    float: none; }
  #bestshopping_list .sticky-filter-bar,
  #shopping_list .sticky-filter-bar {
    position: fixed;
    top: 0;
    left: 0;
    background: #6A706E;
    color: #fff; }
    #bestshopping_list .sticky-filter-bar .filter-wrapper,
    #shopping_list .sticky-filter-bar .filter-wrapper {
      max-width: 1400px;
      margin: 0 auto; }
    #bestshopping_list .sticky-filter-bar .ant-input-affix-wrapper,
    #shopping_list .sticky-filter-bar .ant-input-affix-wrapper {
      height: 32px; }
    #bestshopping_list .sticky-filter-bar .ant-select span,
    #shopping_list .sticky-filter-bar .ant-select span {
      color: #fff; }
  #bestshopping_list .grid-wrapper,
  #shopping_list .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)); }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      #bestshopping_list .grid-wrapper,
      #shopping_list .grid-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)); } }
    @media only screen and (max-width: 575px) {
      #bestshopping_list .grid-wrapper,
      #shopping_list .grid-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); } }
    #bestshopping_list .grid-wrapper .grid-element,
    #shopping_list .grid-wrapper .grid-element {
      border: 1px solid #f5f5f5;
      border-radius: 8px;
      margin: 10px;
      background: #fff;
      padding: 1rem;
      display: grid;
      place-items: center;
      position: relative;
      color: #6A706E;
      cursor: pointer;
      overflow: hidden; }
      #bestshopping_list .grid-wrapper .grid-element:hover,
      #shopping_list .grid-wrapper .grid-element:hover {
        z-index: 1; }
        #bestshopping_list .grid-wrapper .grid-element:hover .shine,
        #shopping_list .grid-wrapper .grid-element:hover .shine {
          opacity: 1;
          top: -30%;
          left: -30%;
          transition-property: left, top, opacity;
          transition-duration: 1.2s, 1.2s, 0.28s;
          transition-timing-function: ease; }
      #bestshopping_list .grid-wrapper .grid-element .ant-col,
      #shopping_list .grid-wrapper .grid-element .ant-col {
        text-align: center; }
      #bestshopping_list .grid-wrapper .grid-element .offer-premium:before,
      #shopping_list .grid-wrapper .grid-element .offer-premium:before {
        font-size: 18px;
        font-family: 'icomoon';
        display: inline-block;
        margin-right: 5px;
        content: '\e92c';
        color: #25D366; }
      #bestshopping_list .grid-wrapper .grid-element img,
      #shopping_list .grid-wrapper .grid-element img {
        margin: 10px 0;
        grid-area: 1 / 1 / 1/ 1;
        width: 100%; }
      #bestshopping_list .grid-wrapper .grid-element strong,
      #shopping_list .grid-wrapper .grid-element strong {
        font-weight: bold; }
      #bestshopping_list .grid-wrapper .grid-element h4,
      #shopping_list .grid-wrapper .grid-element h4 {
        grid-area: 2 / 1 / 2/ 1;
        font-size: 18px;
        text-align: center; }
      #bestshopping_list .grid-wrapper .grid-element .label-info,
      #shopping_list .grid-wrapper .grid-element .label-info {
        height: 30px;
        overflow: hidden;
        grid-area: 3 / 1 / 3/ 1;
        line-height: 1; }
      #bestshopping_list .grid-wrapper .grid-element .icon:after,
      #shopping_list .grid-wrapper .grid-element .icon:after {
        font-family: 'icomoon';
        font-size: 30px;
        display: inline-block;
        vertical-align: middle;
        color: #6A706E;
        margin-bottom: 5px; }
      #bestshopping_list .grid-wrapper .grid-element .icon.icon-ticket:after,
      #shopping_list .grid-wrapper .grid-element .icon.icon-ticket:after {
        content: '\e93b'; }
      #bestshopping_list .grid-wrapper .grid-element .icon.icon-credits:after,
      #shopping_list .grid-wrapper .grid-element .icon.icon-credits:after {
        content: '\e987';
        color: #25D366; }
      #bestshopping_list .grid-wrapper .grid-element .progress-label,
      #shopping_list .grid-wrapper .grid-element .progress-label {
        font-size: 10px;
        text-transform: uppercase; }
      #bestshopping_list .grid-wrapper .grid-element .progress-value,
      #shopping_list .grid-wrapper .grid-element .progress-value {
        font-size: 15px;
        margin: 0 10px 0 5px; }
      #bestshopping_list .grid-wrapper .grid-element .label-not-orange-premium,
      #bestshopping_list .grid-wrapper .grid-element .label-not-orange,
      #shopping_list .grid-wrapper .grid-element .label-not-orange-premium,
      #shopping_list .grid-wrapper .grid-element .label-not-orange {
        display: none;
        color: #6A706E; }
      #bestshopping_list .grid-wrapper .grid-element .label-not-orange-2,
      #bestshopping_list .grid-wrapper .grid-element .label-not-orange-premium-2,
      #shopping_list .grid-wrapper .grid-element .label-not-orange-2,
      #shopping_list .grid-wrapper .grid-element .label-not-orange-premium-2 {
        background-color: #6A706E;
        color: #fff;
        font-size: 12px;
        position: absolute;
        top: -1px;
        width: 100%;
        padding: 4px 10px;
        left: 0;
        text-align: right; }
      #bestshopping_list .grid-wrapper .grid-element .label-not-orange-2,
      #shopping_list .grid-wrapper .grid-element .label-not-orange-2 {
        background-color: #25D366; }
      #bestshopping_list .grid-wrapper .grid-element.user-not-orange .label-not-orange,
      #shopping_list .grid-wrapper .grid-element.user-not-orange .label-not-orange {
        display: block; }
      #bestshopping_list .grid-wrapper .grid-element.user-not-orange-premium img,
      #shopping_list .grid-wrapper .grid-element.user-not-orange-premium img {
        opacity: 0.5; }
      #bestshopping_list .grid-wrapper .grid-element.user-not-orange-premium .label-not-orange-premium,
      #shopping_list .grid-wrapper .grid-element.user-not-orange-premium .label-not-orange-premium {
        display: block; }
      #bestshopping_list .grid-wrapper .grid-element .ant-statistic-content-prefix,
      #shopping_list .grid-wrapper .grid-element .ant-statistic-content-prefix {
        font-size: 16px; }
      #bestshopping_list .grid-wrapper .grid-element .ant-statistic-content-value,
      #shopping_list .grid-wrapper .grid-element .ant-statistic-content-value {
        font-size: 12px; }
      #bestshopping_list .grid-wrapper .grid-element .label-orange,
      #shopping_list .grid-wrapper .grid-element .label-orange {
        height: 14px;
        margin-bottom: 0;
        font-size: 13px;
        margin-top: 5px;
        overflow: hidden;
        grid-area: 4 / 1 / 4/ 1;
        line-height: 1;
        color: #25D366; }
  #bestshopping_list .grid-element-adv,
  #shopping_list .grid-element-adv {
    overflow: hidden;
    position: relative; }
    #bestshopping_list .grid-element-adv > div,
    #shopping_list .grid-element-adv > div {
      float: left; }
    #bestshopping_list .grid-element-adv > div:nth-child(2),
    #shopping_list .grid-element-adv > div:nth-child(2) {
      float: right; }
  #bestshopping_list .ant-btn-primary,
  #shopping_list .ant-btn-primary {
    background-color: #25D366;
    border-color: #25D366; }
    #bestshopping_list .ant-btn-primary:hover, #bestshopping_list .ant-btn-primary:focus,
    #shopping_list .ant-btn-primary:hover,
    #shopping_list .ant-btn-primary:focus {
      background-color: #1da851;
      border-color: #25D366; }
  #bestshopping_list .ant-btn:hover, #bestshopping_list .ant-btn:focus,
  #shopping_list .ant-btn:hover,
  #shopping_list .ant-btn:focus {
    color: #6A706E;
    border-color: #161032; }
  #bestshopping_list .ov-ant-inpt:hover, #bestshopping_list .ant-select-auto-complete .ant-select-search__field__wrap .ant-input:hover, #shopping_list .ant-select-auto-complete .ant-select-search__field__wrap #bestshopping_list .ant-input:hover,
  #shopping_list .ov-ant-inpt:hover,
  #bestshopping_list .ant-select-auto-complete .ant-select-search__field__wrap #shopping_list .ant-input:hover,
  #shopping_list .ant-select-auto-complete .ant-select-search__field__wrap .ant-input:hover {
    border-color: #25D366 !important; }
    #bestshopping_list .ov-ant-inpt:hover .ant-input:not(.ant-input-disabled), #bestshopping_list .ant-select-auto-complete .ant-select-search__field__wrap .ant-input:hover .ant-input:not(.ant-input-disabled), #shopping_list .ant-select-auto-complete .ant-select-search__field__wrap #bestshopping_list .ant-input:hover .ant-input:not(.ant-input-disabled),
    #shopping_list .ov-ant-inpt:hover .ant-input:not(.ant-input-disabled),
    #bestshopping_list .ant-select-auto-complete .ant-select-search__field__wrap #shopping_list .ant-input:hover .ant-input:not(.ant-input-disabled),
    #shopping_list .ant-select-auto-complete .ant-select-search__field__wrap .ant-input:hover .ant-input:not(.ant-input-disabled) {
      border-color: #25D366 !important; }
  #bestshopping_list .ov-ant-inpt:focus, #bestshopping_list .ant-select-auto-complete .ant-select-search__field__wrap .ant-input:focus, #shopping_list .ant-select-auto-complete .ant-select-search__field__wrap #bestshopping_list .ant-input:focus,
  #shopping_list .ov-ant-inpt:focus,
  #bestshopping_list .ant-select-auto-complete .ant-select-search__field__wrap #shopping_list .ant-input:focus,
  #shopping_list .ant-select-auto-complete .ant-select-search__field__wrap .ant-input:focus {
    border-color: #25D366;
    box-shadow: 0 0 0 2px rgba(37, 211, 102, 0.2) !important; }
  #bestshopping_list .ant-list-item.mine,
  #shopping_list .ant-list-item.mine {
    justify-content: flex-end; }
  #bestshopping_list .ant-list-item.other,
  #shopping_list .ant-list-item.other {
    justify-content: flex-start; }
  #bestshopping_list .ant-list-item.system,
  #shopping_list .ant-list-item.system {
    justify-content: center; }
  #bestshopping_list .ant-list-item-meta-avatar,
  #shopping_list .ant-list-item-meta-avatar {
    margin-right: 8px; }
  .small #bestshopping_list .ant-btn-round.ant-btn-lg, .small
  #shopping_list .ant-btn-round.ant-btn-lg {
    height: 22px;
    padding: 0 10px;
    font-size: 15px;
    border-radius: 40px;
    line-height: 5px; }
  #bestshopping_list .ant-btn-background-ghost.ant-btn-primary,
  #shopping_list .ant-btn-background-ghost.ant-btn-primary {
    color: #f5f5f5;
    background-color: transparent;
    border-color: #f5f5f5;
    text-shadow: none; }
  #bestshopping_list .ant-comment-inner,
  #shopping_list .ant-comment-inner {
    padding: 0; }
    #bestshopping_list .ant-comment-inner .ant-comment-avatar,
    #shopping_list .ant-comment-inner .ant-comment-avatar {
      position: absolute;
      left: -1px;
      top: -21px; }
      .single #bestshopping_list .ant-comment-inner .ant-comment-avatar, .single
      #shopping_list .ant-comment-inner .ant-comment-avatar {
        display: none; }
    #bestshopping_list .ant-comment-inner .ant-comment-actions,
    #shopping_list .ant-comment-inner .ant-comment-actions {
      position: absolute;
      margin: 0;
      bottom: -1px;
      right: -47px;
      width: 43px; }
      #bestshopping_list .ant-comment-inner .ant-comment-actions > li,
      #shopping_list .ant-comment-inner .ant-comment-actions > li {
        display: block;
        height: 17px; }
  #bestshopping_list .ant-comment-content-author,
  #shopping_list .ant-comment-content-author {
    position: absolute;
    top: -23px;
    left: 20px;
    font-weight: 600; }
  #bestshopping_list .ant-dropdown,
  #shopping_list .ant-dropdown {
    z-index: 1050000000; }
  #bestshopping_list .ant-popover,
  #bestshopping_list .ant-modal-mask,
  #bestshopping_list .ant-modal-wrap,
  #shopping_list .ant-popover,
  #shopping_list .ant-modal-mask,
  #shopping_list .ant-modal-wrap {
    z-index: 999999; }
  #bestshopping_list .ant-message,
  #shopping_list .ant-message {
    z-index: 999999; }
  .ChatHeader #bestshopping_list .ant-alert-warning, .ChatHeader
  #shopping_list .ant-alert-warning {
    position: absolute;
    left: 0;
    top: 70px;
    z-index: 9999;
    width: 100%;
    text-align: center; }
  #bestshopping_list .ant-back-top,
  #shopping_list .ant-back-top {
    right: 30px;
    bottom: 100px; }
  #bestshopping_list .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  #shopping_list .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #77e7a1; }

#bestshopping_list .bs-cardname {
  grid-area: 1 / 1 / 1 / 1 !important; }

#bestshopping_list .bs-image {
  grid-area: 2 / 1 / 2 / 1 !important;
  background-size: contain !important;
  height: 7rem; }

#bestshopping_list .bs-label {
  height: 3rem !important;
  font-size: 15px;
  padding: 0 2rem; }
  #bestshopping_list .bs-label span {
    float: right; }

#welcome-credits {
  margin: 10px; }
  #welcome-credits .total {
    color: #6A706E;
    font-weight: 600;
    font-size: 14px; }
  #welcome-credits .bar-background {
    box-shadow: inset 0px 3px 8px 0px #25D366;
    border-radius: 50px;
    background-color: #E9FBEB;
    height: 25px;
    width: 100%; }
  #welcome-credits .bar-fill {
    border-radius: 50px;
    position: relative;
    background-color: #25D366;
    height: 100%;
    box-shadow: inset 0px 3px 8px 0px #E9FBEB; }
    #welcome-credits .bar-fill span {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      right: 0;
      color: #6A706E;
      white-space: nowrap;
      font-weight: 600;
      font-size: 14px; }
  #welcome-credits .bar-point {
    box-shadow: inset 0px 5px 10px 0px #E9FBEB;
    background-color: #25D366;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -17px;
    width: 35px;
    height: 35px; }

.sort-icon {
  font-size: 16px;
  margin-left: 5px; }

#social-timeline {
  width: 100%;
  margin: 0 auto 50px;
  padding-top: 50px; }
  #social-timeline h4 > a {
    margin-left: 5px;
    color: #43446d !important;
    text-decoration: underline; }
  @media (max-width: 768px) {
    #social-timeline .right-col {
      display: none; } }
  #social-timeline .right-col .ant-card {
    margin-bottom: 30px; }
  #social-timeline .right-col .orange-banner {
    background-image: url("/assets/images/icons/orange-banner.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 138px; }
    #social-timeline .right-col .orange-banner .ant-typography {
      margin-bottom: 0; }
    #social-timeline .right-col .orange-banner .color-orange {
      margin-top: 0;
      color: #25D366 !important; }
  #social-timeline .right-col .friend-info img {
    margin-top: 10px; }
  #social-timeline .right-col .friend-info .title {
    margin-bottom: 5px; }
  #social-timeline .right-col .friend-info .value {
    font-size: 20px;
    text-transform: lowercase; }
  #social-timeline .write-form .link-preview {
    background: #f5f5f5;
    position: relative; }
    #social-timeline .write-form .link-preview .anticon-close-circle {
      position: absolute;
      top: 6px;
      right: 6px;
      cursor: pointer;
      font-size: 20px; }
  #social-timeline .post-content,
  #social-timeline .post-edit {
    width: 100%; }
    #social-timeline .post-content .post-description .ant-tag-blue,
    #social-timeline .post-edit .post-description .ant-tag-blue {
      cursor: pointer; }
    #social-timeline .post-content .image-preview,
    #social-timeline .post-edit .image-preview {
      width: 100%;
      max-height: 500px;
      object-fit: scale-down;
      cursor: pointer;
      margin-bottom: 5px;
      background-color: transparent; }
  #social-timeline .post-edit textarea {
    min-height: 200px; }
  #social-timeline .post-head .time {
    font-size: 12px;
    color: #666; }
  #social-timeline .bg-share {
    background: #f5f5f5;
    padding: 10px !important; }
    #social-timeline .bg-share .image-shared {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
      cursor: pointer;
      margin-bottom: 5px; }
  #social-timeline .post-actions .ant-typography {
    cursor: pointer; }
  #social-timeline .post-actions .ant-typography.like.liked {
    color: #25D366; }
  #social-timeline .comment-list {
    background: #fafafa;
    padding: 6px 10px;
    box-shadow: #dfdfdf 0px 1px 2px inset;
    margin-bottom: 10px; }
    #social-timeline .comment-list .ant-list-item {
      position: relative; }
    #social-timeline .comment-list .ant-list-item-meta-title {
      margin-bottom: 0; }
    #social-timeline .comment-list .ant-list-item-meta-description {
      font-size: 12px; }
    #social-timeline .comment-list .ant-list-item-action {
      position: absolute;
      top: 0;
      right: 0; }
  #social-timeline .friend-friends .ant-avatar {
    cursor: pointer; }
  #social-timeline #users-search {
    padding: 0; }
    #social-timeline #users-search .ant-list {
      position: absolute;
      max-height: 500px;
      width: 120px;
      z-index: 99;
      background: #fff;
      overflow-y: scroll;
      border: 1px solid #eee; }
      #social-timeline #users-search .ant-list .ant-list-item:hover {
        cursor: pointer;
        background-color: #eee; }
        #social-timeline #users-search .ant-list .ant-list-item:hover .ant-card-body {
          background-color: #eee; }
      #social-timeline #users-search .ant-list .ant-card-body {
        padding: 10px; }
    #social-timeline #users-search input[type='text']:hover,
    #social-timeline #users-search input[type='text']:active,
    #social-timeline #users-search input[type='text']:focus {
      background-color: #fff; }

.ant-modal  .post-share-img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 5px; }

.share-post-modal .shared {
  padding: 20px;
  background: #efefef; }

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -5px;
  width: auto; }

.my-masonry-grid_column {
  padding-left: 5px;
  background-clip: padding-box; }

.my-masonry-grid_column > img {
  background: gray;
  margin-bottom: 30px; }

#userpagameno {
  padding-top: 50px; }
  #userpagameno .anticon-loading {
    margin: 30px auto;
    font-size: 30px;
    display: block; }
  #userpagameno table th.ant-table-cell {
    background-color: #f5f5f5; }

#userranking > .ant-row {
  justify-content: flex-end; }

#userranking .anticon-loading {
  margin: 30px auto;
  font-size: 30px;
  display: block; }

#userfriends > .ant-row {
  justify-content: flex-end; }

#userfriends .anticon-loading {
  margin: 30px auto;
  font-size: 30px;
  display: block; }

#userfriends table th.ant-table-cell {
  background-color: #f5f5f5; }

#userfriends .friends-title {
  display: flex;
  align-items: 'center'; }
  #userfriends .friends-title button {
    margin: 0 5px; }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  #usercard {
    display: flex;
    display: flex;
    align-items: center; } }

#usercard .ant-row {
  margin-bottom: 0; }

#usercard .ant-typography:first-child {
  margin-bottom: 0;
  margin-top: 10px; }

#usercard .ant-typography + h4.ant-typography {
  margin-top: 0; }

#usercard.invited-friends .level-counter, #usercard.invited-friends .points-counter,
#usercard.invited-friends .points {
  display: none; }

#usercard .level-counter, #usercard .points-counter {
  position: relative; }
  #usercard .level-counter:after, #usercard .points-counter:after {
    content: '\e900';
    font-family: 'icomoon';
    color: #07004D;
    display: block;
    font-size: 24px;
    left: -64px;
    position: absolute;
    top: 50%;
    margin-top: -19px; }

#usercard .points-counter:after {
  content: '\e901'; }

#groups > .ant-row-flex-end {
  justify-content: flex-end; }

#groups .category {
  cursor: pointer; }

#groups .category.active {
  color: #25D366; }

#groups #usergroup > .ant-row-flex-end {
  justify-content: flex-end; }

#groups #usergroup #group-details {
  margin-bottom: 40px; }
  #groups #usergroup #group-details .ant-card-body {
    text-align: center; }

#groups #usergroup .admin-border {
  border-radius: 3px;
  border-color: #6A706E;
  border-width: 1px; }

#groups #usergroup .post-content {
  width: 100%; }
  #groups #usergroup .post-content .post-head .time {
    font-size: 12px;
    color: #666; }
  #groups #usergroup .post-content .post-description .ant-tag-blue {
    cursor: pointer; }
  #groups #usergroup .post-content .image-preview {
    cursor: pointer; }
  #groups #usergroup .post-content .link-preview {
    background: #efefef;
    cursor: pointer; }
  #groups #usergroup .post-content .post-actions .ant-typography {
    cursor: pointer; }
  #groups #usergroup .post-content .post-actions .ant-typography.like.liked {
    color: #6363e8;
    font-weight: 600; }
  #groups #usergroup .post-content .comment-list .ant-list-item-meta-title {
    margin-bottom: 0; }
  #groups #usergroup .post-content .comment-list .ant-list-item-meta-description {
    font-size: 12px; }
  #groups #usergroup .post-content .comment-list .ant-list-item {
    background: #f7f7f7;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative; }
  #groups #usergroup .post-content .comment-list .ant-list-item-action {
    position: absolute;
    top: 0;
    right: 0; }

#groups #usergroup .post-edit {
  width: 100%; }

#userbadges > .ant-row {
  justify-content: flex-end; }

#userbadges .ant-card-body {
  text-align: center; }

#userbadges .badge-count {
  min-height: 1.5rem; }

#userbadges .badge-rules-link {
  color: #25D366; }
  #userbadges .badge-rules-link:hover {
    text-decoration: underline; }

.badge-listing-icon {
  overflow: hidden;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  display: block;
  margin: 30px auto;
  background: #07004D;
  box-shadow: 2px 2px 5px rgba(7, 0, 77, 0.5); }
  .badge-listing-icon i:before {
    color: #fff;
    line-height: 90px !important;
    font-family: 'icomoon';
    font-size: 80px !important;
    content: '\e954'; }
  .badge-listing-icon.weekly_login i:before {
    content: '\e955'; }
  .badge-listing-icon.monthly_login i:before {
    content: '\e956'; }
  .badge-listing-icon.yearly_login i:before {
    content: '\e957'; }
  .badge-listing-icon.shopper i:before {
    content: '\e950'; }
  .badge-listing-icon.shopper2 i:before {
    content: '\e949'; }
  .badge-listing-icon.writer i:before {
    content: '\e948'; }
  .badge-listing-icon.writer2 i:before {
    content: '\e947'; }
  .badge-listing-icon.citizen i:before, .badge-listing-icon.profile_name i:before, .badge-listing-icon.profile_date i:before, .badge-listing-icon.profile_country i:before, .badge-listing-icon.profile_currency i:before, .badge-listing-icon.profile_pic i:before {
    content: '\e951'; }
  .badge-listing-icon.extraprofit i:before {
    content: '\e944'; }
  .badge-listing-icon.premium1 i:before {
    content: '\e940'; }
  .badge-listing-icon.extrapremium i:before {
    content: '\e943'; }
  .badge-listing-icon.extrapremium5 i:before, .badge-listing-icon.premium5 i:before {
    content: '\e939'; }
  .badge-listing-icon.extrapremium10 i:before, .badge-listing-icon.premium10 i:before {
    content: '\e953'; }
  .badge-listing-icon.level i:before {
    content: '\e952'; }
  .badge-listing-icon.ambassador_US i:before {
    content: '\e926'; }
  .badge-listing-icon.ambassador_DE i:before {
    content: '\e928'; }
  .badge-listing-icon.ambassador_UK i:before {
    content: '\e927'; }
  .badge-listing-icon.ambassador_FR i:before {
    content: '\e929'; }
  .badge-listing-icon.ambassador_ES i:before {
    content: '\e92a'; }
  .badge-listing-icon.small {
    width: 46px;
    height: 46px;
    margin: 15px auto; }
    .badge-listing-icon.small i:before {
      line-height: 46px !important;
      font-size: 40px !important; }
  .badge-listing-icon a {
    width: 90px;
    height: 90px; }

#ticketmodal {
  text-align: center;
  font-size: 1rem; }
  #ticketmodal h4 {
    color: #6A706E; }
  #ticketmodal .error-message-ico {
    background: url(/assets/images/icons/attention.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 5rem;
    margin: 0 auto 1rem;
    padding-top: 5rem; }

#userticket {
  padding-top: 50px; }
  #userticket .anticon-loading {
    margin: 30px auto;
    font-size: 30px;
    display: block; }
  #userticket .banner {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #efe9e4;
    font-size: 1rem; }
    #userticket .banner.sxc-banner {
      background: #43446d;
      height: 15rem;
      position: relative;
      padding-top: 2rem; }
      #userticket .banner.sxc-banner .header h3 {
        color: #fff;
        font-size: 32px;
        text-align: center;
        margin-bottom: 0; }
      #userticket .banner.sxc-banner .header h4 {
        font-size: 60px;
        color: #fff;
        text-align: center;
        margin-top: 0; }
    #userticket .banner h3.header {
      font-size: 22px;
      margin-top: 0.5rem; }
      #userticket .banner h3.header span {
        color: #6A706E; }
    #userticket .banner .method {
      position: relative;
      display: inline-block;
      line-height: 60px; }
      #userticket .banner .method:before {
        font-family: 'icomoon';
        display: block;
        font-size: 56px; }
      #userticket .banner .method.ticket {
        padding-left: 60px; }
        #userticket .banner .method.ticket:before {
          content: '\e93b';
          position: absolute;
          left: 0; }
    #userticket .banner input[type='text'] {
      height: 44px;
      border: 2px solid #ffc386;
      border-radius: 6px;
      font-size: 17px;
      padding: 0 20px;
      margin-bottom: 20px;
      margin-right: 30px;
      width: 400px; }
      #userticket .banner input[type='text'].error {
        border-color: #ff4949; }

#verify {
  background-color: #fff;
  padding-bottom: 3rem; }
  #verify .head {
    text-align: center;
    display: block;
    margin-bottom: 3rem; }
  #verify .icon {
    font-size: 2.5rem;
    color: #25D366;
    margin: 1.5rem auto 2rem;
    display: block; }
  #verify .link {
    display: block;
    background-color: transparent;
    border: none; }
  #verify .feedback-message {
    position: relative;
    top: -1.5rem; }
  #verify .invitation-claim {
    font-size: 1.2rem; }
  #verify .banner {
    background: url("/assets/images/invite.png") 0 0 no-repeat;
    background-size: contain;
    margin: 2rem auto;
    width: fit-content;
    text-align: left;
    padding-left: 3.2rem; }

#sxccardmodal {
  text-align: center;
  font-size: 1rem; }
  #sxccardmodal h4 {
    color: #6A706E; }
  #sxccardmodal .error-message-ico {
    background: url(/assets/images/icons/attention.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 5rem;
    margin: 0 auto 1rem;
    padding-top: 5rem; }

#usersxccard {
  padding-top: 50px; }
  #usersxccard .anticon-loading {
    margin: 30px auto;
    font-size: 30px;
    display: block; }
  #usersxccard .banner {
    margin-top: 20px;
    background: #fff;
    border: 1px solid #efe9e4;
    border-radius: 3px;
    font-size: 1rem; }
    #usersxccard .banner h3 {
      font-size: 22px;
      margin-top: 0.5rem; }
    #usersxccard .banner.recharge .method-select {
      display: block;
      height: 4rem;
      font-size: 1rem; }
      #usersxccard .banner.recharge .method-select .description {
        display: inline-block;
        vertical-align: top;
        height: 4rem;
        padding: 1rem 0 0 0.5rem; }
      #usersxccard .banner.recharge .method-select:hover {
        cursor: pointer; }
      #usersxccard .banner.recharge .method-select.active {
        border: 2px solid #6A706E;
        border-radius: 5px;
        background-color: #25D366; }
    #usersxccard .banner.recharge .method {
      position: relative;
      display: inline-block;
      line-height: 4rem;
      padding-left: 0; }
      #usersxccard .banner.recharge .method:after {
        font-family: 'icomoon';
        font-size: 4rem; }
      #usersxccard .banner.recharge .method.sxc-card:after {
        content: '\e934'; }
      #usersxccard .banner.recharge .method.lotto:after {
        content: '\e935'; }
    #usersxccard .banner.recharge .recharge-form {
      padding: 1rem; }
      #usersxccard .banner.recharge .recharge-form form {
        margin-bottom: 0.5rem; }
      #usersxccard .banner.recharge .recharge-form input {
        border-radius: 0.25rem;
        height: 2.25rem; }
      #usersxccard .banner.recharge .recharge-form button {
        max-height: 2.25rem; }

#userfamilybox > .ant-row {
  justify-content: flex-end; }

#userfamilybox .balance-block {
  display: flex;
  justify-content: center;
  text-align: center; }
  #userfamilybox .balance-block .ant-typography {
    margin: 0;
    font-size: 30px;
    font-weight: 300; }

#userfamilybox .familybox-img {
  width: 90px;
  margin-right: 20px; }
  @media only screen and (max-width: 575px) {
    #userfamilybox .familybox-img {
      display: none; } }

#userorange {
  padding-top: 50px; }
  #userorange .info-box {
    margin-bottom: 10px; }
  #userorange .expiry-date span {
    color: #137939;
    display: inline-block;
    margin-right: 10px; }
  #userorange .orange-subscription {
    text-align: center;
    border: 1px solid transparent;
    opacity: 0.8;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s; }
    #userorange .orange-subscription.selected {
      border-color: #07004D;
      opacity: 1;
      transform: scale(1.03); }
    #userorange .orange-subscription .ant-card-body {
      padding: 0; }
    #userorange .orange-subscription h5 {
      background-image: url(/assets/images/orange/orange-premium-bg-tiny.png);
      background-size: 100% auto;
      background-repeat: no-repeat;
      padding: 20px;
      font-size: 20px;
      color: #fff !important; }
    #userorange .orange-subscription .month-price {
      text-transform: lowercase;
      font-size: 16px;
      color: #25D366;
      margin-bottom: 10px; }
    #userorange .orange-subscription .total {
      font-size: 18px;
      margin-bottom: 40px; }
      #userorange .orange-subscription .total strong {
        line-height: 1em;
        display: block;
        font-size: 25px;
        color: #25D366; }
    #userorange .orange-subscription .orange-check {
      position: absolute;
      bottom: -8px;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      background-image: linear-gradient(#137939, #25D366);
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center; }
      #userorange .orange-subscription .orange-check i {
        color: #fff;
        font-size: 20px; }
  #userorange .bttn-2 {
    background-image: linear-gradient(to right, #161032 0%, #6562d8 100%);
    margin: 0px auto;
    display: block;
    width: 150px;
    font-size: 18px;
    transition: none; }
    #userorange .bttn-2:hover {
      border-color: #6A706E; }
  #userorange .redeem-form-wrapper {
    opacity: 0.5;
    pointer-events: none; }
    #userorange .redeem-form-wrapper .ant-alert {
      display: none; }
    #userorange .redeem-form-wrapper .extra-note {
      font-size: 12px;
      color: grey;
      margin-top: -18px; }
    #userorange .redeem-form-wrapper .bttn-redeem {
      display: none; }
    #userorange .redeem-form-wrapper.redeem-visibile {
      opacity: 1;
      pointer-events: initial; }
      #userorange .redeem-form-wrapper.redeem-visibile .bttn-redeem {
        display: block; }
      #userorange .redeem-form-wrapper.redeem-visibile .ant-alert {
        display: inherit; }
  #userorange .bttn-selectplan {
    display: none; }
    #userorange .bttn-selectplan.btn-visibile {
      display: block; }
  #userorange .redeem-code input {
    border: 1px solid #137939;
    max-width: 300px;
    padding: 10px; }
  #userorange .input-share-wrapper {
    position: relative; }
    #userorange .input-share-wrapper .success {
      position: absolute;
      bottom: -20px;
      right: 0; }
  #userorange .input-share {
    padding: 0 0 0 20px; }
    #userorange .input-share button {
      border: none; }
    #userorange .input-share:hover {
      border-color: initial; }
  #userorange .checkout-grid .item {
    display: flex;
    align-items: center; }
    #userorange .checkout-grid .item > :first-child {
      flex-grow: 1; }
  #userorange .ant-radio-group {
    display: flex;
    flex-direction: column; }
    #userorange .ant-radio-group .ant-radio-wrapper {
      display: flex;
      align-items: center;
      margin-right: 0; }
    #userorange .ant-radio-group .ant-radio + span {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      padding-right: 0; }
      #userorange .ant-radio-group .ant-radio + span > div {
        flex-grow: 1;
        text-transform: capitalize; }
      #userorange .ant-radio-group .ant-radio + span img {
        padding: 5px;
        width: 70px;
        max-height: 41px;
        object-fit: contain;
        border-radius: 5px;
        box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.1); }
  #userorange .orange-checkout .bttn-checkout {
    color: #fff;
    margin: 20px auto;
    background: linear-gradient(to right, #6A706E 0%, #07004D 100%);
    display: block; }
    #userorange .orange-checkout .bttn-checkout:hover {
      border-top-color: #6A706E; }
    #userorange .orange-checkout .bttn-checkout:disabled {
      opacity: 0.5; }

#usermomosy > .ant-row {
  justify-content: flex-end; }

#usermomosy h5 {
  color: #25D366; }

#usermomosy ul {
  list-style: circle; }

#howitworks {
  padding-top: 20px; }

#userwallet {
  padding-top: 50px; }
  #userwallet .orange-banner {
    background-image: url("/assets/images/banner-orange-big.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: contain; }
    #userwallet .orange-banner .ant-typography {
      margin-bottom: 0; }
    #userwallet .orange-banner .color-orange {
      margin-top: 0;
      color: #25D366 !important; }
  #userwallet .info-block {
    text-align: center;
    font-size: 26px; }
    #userwallet .info-block h4.ant-typography {
      margin-bottom: 5px; }
    #userwallet .info-block b {
      display: block;
      font-size: 34px; }
    #userwallet .info-block.credits b {
      color: #25D366; }
    #userwallet .info-block.points b {
      color: #07004D; }
  #userwallet #uservouchers {
    max-width: 100%;
    padding: 0;
    margin-top: -20px; }
    #userwallet #uservouchers h5 {
      font-size: 18px;
      padding: 7px;
      margin-top: -20px;
      display: block;
      text-align: center;
      font-weight: 300;
      margin-bottom: 5px; }
    #userwallet #uservouchers .view-all {
      margin: 20px auto 0;
      width: 100px;
      display: block; }
  #userwallet .CircularProgressbar {
    width: 100%;
    vertical-align: middle; }
  #userwallet .CircularProgressbar .CircularProgressbar-path {
    stroke: #25D366;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s; }
  #userwallet .CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
    stroke-linecap: round; }
  #userwallet .CircularProgressbar .CircularProgressbar-text {
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle; }
  #userwallet .CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6; }
  #userwallet .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
    fill: #25D366; }
  #userwallet .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff; }
  #userwallet .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff; }
  #userwallet .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent; }

.extraprofit,
.chart-earned,
.redeem,
.daily-earnings {
  background: #fff; }
  .extraprofit h5,
  .chart-earned h5,
  .redeem h5,
  .daily-earnings h5 {
    font-size: 18px;
    padding: 7px;
    display: block;
    font-weight: 300; }

.extraprofit h3.ant-typography {
  margin-top: 0 !important; }

.extraprofit h1.ant-typography {
  margin-top: 0 !important;
  font-size: 40px;
  margin-bottom: 5px; }

.chart-earned .card-wrapper {
  display: flex; }
  .chart-earned .card-wrapper .ant-card:last-child {
    border: none; }
  @media only screen and (max-width: 575px) {
    .chart-earned .card-wrapper .ant-card:nth-child(1),
    .chart-earned .card-wrapper .ant-card:nth-child(2),
    .chart-earned .card-wrapper .ant-card:nth-child(3),
    .chart-earned .card-wrapper .ant-card:nth-child(4) {
      display: none; }
    .chart-earned .card-wrapper .ant-card {
      width: 25%; } }

.chart-earned h5.ant-typography {
  margin-bottom: 0;
  margin-left: 56px; }

.chart-earned .ant-typography + h3.ant-typography {
  margin-top: 0;
  color: #25D366 !important;
  margin-left: 61px; }

.chart-earned .ant-card {
  width: 12.5%;
  text-align: center;
  box-sizing: border-box;
  border-right: 1px dashed #d8d8d8; }
  .chart-earned .ant-card .ant-card-body {
    padding: 20px 0px; }

.chart-earned .ant-progress-line {
  transform: rotate(-90deg) translate(-30%, -204%);
  width: 180px;
  z-index: 1; }

.chart-earned .ant-progress-text {
  transform: rotate(90deg) translateX(5px); }

.chart-earned .month-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  text-transform: capitalize;
  flex-direction: column;
  margin-top: 140px; }
  .chart-earned .month-detail > span:nth-child(2) {
    font-weight: 600; }

.chart-earned .disabled {
  pointer-events: none; }

.redeem form {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 20px 20px; }

.level-counter, #usercard .points-counter {
  display: inline;
  font-size: 16px;
  position: relative;
  min-width: 80px;
  margin: 0 20px 0 0; }
  .level-counter:after, #usercard .points-counter:after {
    position: absolute;
    font-family: 'icomoon';
    content: '\e900';
    right: 22px;
    margin-top: 1px;
    font-size: 20px; }

.points-counter {
  display: inline;
  font-size: 16px;
  position: relative;
  min-width: 80px;
  margin: 0 20px 0 0;
  margin: 0 20px; }
  .points-counter::after {
    content: '\e900';
    font-family: 'icomoon';
    color: #43446d;
    display: block;
    font-size: 24px;
    right: -22px;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    content: '\e901';
    right: -28px;
    margin-top: -12px; }

#usercredits {
  padding-top: 50px; }
  #usercredits .info-block {
    text-align: center;
    font-size: 26px;
    cursor: pointer; }
    #usercredits .info-block .ant-card-body {
      padding: 0; }
    #usercredits .info-block span {
      font-size: 18px;
      padding: 6px;
      display: block;
      color: #fff;
      font-weight: 300; }
    #usercredits .info-block b {
      display: block;
      padding: 10px; }
  #usercredits .info-block-wrapper > .ant-col:nth-child(1) span {
    background: linear-gradient(128deg, #dd5a0b 0%, #f47f00 100%); }
  #usercredits .balance-block {
    display: flex;
    justify-content: center;
    text-align: center; }
    #usercredits .balance-block .ant-typography {
      margin: 0;
      font-size: 30px;
      font-weight: 300; }
  #usercredits .ant-pagination {
    text-align: center;
    padding: 20px; }

.wallet-history {
  background: #fff; }
  .wallet-history h5 {
    font-size: 18px;
    padding: 7px;
    display: block;
    text-align: center;
    font-weight: 300; }

#userguadagnalink > .ant-row {
  justify-content: flex-end; }

#userguadagnalink .guadagnalink-info {
  display: flex;
  justify-content: center;
  text-align: center; }
  #userguadagnalink .guadagnalink-info .ant-typography {
    margin: 0;
    font-size: 30px;
    font-weight: 300; }

#userguadagnalink .brand {
  width: auto;
  height: auto;
  max-height: 100px;
  max-width: 100px; }

#userguadagnalink .ant-table-expanded-row td {
  padding: 0; }
  #userguadagnalink .ant-table-expanded-row td .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center; }
  #userguadagnalink .ant-table-expanded-row td .ant-card-grid {
    box-shadow: none; }

#userguadagnalink .powered {
  font-size: 11px;
  margin-top: 20px; }
  #userguadagnalink .powered a {
    color: #25D366; }

#userguadagnalink .chat-message {
  margin: 20px;
  padding: 20px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #f1f1f1; }

.guadagnalink-form .ant-btn {
  width: 250px;
  margin: 0 auto;
  display: block; }

#voucherdetail {
  padding-top: 30px; }
  #voucherdetail .promosec {
    font-size: 15px; }
    #voucherdetail .promosec .offer-premium:before {
      font-family: 'icomoon';
      display: inline-block;
      margin-right: 5px;
      content: '\e92c';
      color: #25D366; }
  #voucherdetail .selected-premiums {
    margin-bottom: 10px; }
    #voucherdetail .selected-premiums .ant-card.ant-card-bordered {
      border: 0;
      border-bottom: 1px solid #f0f0f0; }
      #voucherdetail .selected-premiums .ant-card.ant-card-bordered:first-child {
        border-top: 1px solid #f0f0f0; }
      #voucherdetail .selected-premiums .ant-card.ant-card-bordered .ant-card-body {
        padding: 8px; }
        #voucherdetail .selected-premiums .ant-card.ant-card-bordered .ant-card-body .anticon.anticon-delete {
          position: absolute;
          left: 5px;
          top: 12px;
          font-size: 16px;
          cursor: pointer; }
        #voucherdetail .selected-premiums .ant-card.ant-card-bordered .ant-card-body .anticon.anticon-caret-down, #voucherdetail .selected-premiums .ant-card.ant-card-bordered .ant-card-body .anticon.anticon-caret-up {
          position: absolute;
          right: 5px;
          top: 12px;
          font-size: 16px;
          cursor: pointer; }
  #voucherdetail .moreDetails {
    display: block;
    text-align: center;
    cursor: pointer; }
  #voucherdetail .ant-steps {
    max-width: 300px;
    margin: 0 auto;
    transform: translateX(-12%); }
  #voucherdetail .voucher-options {
    text-align: center; }
    #voucherdetail .voucher-options .ant-radio-button-wrapper {
      font-size: 28px;
      height: auto;
      width: 100px;
      text-align: center;
      padding: 10px;
      margin: 0 15px; }
      #voucherdetail .voucher-options .ant-radio-button-wrapper:first-child {
        margin-left: 0; }
    #voucherdetail .voucher-options .ant-btn {
      border-radius: 0;
      font-size: 25px;
      line-height: 22px; }
  #voucherdetail .activation-options .ant-select {
    width: 100%; }
  #voucherdetail .choose-credit .ant-card {
    cursor: pointer;
    opacity: 0.5; }
    #voucherdetail .choose-credit .ant-card.credit-chosen {
      opacity: 1;
      border: 2px solid #25D366; }
  #voucherdetail .choose-credit-us .ant-card {
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 4px; }
    #voucherdetail .choose-credit-us .ant-card.credit-chosen {
      border: 2px solid #25D366; }
  #voucherdetail .choose-credit-us .ant-card-body {
    padding: 10px 10px 6px; }
  #voucherdetail #voucher-search {
    padding: 0; }
    #voucherdetail #voucher-search .ant-list {
      position: absolute;
      max-height: 500px;
      width: 340px;
      z-index: 99;
      background: #fff;
      overflow-y: scroll;
      border: 1px solid #eee; }
      #voucherdetail #voucher-search .ant-list .ant-list-item:hover {
        cursor: pointer;
        background-color: #eee; }
        #voucherdetail #voucher-search .ant-list .ant-list-item:hover .ant-card-body {
          background-color: #eee; }
      #voucherdetail #voucher-search .ant-list .ant-card-body {
        padding: 10px; }
    #voucherdetail #voucher-search input[type='text']:hover,
    #voucherdetail #voucher-search input[type='text']:active,
    #voucherdetail #voucher-search input[type='text']:focus {
      background-color: #fff; }
  #voucherdetail .voucher-right > .ant-card-body {
    padding-top: 0; }
  #voucherdetail .voucher-right .ant-alert-warning {
    margin-bottom: 20px; }
  #voucherdetail .voucher-right .ant-row {
    margin-bottom: 5px; }
  #voucherdetail .voucher-right input[type='radio'] {
    margin-right: 5px; }
  #voucherdetail .voucher-right .summary {
    border: 1px solid #f0f0f0;
    padding: 10px 0;
    margin-bottom: 20px; }
    #voucherdetail .voucher-right .summary .voucher-label {
      font-size: 16px;
      font-weight: 600; }
    #voucherdetail .voucher-right .summary .voucher-label-summary {
      font-size: 16px;
      font-weight: 400; }
    #voucherdetail .voucher-right .summary .background-orange {
      background-color: rgba(37, 211, 102, 0.1);
      padding-top: 15px; }
    #voucherdetail .voucher-right .summary .ant-divider {
      margin: 0; }
  #voucherdetail #premiums .ant-card {
    cursor: pointer; }
    #voucherdetail #premiums .ant-card.added {
      border: 1px solid #25D366; }
    #voucherdetail #premiums .ant-card .anticon.anticon-info-circle {
      position: absolute;
      left: 10px;
      top: 10px;
      font-size: 20px;
      color: #666; }
    #voucherdetail #premiums .ant-card .anticon.anticon-plus-circle,
    #voucherdetail #premiums .ant-card .anticon.anticon-minus-circle {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      color: #25D366; }
    #voucherdetail #premiums .ant-card .ant-row, #voucherdetail #premiums .ant-card h5 {
      margin: 0; }
    #voucherdetail #premiums .ant-card .voucher-name {
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center; }
    #voucherdetail #premiums .ant-card .offer-premium:before {
      font-family: 'icomoon';
      display: inline-block;
      margin-right: 5px;
      content: '\e92c';
      color: #25D366; }
  #voucherdetail #premiums .ant-card-body {
    padding: 15px; }
    #voucherdetail #premiums .ant-card-body .icon:after {
      font-family: 'icomoon';
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      color: #25D366; }
    #voucherdetail #premiums .ant-card-body .icon-credits:after {
      content: '\e987'; }
    #voucherdetail #premiums .ant-card-body .icon-ticket:after {
      content: '\e93b'; }
    #voucherdetail #premiums .ant-card-body .tooltip {
      position: relative; }
      #voucherdetail #premiums .ant-card-body .tooltip .tooltip-text {
        z-index: 1000;
        width: max-content;
        display: none;
        background-color: #ffffff;
        border: 1px solid #25D366;
        padding: 5px;
        font-size: 14px;
        position: absolute;
        top: -60px;
        left: 0;
        right: 0;
        margin: auto; }
    #voucherdetail #premiums .ant-card-body .tooltip:hover .tooltip-text {
      display: block; }
    #voucherdetail #premiums .ant-card-body .activation-days {
      font-size: 13px; }
    #voucherdetail #premiums .ant-card-body .info {
      font-size: 22px;
      color: #6A706E; }
    #voucherdetail #premiums .ant-card-body .toggle-voucher-btn {
      width: 100%; }
  #voucherdetail .socialshare {
    margin-top: 20px; }
    #voucherdetail .socialshare .ant-typography {
      display: inline-block;
      margin-bottom: 10px; }
    #voucherdetail .socialshare img {
      cursor: pointer; }
  #voucherdetail .become-orange {
    cursor: pointer;
    background: url("/assets/images/icons/premium.png") 0 2px no-repeat;
    padding-left: 22px; }

.ops-container {
  background-color: '#f5f5f5'; }
  .ops-container .ops-500-dogs {
    background: #fff;
    max-width: 550px;
    width: 100%;
    border-radius: 30px;
    margin: 30px auto;
    padding: 30px 50px 30px 35px;
    position: relative; }
    @media only screen and (max-width: 575px) {
      .ops-container .ops-500-dogs {
        margin: 30px auto;
        padding: 20px;
        max-width: 90%; } }
    .ops-container .ops-500-dogs .ops-title {
      font-size: 90px;
      font-weight: bold;
      color: #25D366; }
      @media only screen and (max-width: 575px) {
        .ops-container .ops-500-dogs .ops-title {
          font-size: 60px; } }
    .ops-container .ops-500-dogs .ops-description {
      display: block;
      padding-right: 35%;
      min-height: 100px;
      margin-bottom: 20px; }
      @media only screen and (max-width: 575px) {
        .ops-container .ops-500-dogs .ops-description {
          min-height: 170px; } }
    .ops-container .ops-500-dogs .err-img {
      position: absolute;
      max-width: 340px;
      top: -6px;
      right: -50px; }
      @media only screen and (max-width: 575px) {
        .ops-container .ops-500-dogs .err-img {
          position: absolute;
          max-width: 200px;
          top: 124px;
          right: -30px; } }

/**
 * COMMON
 */
#userinvoices,
#userpagameno,
#userricaricacell,
#uservouchers,
#userupdate,
#userticket,
#usersxccard,
#refundarea {
  padding-top: 50px; }
  #userinvoices .anticon-loading,
  #userpagameno .anticon-loading,
  #userricaricacell .anticon-loading,
  #uservouchers .anticon-loading,
  #userupdate .anticon-loading,
  #userticket .anticon-loading,
  #usersxccard .anticon-loading,
  #refundarea .anticon-loading {
    margin: 30px auto;
    font-size: 30px;
    display: block; }

/**
 * VOUCHER DOWNLOAD
 */
#uservouchers .voucherimg {
  display: flex; }

#uservouchers .ant-table-wrapper img {
  width: 100px; }
  @media only screen and (max-width: 575px) {
    #uservouchers .ant-table-wrapper img {
      width: 60px; } }

#uservouchers table th.ant-table-cell {
  background-color: #f5f5f5; }

#uservouchers table td a {
  color: #6A706E; }

#uservouchers table td i {
  color: #aaa; }

#uservouchers table td .anticon-loading {
  margin: initial;
  margin-right: 15px;
  font-size: 22px;
  float: right; }

.download-success .ant-message-notice-content {
  padding: 14px 50px;
  transform: translateX(50%); }

.ant-notification-topRight {
  width: 500px; }
  .ant-notification-topRight .popup-download {
    width: 500px;
    border: 1px solid #25D366; }
    .ant-notification-topRight .popup-download .ant-notification-notice-description {
      font-size: 16px;
      line-height: 30px;
      margin-left: 80px; }

/**
* INVOICES
*/
#userinvoices .ant-row.ant-form-item {
  flex-direction: column; }
  #userinvoices .ant-row.ant-form-item .ant-form-item-label {
    text-align: left; }
    #userinvoices .ant-row.ant-form-item .ant-form-item-label label {
      font-weight: 600;
      font-size: 12px;
      font-family: verdana;
      margin-bottom: 0; }

#userinvoices .handle-addresses {
  float: right;
  margin-top: 8px;
  color: #25D366;
  cursor: pointer; }

#user-profile .tabs {
  border: 2px solid #F5F5F5;
  border-radius: 10px; }
  #user-profile .tabs .tab {
    margin: 0px;
    padding: 10px; }
  #user-profile .tabs .tab:not(:last-child) {
    border-bottom: 1px solid #C4C4C4; }
  #user-profile .tabs .tab:hover, #user-profile .tabs .tab.selected {
    cursor: pointer;
    background-color: rgba(196, 196, 196, 0.2); }

#user-profile .tab-content {
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  padding: 0px 15px; }
  #user-profile .tab-content .ant-row {
    margin-bottom: 0px; }
    #user-profile .tab-content .ant-row .ant-form-item-label {
      width: 200px;
      text-align: start;
      white-space: unset; }
  #user-profile .tab-content > *:not(:last-child) {
    border-bottom: 1px solid #C4C4C4; }
  #user-profile .tab-content > * {
    margin: 0px;
    padding: 15px 0px; }
    #user-profile .tab-content > * p {
      margin: 0px; }
  #user-profile .tab-content .public-row {
    position: relative; }
    #user-profile .tab-content .public-row .checkbox {
      position: absolute;
      left: -50px;
      top: 0px;
      bottom: 0px;
      margin: auto 0px;
      height: fit-content; }

#user-profile .delete-account-btn {
  width: 100%; }

#profile-diana .diana-bg {
  width: 100%;
  height: 250px;
  background-image: url("/assets/images/diana_bar_en.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover; }

#profile-diana .tabs .tab {
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  background-color: rgba(73, 90, 191, 0.5); }

#profile-diana .tabs .tab:hover:not(.disabled), #profile-diana .tabs .tab.selected:not(.disabled) {
  cursor: pointer;
  background-color: #495ABF; }

#profile-diana .tab-content {
  border: 2px solid #F5F5F5;
  border-radius: 10px;
  padding: 15px; }
  #profile-diana .tab-content > .ant-row {
    margin-bottom: 0px; }
  #profile-diana .tab-content .device:not(:last-child) {
    margin: 0px;
    padding: 10px 0px;
    border-bottom: 1px solid #F5F5F5; }

#profile-diana .privacy h4 {
  margin-bottom: 5px; }

#profile-diana .privacy p {
  margin-bottom: 0px; }

#profile-navigation > * p {
  color: #161032; }

#profile-navigation > * p.selected {
  border-bottom: 1px solid #161032; }

#profile-navigation > *:hover {
  cursor: pointer; }

#profile-navigation .profile-icon {
  font-size: 20px;
  color: #25D366; }

@media (max-width: 1200px) {
  #cover .xs-centered {
    justify-content: center; }
  #cover .profile-image {
    margin-left: 0px !important; } }

.adSlots {
  clear: both;
  margin: 0 auto;
  background: transparent;
  display: block;
  position: relative; }
  @media (max-width: 970px) {
    .adSlots {
      display: none; } }
  .adSlots.bottom20 {
    margin-bottom: 20px; }
  .adSlots.bottom30 {
    margin-bottom: 30px; }
  .adSlots.bottom40 {
    margin-bottom: 40px; }
  .adSlots.w970x250 {
    width: 970px;
    max-height: 250px;
    text-align: center; }
    .profile-container .adSlots.w970x250,
    #usr_cnt_tim .adSlots.w970x250,
    #usr_cnt_wlt .adSlots.w970x250,
    .clubs-container .adSlots.w970x250 {
      margin-bottom: 30px; }
    @media (max-width: 1280px) {
      .col-2-layout .adSlots.w970x250 {
        width: 100%; } }
    @media (max-width: 970px) {
      .adSlots.w970x250 .col-1-layout {
        width: 100%; } }
  .adSlots.w300x250 {
    width: 300px;
    height: 250px; }
    @media (max-width: 1280px) {
      .col-2-layout > div:not(.shopping-main-content) .adSlots.w300x250 {
        width: 100%; }
      .adSlots.w300x250 .col-2-layout .shoppingcard_in_all {
        display: none; } }
    @media (max-width: 970px) {
      .adSlots.w300x250 .col-1-layout {
        width: 100%; }
      .flex-for-adv .adSlots.w300x250,
      .offer-details-left .adSlots.w300x250,
      .col-2-layout > .shopping-main-content .adSlots.w300x250,
      .bulletin-container .adSlots.w300x250 {
        display: none; } }
  .adSlots.w300x600 {
    width: 300px;
    height: 600px;
    margin-bottom: 30px; }
    @media (max-width: 1280px) {
      .col-2-layout > div:not(.shopping-main-content) .adSlots.w300x600 {
        width: 100%; } }
    @media (max-width: 970px) {
      .adSlots.w300x600 .col-1-layout {
        width: 100%; }
      .col-2-layout > .shopping-main-content .adSlots.w300x600,
      .bulletin-container .adSlots.w300x600,
      .user-area .adSlots.w300x600 {
        display: none; } }
  .adSlots.w120x600 {
    width: 120px;
    height: 600px; }
    .adSlots.w120x600:after {
      writing-mode: vertical-rl; }
    @media (max-width: 1280px) {
      .col-2-layout .adSlots.w120x600 {
        width: 100%; } }
    @media (max-width: 970px) {
      .adSlots.w120x600 .col-1-layout {
        width: 100%; }
      .flex-for-adv .adSlots.w120x600 {
        display: none; } }
  .adSlots.w160x600 {
    width: 160px;
    height: 600px; }
    .adSlots.w160x600:after {
      writing-mode: vertical-rl; }
    @media (max-width: 1280px) {
      .col-2-layout .adSlots.w160x600 {
        width: 100%; } }
    @media (max-width: 970px) {
      .adSlots.w160x600 .col-1-layout {
        width: 100%; }
      .flex-for-adv .adSlots.w160x600 {
        display: none; } }
  @media (max-width: 970px) {
    .aside-menu .adSlots {
      display: none; } }

#home .adSlots.w970x250 {
  margin-bottom: 30px; }

#home .hghlt.flex-for-adv {
  display: flex; }

.layout .container.flex-for-adv {
  display: flex; }
  .layout .container.flex-for-adv > .pt-4 {
    flex: 1; }
  .layout .container.flex-for-adv .adSlots.w300x250 {
    margin-top: 60px; }

#header .logo-elem div {
  height: 80px; }

#header .logo {
  height: 55px; }

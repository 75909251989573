.ops-container {
    background-color: '#f5f5f5';

    .ops-500-dogs {
        background: $white;
        max-width: 550px;
        width: 100%;
        border-radius: 30px;
        margin: 30px auto;
        padding: 30px 50px 30px 35px;
        position: relative;
        @media #{$mq-xs} {
            margin: 30px auto;
            padding: 20px;
            max-width: 90%;
        }
        .ops-title {
            font-size: 90px;
            font-weight: bold;
            color: $orange;
            @media #{$mq-xs} {
                font-size: 60px;
            }
        }
        .ops-description {
            display: block;
            padding-right: 35%;
            min-height: 100px;
            margin-bottom: 20px;
            @media #{$mq-xs} {
                min-height: 170px;
            }
        }
        .err-img {
            position: absolute;
            max-width: 340px;
            top: -6px;
            right: -50px;
            @media #{$mq-xs} {
                position: absolute;
                max-width: 200px;
                top: 124px;
                right: -30px;
            }
        }
    }
}

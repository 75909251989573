#userguadagnalink {
    > .ant-row {
        justify-content: flex-end;
    }

    .guadagnalink-info {
        display: flex;
        justify-content: center;
        text-align: center;
        .ant-typography {
            margin: 0;
            font-size: 30px;
            font-weight: 300;
        }
    }

    .brand {
        width: auto;
        height: auto;
        max-height: 100px;
        max-width: 100px;
    }

    .ant-table-expanded-row {
        td {
            padding: 0;
            .ant-card-body {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .ant-card-grid {
                box-shadow: none;
            }
        }
    }

    .powered {
        font-size: 11px;
        margin-top: 20px;
        a {
            color: $orange;
        }
    }

    .chat-message {
        margin: 20px;
        padding: 20px;
        border-radius: 16px;
        background: $white;
        border: 1px solid #f1f1f1;
    }
}

.guadagnalink-form {
    .ant-btn {
        width: 250px;
        margin: 0 auto;
        display: block;
    }
}

#verify {
    background-color: #fff;
    padding-bottom: 3rem;

    .head {
        text-align: center;
        display: block;
        margin-bottom: 3rem;
    }

    .icon {
        font-size: 2.5rem;
        color: $orange;
        margin: 1.5rem auto 2rem;
        display: block;
    }

    .link {
        display: block;
        background-color: transparent;
        border: none;
    }

    .feedback-message {
        position: relative;
        top: -1.5rem;
    }

    .invitation-claim {
        font-size: 1.2rem;
    }

    .banner {
        background: url('/assets/images/invite.png') 0 0 no-repeat;
        background-size: contain;
        margin: 2rem auto;
        width: fit-content;
        text-align: left;
        padding-left: 3.2rem;
    }
}

#footer {
    background-color: $violetdark;

    .wrapper {
        @include maxWidth();
        margin: 0 auto !important;
        padding-top: 30px;

        &.last {
            border-top: 1px solid #cecece;
            padding: 20px 0 100px;
            margin-top: 10px !important;
        }

        .the4 {
            span {
                cursor: pointer;
                strong {
                    cursor: default;
                }
            }
        }

        .ant-typography,
        a {
            color: #fff;
            display: block;
        }

        strong {
            display: block;
            margin-bottom: 20px;
        }

        .payments {
            img {
                margin: 0 7px;
            }
        }
    }
}

#userfamilybox {
    > .ant-row {
        justify-content: flex-end;
    }

    .balance-block {
        display: flex;
        justify-content: center;
        text-align: center;
        .ant-typography {
            margin: 0;
            font-size: 30px;
            font-weight: 300;
        }
    }

    .familybox-img {
        width: 90px;
        margin-right: 20px;
        @media #{$mq-xs} {
            display: none;
        }
    }
}

#cart {
    @include maxWidth;
    min-height: 40vh;
    padding-top: 20px;
    &.revolut {
        margin-top: 20px;

        iframe {
            height: 50px !important;
            margin-bottom: 20px !important;
        }
    }

    .ant-steps {
        margin-bottom: 20px;
        background: #fff;
        padding: 12px 30px 15px;

        .ant-steps-item-icon {
            cursor: pointer;
        }
    }

    .vouchers-wrapper {
        min-height: 413px;
        @media #{$mq-sm} {
            min-height: 0;
        }
    }

    .voucher-list-cart {
        .voucher-single-cart {
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }

        .voucher-premium-cart {
            border-top: 1px dashed $orangelight;
            padding-top: 10px;
            margin-top: 10px;

            h5 {
                color: $orange;
            }
        }
    }

    .ant-descriptions-item-container {
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 10px;
        align-items: center;

        .ant-descriptions-item-label {
            span.anticon {
                font-size: 19px;
                cursor: pointer;
                color: $violetdark;
            }

            img {
                width: 80px;
            }
        }
        .ant-descriptions-item-content {
            text-align: right;
            font-weight: 600;
            display: table-cell;
        }
    }

    .ant-descriptions-item.total {
        .ant-descriptions-item-label,
        .ant-descriptions-item-content {
            font-size: 24px;
            color: $orange;
        }
    }

    .ant-row.ant-form-item {
        flex-direction: column;

        .ant-form-item-label {
            text-align: left;

            label {
                font-weight: 600;
                font-size: 12px;
                font-family: verdana;
                margin-bottom: 0;
            }
        }
    }

    .cart-billing {
        .ant-checkbox-wrapper {
            margin-right: 10px;
        }

        .ant-menu-item {
            border: 1px solid #ededed;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: $orangelight;
            color: $orange;
        }
    }

    .cart-shipping {
        .ant-menu-item {
            border: 1px solid #ededed;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: $orangelight;
            color: $orange;
        }
    }

    .cart-payment {
        .ant-list-items .ant-row {
            margin: 10px 0 0 0;
            padding: 0 0 10px 0;
            border-bottom: 1px solid #ededed;
            cursor: pointer;
            &:last-child {
                border: 0;
            }

            div {
              vertical-align: middle;
            }
        }

        img {
          height: 100%;
        }

        button {
            margin: 0 0 10px 0;
        }

        button[disabled] {
            background-color: #fff;
            color: #ccc;
            border-color: #ccc;
        }
    }

    .ant-steps-horizontal.ant-steps-label-horizontal
        > .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail {
        @media #{$mq-sm} {
            display: none;
        }
    }

    .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
        .ant-steps-item {
        @media #{$mq-sm} {
            padding-left: 0;
        }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        color: initial;
        background: #fff;
    }

    .header-icon {
        color: $orange;
        width: 22px;
        height: 22px;
        font-size: 22px;
    }

    .min-height {
        min-height: 44px;
    }

    .link-orange a {
        color: $orange;
    }

    .credit-card input {
        border-color: #d9d9d9;
    }
}

#walletWidgetDiv,
#addressBookWidgetDiv {
    margin-top: 20px !important;
}
#OffAmazonPaymentsWidgets2IFrame {
    border: 0 !important;
}

.bank-box {
    border: 1px solid orange;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: -1px 5px 10px 0px lightgrey;
    padding: 10px;

    p.bank-logo {
        text-transform: capitalize;
        font-size: large;
        vertical-align: middle;
    }

    img {
        height: 100px;
        max-width: 100px;
        object-fit: contain;
        margin-right: 2em;
    }

    .has-padding > div {
        padding: 0 5px;
    }

    .has-padding > div:first-child {
        padding-left: 0;
    }

    .has-padding > div:last-child {
        padding-right: 0;
    }
}

.yapily-box1 {
    background-color: skyblue;
    padding: 30px 60px;
    border: 1px solid transparent;
    border-radius: 10px;

    h1 {
        color: orange;
        font-weight: bold;
    }

    h2 {
        font-weight: bold;
        margin: 0;
    }
}

.yapily-box2 {
    border: 1px solid lightgrey;
    padding: 30px 60px;
    border-radius: 10px;

    h1 {
        font-weight: bold;
        border-bottom: 1px solid lightgrey;
    }

    span:first-child {
        color: orange;
    }

    span {
        font-size: large;
    }

    p {
        font-size: small;
    }
}
